<template>
  <div>
    <form ref="form" autocomplete="on" @submit.prevent="register">
      <legend>Sign up</legend>
      <b-field
        label="First name"
      >
        <b-input
          v-model="registrationData.first_name"
          autocomplete="given-name"
          placeholder="John"
          required
        />
      </b-field>
      <b-field
        label="Last name"
      >
        <b-input
          v-model="registrationData.last_name"
          autocomplete="family-name"
          placeholder="Doe"
          required
        />
      </b-field>

      <b-field
        label="Email"
      >
        <b-input
          v-model="registrationData.email"
          type="email"
          autocomplete="email"
          placeholder="john.doe@example.xyz"
          required
        />
      </b-field>
      <b-field
        label="Password"
      >
        <b-input
          v-model="registrationData.password"
          type="password"
          autocomplete="new-password"
          placeholder="must be at least 8 characters"
          minlength="8"
          required
          password-reveal
        />
      </b-field>
      <b-field class="pt-2">
        <b-checkbox required>
          I agree with Wolfgang's Terms of Service including the GDPR provisions.
        </b-checkbox>
      </b-field>
      <b-field class="pb-2">
        <a href="https://wolfgang.pro/tos">Read the Terms of Service</a>
      </b-field>
      <b-field>
        <SubmitButton
          :is-loading="isSending"
          :is-disabled="isSending"
        >
          Create account
        </SubmitButton>
      </b-field>
    </form>
  </div>
</template>

<script>

import Actions from '@/store/actions'

export default {
  name: 'RegistrationForm',
  data () {
    return {
      registrationData: {
        first_name: '',
        last_name: '',
        email: '',
        password: ''
      },
      isSending: false,
    }
  },
  methods: {
    register () {
      this.isSending = true
      this.$store.dispatch(Actions.REGISTER, this.registrationData)
        .then(() => this.$store.dispatch(Actions.LOGIN, {
          email: this.registrationData.email,
          password: this.registrationData.password
        }))
        .then(() => this.$emit('login-success'))
        .finally(() => { this.isSending = false })
    }
  }
}
</script>
