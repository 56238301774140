import { render, staticRenderFns } from "./YachtList.vue?vue&type=template&id=66738bf8&scoped=true"
import script from "./YachtList.vue?vue&type=script&lang=js"
export * from "./YachtList.vue?vue&type=script&lang=js"
import style0 from "./YachtList.vue?vue&type=style&index=0&id=66738bf8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66738bf8",
  null
  
)

export default component.exports