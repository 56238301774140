import Vue from 'vue'
import axios from '@/api/config'
import Actions from '@/store/actions'
import DateFormatters from '@/helpers/date_formatters'

const MU_SET_CRUISES = 'μ: Set cruises'
const MU_SET_CRUISE = 'μ: Set cruise'
const MU_EDIT_CRUISE = 'μ: Edit cruise attributes'
const MU_ADD_CRUISE = 'μ: Add cruise'
const MU_DELETE_CRUISE = 'μ: Delete cruise'
const MU_SET_CRUISE_ROLE_STATUSES = 'μ: Set role statuses'
const MU_SET_CRUISE_ROLE = 'μ: Set profiles for specific cruise role'
const MU_SET_CRUISE_VALIDATION_DATA = 'μ: Set cruise validation data'
const MU_SET_CRUISE_TAB_VALIDATION = 'μ: Set cruise tab validation'
const MU_SET_PAYMENT_CALLS = 'μ: Set cruise payment calls'
const MU_SET_PAYMENT_CALL = 'μ: Update single payment call'
const MU_DELETE_PAYMENT_CALL = 'μ: Delete payment call'
const MU_SET_FUEL_BUNKERS = 'μ: Set cruise fuel bunkers'
const MU_SET_APPROVALS = 'μ: Set cruise approvals'
const MU_SET_PASSENGER_DISEMBS = 'μ: Set disembs data for single passenger'

function initialState () {
  return {
    cruises: [],
    cruise: null, /* current cruise */
    paymentCalls: [],
    roleStatuses: [], /* current cruise list of approvals (user/role/profile) */
    validationData: null /* current cruise validation state */,
    tabValidation: {
      cruise: false,
      yacht: false,
      brokers: false,
      clients: false,
      services: false,
      price: false
    },
  }
}

const cruiseModule = {
  state: initialState,
  mutations: {
    [MU_SET_CRUISES] (state, cruises) {
      state.cruises = cruises
    },
    [MU_ADD_CRUISE] (state, cruise) {
      if (!state.cruises.length) return
      state.cruises.push(cruise)
    },
    [MU_EDIT_CRUISE] (state, cruise) {
      if ((cruise.id && cruise.id !== state.cruise.id) || (cruise.version && cruise.version !== state.cruise.version)) {
        return
      }
      Object.keys(cruise).forEach(prop => Vue.set(state.cruise, prop, cruise[prop]))
    },
    [MU_SET_CRUISE] (state, cruise) {
      if (state.cruises.length && cruise.is_current_version) {
        const cruiseIdx = state.cruises.findIndex(c => Number(c.id) === Number(cruise.id))
        if (cruiseIdx !== -1) {
          state.cruises.splice(cruiseIdx, 1, cruise)
        } else {
          state.cruises.push(cruise)
        }
      }
      state.cruise = cruise
    },
    [MU_SET_CRUISE_ROLE] (state, { role, roleData }) {
      if (!Array.isArray(roleData)) return
      Vue.set(state.cruise, role, roleData)
    },
    [MU_SET_CRUISE_VALIDATION_DATA] (state, validationData) {
      state.validationData = validationData
    },
    [MU_SET_CRUISE_TAB_VALIDATION] (state, tabValidation) {
      state.tabValidation = tabValidation
    },
    [MU_SET_CRUISE_ROLE_STATUSES] (state, roleStatuses) {
      state.roleStatuses = roleStatuses
    },
    [MU_DELETE_CRUISE] (state, cruiseId) {
      state.cruises = state.cruises.filter(cruise => Number(cruise.id) !== Number(cruiseId))
    },
    [MU_SET_PAYMENT_CALLS] (state, paymentCallsData) {
      if (paymentCallsData.length > 0 && state.cruise && paymentCallsData[0].cruise_id !== state.cruise.id) { return }
      state.paymentCalls = paymentCallsData
    },
    [MU_SET_PAYMENT_CALL] (state, paymentCallData) {
      const pcIdx = state.paymentCalls.findIndex(c => Number(c.id) === Number(paymentCallData.id))
      if (pcIdx !== -1) {
        state.paymentCalls.splice(pcIdx, 1, paymentCallData)
      }
    },
    [MU_DELETE_PAYMENT_CALL] (state, paymentCallId) {
      state.paymentCalls = state.paymentCalls.filter(pc => Number(pc.id) !== Number(paymentCallId))
    },
    [MU_SET_FUEL_BUNKERS] (state, fuelBunkersData) {
      if (fuelBunkersData.length > 0 && state.cruise && fuelBunkersData[0].cruise_id !== state.cruise.id) { return }
      Vue.set(state.cruise, 'fuel_bunkers', fuelBunkersData)
    },
    [MU_SET_APPROVALS] (state, approvalsData) {
      state.approvals = approvalsData
    },
    [MU_SET_PASSENGER_DISEMBS] (state, { profileId, disembData }) {
      const pIdx = state.cruise.passengers.findIndex(p => Number(p.id) === Number(profileId))
      if (pIdx !== -1) {
        Vue.set(state.cruise.passengers[pIdx], 'disembs', disembData)
      }
    },
  },
  actions: {
    [Actions.GET_USER_CRUISES] ({ commit, getters }) {
      return axios.get(`/cruise/by_user/${getters.userId}/`)
        .then(({ data }) => {
          commit(MU_SET_CRUISES, data)
          return data
        })
    },
    [Actions.REFRESH_CRUISE] ({ commit, dispatch, state }, args) {
      // Allow undefined args:
      const { reloadWaypoints = false, validate = true } = args || {}
      if (state.cruise === null) { return }
      return dispatch(Actions.GET_CRUISE, {
        cruiseId: state.cruise.id,
        reloadWaypoints: reloadWaypoints,
        validate: validate
      })
    },
    [Actions.GET_CRUISE] ({ commit, dispatch, state },
      { cruiseId, cruiseVersion = undefined, reloadWaypoints = true, validate = true, saveInState = true }) {
      return axios.get(`/cruise/${cruiseId}` + (cruiseVersion !== undefined ? `/${cruiseVersion}` : ''))
        .then(({ data }) => {
          if (saveInState) {
            commit(MU_SET_CRUISE, data)
            // commit('resetCruiseYacht')
            if (validate) dispatch(Actions.VALIDATE_CRUISE, cruiseId)
            if (reloadWaypoints) dispatch(Actions.GET_CRUISE_WAYPOINTS)
            // if (cruise.yacht && cruise.yacht.id)
          }
          return data
        })
    },
    [Actions.UPDATE_CRUISE] ({ commit, state, dispatch }, cruiseData) {
      commit(MU_EDIT_CRUISE, cruiseData) // Temporary edit for quicker UI response
      return axios.put(`/cruise/${state.cruise.id}/${state.cruise.version}`, cruiseData)
        .then(({ data }) => {
          commit(MU_SET_CRUISE, data)
          return data
        })
        .catch(() => {
          // Need to restore true DB state:
          return dispatch(Actions.GET_CRUISE, {
            cruiseId: state.cruise.id,
            cruiseVersion: state.cruise.version,
            reloadWaypoints: false,
            validate: false
          })
        })
    },
    [Actions.CREATE_USER_CRUISE] ({ commit, getters }, cruiseData) {
      return axios.post(`/cruise/by_user/${getters.userId}/`, cruiseData)
        .then(({ data }) => {
          commit(MU_ADD_CRUISE, data)
          return data
        })
    },
    [Actions.GET_CRUISE_GENERATED_VERSIONS] ({ commit, state }) {
      return axios.get(`/cruise/${state.cruise.id}/${state.cruise.version}/generated_versions`)
        .then(({ data }) => {
          return data
        })
    },
    [Actions.GET_CRUISE_ROLE_STATUSES] ({ commit, state }) {
      return axios.get(`/cruise/${state.cruise.id}/role_status/`)
        .then(({ data }) => {
          commit(MU_SET_CRUISE_ROLE_STATUSES, data)
          return data
        })
    },
    [Actions.SET_CRUISE_LOCKED] ({ commit, dispatch, state, getters }, isLocked) {
      var promise
      if (isLocked) {
        promise = axios.post(`/cruise/${state.cruise.id}/lock`, { user_id: getters.userId })
      } else {
        promise = axios.delete(`/cruise/${state.cruise.id}/lock`)
      }
      return promise.then(({ data }) => {
        commit(MU_SET_CRUISE, data)
        return data
      })
    },
    [Actions.DELETE_CRUISE] ({ commit, state }, cruiseId) {
      return axios.delete(`/cruise/${cruiseId}`)
        .then(() => {
          commit(MU_DELETE_CRUISE, cruiseId)
        })
    },
    [Actions.VALIDATE_CRUISE] ({ commit, dispatch, state }, cruiseId) {
      return axios.get(`/contract/${cruiseId}/missing`)
        .then(({ data: vData }) => {
          commit(MU_SET_CRUISE_VALIDATION_DATA, vData)

          const tabValidation = initialState().tabValidation

          function validatesFor (vData, fields) {
            return !vData.find(x => fields.some(
              f => x.startsWith('cruise.' + f
              )))
          }

          tabValidation.yacht = validatesFor(vData, ['yacht', 'carriers', 'central_agents', 'captains'])
          tabValidation.cruise = validatesFor(vData, ['waypoints', 'cruise_areas'])
          tabValidation.brokers = validatesFor(vData, ['brokers', 'stakeholders'])
          tabValidation.clients = validatesFor(vData, ['clients', 'passengers', 'r_passengers'])
          tabValidation.services = validatesFor(vData, ['special_conditions'])
          tabValidation.price = validatesFor(vData, [
            'base_price_routing', 'base_price_cruising', 'contract_payment_calls'
          ])
          tabValidation.contract = !vData.length

          commit(MU_SET_CRUISE_TAB_VALIDATION, tabValidation)
          return tabValidation
        })
    },
    [Actions.GENERATE_CONTRACT] ({ commit }, cruiseId) {
      return axios.post(`/cruise/${cruiseId}/generate`, {})
        .then(({ data }) => {
          commit(MU_SET_CRUISE, data)
          return data
        })
    },
    [Actions.START_SETTLING] ({ commit, dispatch }, cruiseId) {
      return axios.post(`/cruise/${cruiseId}/settling`, {})
        .then(({ data }) => {
          commit(MU_SET_CRUISE, data)
          return dispatch(Actions.GET_CRUISE_PAYMENT_CALLS)
        })
    },
    [Actions.CANCEL_SETTLING] ({ commit, dispatch }, cruiseId) {
      return axios.delete(`/cruise/${cruiseId}/settling`, {})
        .then(({ data }) => {
          commit(MU_SET_CRUISE, data)
          dispatch(Actions.GET_CRUISE_PAYMENT_CALLS)
          return data
        })
    },
    [Actions.SETTLE_CONTRACT] ({ commit, dispatch }, cruiseId) {
      return axios.post(`/cruise/${cruiseId}/settled`, {})
        .then(({ data }) => {
          commit(MU_SET_CRUISE, data)
          return data
        })
    },
    [Actions.ARCHIVE_CONTRACT] ({ commit }, cruiseId) {
      return axios.post(`/cruise/${cruiseId}/archived`, {})
        .then(({ data }) => {
          commit(MU_SET_CRUISE, data)
          return data
        })
    },
    [Actions.CANCEL_SETTLE] ({ commit, dispatch }, cruiseId) {
      return axios.delete(`/cruise/${cruiseId}/settled`, {})
        .then(({ data }) => {
          commit(MU_SET_CRUISE, data)
          dispatch(Actions.GET_CRUISE_PAYMENT_CALLS)
          return data
        })
    },
    [Actions.GET_CRUISE_PAYMENT_CALLS] ({ commit, state }) {
      return axios.get(`/cruise/${state.cruise.id}/payment_call/`)
        .then(({ data }) => {
          commit(MU_SET_PAYMENT_CALLS, data)
          return data
        })
    },
    [Actions.ADD_PAYMENT_CALL] ({ commit, state }, paymentCallData) {
      return axios.post(`/cruise/${state.cruise.id}/payment_call/`, paymentCallData)
        .then(({ data }) => {
          commit(MU_SET_PAYMENT_CALLS, data)
          return data
        })
    },
    [Actions.UPDATE_PAYMENT_CALL] ({ commit }, { paymentCallId, paymentCallData }) {
      return axios.put(`/cruise/payment_call/${paymentCallId}`, paymentCallData)
        .then(({ data }) => {
          commit(MU_SET_PAYMENT_CALLS, data)
          return data
        })
    },
    [Actions.DELETE_PAYMENT_CALL] ({ commit, dispatch }, paymentCallId) {
      commit(MU_DELETE_PAYMENT_CALL, paymentCallId)
      return axios.delete(`/cruise/payment_call/${paymentCallId}`)
        .then(({ data }) => {
          commit(MU_SET_PAYMENT_CALLS, data)
          return data
        })
        .catch(() => {
          dispatch(Actions.GET_CRUISE_PAYMENT_CALLS)
        })
    },
    [Actions.ADD_PAYMENT] ({ commit }, { paymentCallId, paymentData }) {
      const formData = new FormData()
      Object.keys(paymentData).forEach((key) => {
        const val = paymentData[key]
        if (val instanceof Date) { formData.set(key, DateFormatters.date2stringDateOnly(val)) } else { formData.set(key, val) }
      })
      return axios.post(
        `/cruise/payment_call/${paymentCallId}/payment/`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
        .then(({ data }) => {
          commit(MU_SET_PAYMENT_CALL, data)
          return data
        })
    },
    [Actions.CANCEL_PAYMENT] ({ commit, dispatch, state }, paymentId) {
      return axios.delete(`/cruise/payment/${paymentId}`)
        .then(() => {
          return dispatch(Actions.GET_CRUISE_PAYMENT_CALLS, { cruiseId: state.cruise.id })
        })
    },
    [Actions.ADD_FUEL_BUNKER] ({ commit, state, dispatch }, fuelBunkerData) {
      return axios.post(`/cruise/${state.cruise.id}/fuel_bunker/`, fuelBunkerData)
        .then(({ data }) => {
          commit(MU_SET_FUEL_BUNKERS, data)
          dispatch(Actions.REFRESH_CRUISE)
          return data
        })
    },
    [Actions.UPDATE_FUEL_BUNKER] ({ commit, state, dispatch }, { fuelBunkerId, fuelBunkerData }) {
      return axios.put(`/cruise/${state.cruise.id}/fuel_bunker/${fuelBunkerId}`, fuelBunkerData)
        .then(({ data }) => {
          commit(MU_SET_FUEL_BUNKERS, data)
          dispatch(Actions.REFRESH_CRUISE)
          return data
        })
    },
    [Actions.DELETE_FUEL_BUNKER] ({ commit, state, dispatch }, fuelBunkerId) {
      return axios.delete(`/cruise/${state.cruise.id}/fuel_bunker/${fuelBunkerId}`)
        .then(({ data }) => {
          commit(MU_SET_FUEL_BUNKERS, data)
          dispatch(Actions.REFRESH_CRUISE)
          return data
        })
    },
    [Actions.ADD_CRUISE_APPROVAL] ({ commit, state, getters, dispatch }, userId = undefined) {
      if (userId === undefined) {
        userId = getters.userId
      }
      return axios.post(`/cruise/${state.cruise.id}/approval/`, { user_id: userId })
        .then(({ data }) => {
          commit(MU_SET_CRUISE_ROLE_STATUSES, data)
          dispatch(Actions.REFRESH_CRUISE)
          return data
        })
    },
    [Actions.DELETE_CRUISE_APPROVAL] ({ commit, state, getters, dispatch }, userId = undefined) {
      if (userId === undefined) {
        userId = getters.userId
      }
      return axios.delete(`/cruise/${state.cruise.id}/approval_by/${userId}`)
        .then(({ data }) => {
          commit(MU_SET_CRUISE_ROLE_STATUSES, data)
          dispatch(Actions.REFRESH_CRUISE)
          return data
        })
    },
    [Actions.GET_PROFILES_FOR_ROLE] ({ commit, state }, role) {
      return axios.get(`/cruise/${state.cruise.id}/${role}/`)
        .then(({ data: roleData }) => {
          commit(MU_SET_CRUISE_ROLE, { role, roleData })
          return roleData
        })
    },
    [Actions.ADD_PROFILE_TO_ROLE] ({ commit, state }, { role, profileId }) {
      return axios.post(`/cruise/${state.cruise.id}/${role}/`, { profile_id: profileId })
        .then(({ data: roleData }) => {
          commit(MU_SET_CRUISE_ROLE, { role, roleData })
          return roleData
        })
    },
    [Actions.DELETE_PROFILE_FROM_ROLE] ({ commit, state, dispatch }, { role, profileId }) {
      return axios.delete(`/cruise/${state.cruise.id}/${role}/by_profile/${profileId}`)
        .then(({ data: roleData }) => {
          commit(MU_SET_CRUISE_ROLE, { role, roleData })
          return dispatch(Actions.REFRESH_CRUISE)
            .then(() => {
              Promise.resolve(roleData)
            })
        })
    },
    [Actions.ADD_PROFILE_DISEMB] ({ commit, state, dispatch }, { profileId, disembData, refreshCruise = true }) {
      return axios.post(`/cruise/${state.cruise.id}/passenger/${profileId}/disembs/`, disembData)
        .then(({ data: disembData }) => {
          commit(MU_SET_PASSENGER_DISEMBS, { profileId, disembData })
          if (refreshCruise) {
            dispatch(Actions.REFRESH_CRUISE)
          }
          return disembData
        })
    }

  },
  getters: {
    // getCruiseById: (state) => (id) => {
    //   return state.cruises.find(cruise => cruise.id === Number(id))
    // },
    cruiseId: (state) => {
      return state.cruise ? state.cruise.id : null
    },
    cruiseReadOnly: (state) => {
      if (state.cruise) {
        return state.cruise.write_access ? state.cruise.locked : true
      } else {
        return true
      }
    }
  }
}

export default cruiseModule
