<template>
  <header  v-show="homePage" class="header">
    <div class="header-main">
      <div class="header__logo">
        <router-link class="" :to="{name: 'AgreementList'}">
          <img src="../assets/img/logo@1x.svg" width="45">
        </router-link>
      </div>
      <div class="header__nav">
        <router-link
          class="header__nav-item"
          active-class="header__nav-item--active"
          :to="{name: 'AgreementList'}"
        >Agreements</router-link>
        <router-link
          class="header__nav-item"
          active-class="header__nav-item--active"
          :to="{name: 'YachtList'}"
        >Yachts</router-link>
        <router-link
          class="header__nav-item"
          active-class="header__nav-item--active"
          :to="{name: 'ContactsList'}"
        >contacts</router-link>
      </div>
      <div class="header__actions">
        <template v-if="isLoggedIn">
          <router-link class="profile-link" active-class="is-active" :to="{name: 'ProfileDetail', params: { profileId: mainProfile.id }}">
            <span class="is-hidden-mobile has-text-grey">
              {{ mainProfile.full_name || 'Profile' }}
            </span>
            <b-icon icon="user" type="" class="is-hidden-tablet" />
          </router-link>
          <router-link class="help-link" active-class="is-active" :to="{name: 'Help'}">
            <span class="is-hidden-mobile has-text-grey">Help</span>
            <b-icon icon="info-circle" type="" class="is-hidden-tablet" />
          </router-link>
          <a class="logout" @click="handleLogout">
            <span class="has-text-danger is-hidden-mobile">Log out</span>
            <b-icon icon="sign-out-alt" type="is-danger" class="is-hidden-tablet" />
          </a>
        </template>
      </div>
    </div>
    <div class="header-secondary">
      <div class="header__nav">
        <router-link
          class="header__nav-item"
          active-class="header__nav-item--active"
          :to="{name: 'AgreementList'}"
        >Agreements</router-link>
        <router-link
          class="header__nav-item"
          active-class="header__nav-item--active"
          :to="{name: 'YachtList'}"
        >Yachts</router-link>
        <router-link
          class="header__nav-item"
          active-class="header__nav-item--active"
          :to="{name: 'ContactsList'}"
        >Contacts</router-link>
      </div>
    </div>
  </header>
</template>
<script>
import Actions from '@/store/actions'

export default {
  name: 'NavBar',
  data () {
    return {
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    mainProfile () {
      return this.$store.getters.mainProfile
    },
    homePage () {
      // console.log(this.$route.path)
      if (this.$route.path === '/') {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    handleLogout () {
      this.$store.dispatch(Actions.LOGOUT)
        .then(() => { this.$router.push({ path: '/' }) })
    }
  }
}
</script>
<style lang="scss" scoped>
  .header {
    display: flex;
    flex-flow: column nowrap;
    background-color: white;
    flex: 0 0 auto;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);

    &__nav {
      display: flex;
      flex: 1 0 auto;
      height: 100%;
      align-items: center;

      &-item {
        display: flex;
        align-items: center;
        height: 100%;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 1rem;
        color: $grey-light;
        font-weight: 600;
        border-bottom: 3px solid transparent;
        padding: 0 .25rem;
        margin: 0 .5rem;
        outline: none;
        transition: all .2s ease;

        &:not(&--active):hover {
          color: $grey-dark
        }

        &--active {
          border-bottom: 3px solid $primary;
          color: black;
        }
      }
    }

    &-main {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      height: 80px;
      padding: 0 1rem;

      .header__nav {
        justify-content: center;
        @media (max-width: 769px) {
          display: none;
        }
      }

    }

    &-secondary {
      display: flex;
      flex-flow: row nowrap;

      .header__nav {
        @media (min-width: 769px) {
          display: none;
        }

        &-item {
          flex: 1;
          padding: 1rem 0;
          justify-content: center;
        }
      }
    }

    &__logo,
    &__actions {
      display: flex;
      flex: 1 0 0;

      & > * {
        display: inline-flex;
        outline: none;
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;

      & > *:not(:last-child) {
        margin-right: 1rem;
      }
    }

  }
</style>
