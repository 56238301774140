<template>
  <b-button
    :icon-left="icon"
    :type="type"
    inverted
  >
    Back
  </b-button>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    icon: {
      default: 'arrow-left',
      type: String
    },
    type: {
      required: false,
      default: 'is-primary',
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
