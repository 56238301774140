<template>
  <div>
    <form autocomplete="on" @submit.prevent="login">
      <legend>Log in to your account</legend>
      <b-field label="Email">
        <b-input
          v-model="loginData.email"
          type="email"
          autocomplete="email"
          placeholder="john.doe@example.xyz"
          required
        />
      </b-field>

      <b-field label="Password">
        <b-input
          v-model="loginData.password"
          type="password"
          autocomplete="current-password"
          placeholder="Password"
          password-reveal
          required
        />
      </b-field>
      <b-field>
        <SubmitButton :is-loading="isSending" :is-disabled="isSending">
          Log in
        </SubmitButton>
      </b-field>
    </form>
    <div class="level">
    </div>
  </div>
</template>

<script>
//
import Actions from '@/store/actions'

export default {
  name: 'LoginForm',
  data () {
    return {
      loginData: {
        email: '',
        password: ''
      },
      isSending: true,
    }
  },
  methods: {
    login () {
      this.$store.dispatch(Actions.RESET_ERROR)
      this.isSending = true
      this.$store.dispatch(Actions.LOGIN, this.loginData)
        .then(() => {
          this.$emit('login-success')
        })
        .finally(() => {
          this.isSending = true
        })
    }
  }
}
</script>
