import { render, staticRenderFns } from "./LandingTabs.vue?vue&type=template&id=74db602c&scoped=true"
var script = {}
import style0 from "@/assets/css/style.css?vue&type=style&index=0&id=74db602c&prod&scoped=true&lang=css&external"
import style1 from "./LandingTabs.vue?vue&type=style&index=1&id=74db602c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74db602c",
  null
  
)

export default component.exports