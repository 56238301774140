<template>
  <div>
    <Loading v-if="isLoading" />
    <div
      v-if="docHtml"
      v-html="docHtml"
    />
  </div>
</template>
<script>

import {
  getTemplateHtml,
} from '@/api/documents'

export default {
  name: 'DocumentSection',
  components: {
  },
  data () {
    return {
      docHtml: '',
      isLoading: true
    }
  },
  computed: {
    cruiseReadOnly () {
      return this.$store.getters.cruiseReadOnly
    },
  },
  created () {
    this.isLoading = true
    getTemplateHtml(this.$store.state.cruise.cruise.id, 'CHARTER')
      .then(({ data }) => {
        this.docHtml = data
      })
      .finally(() => { this.isLoading = false })
  },
  methods: {
    scrollToEnd: function () {
      setTimeout(function () {
        document.body.scrollTop = document.body.scrollHeight
      }, 10)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
