<template>
  <section class="section">
    <div class="container section-box content">
      <h3 class="title">Current Users</h3>
      <b-table
        :data="users"
        :columns="columns"
        detailed
        :show-detail-icon="true"
      >
        <template slot="detail" slot-scope="props">
          <article class="media">
            <pre>
              {{ props.row }}
            </pre>
          </article>
        </template>
      </b-table>
      <br/>
      <b-switch v-model="showDetails">Show raw server response</b-switch>
      <pre v-if="showDetails">{{ users }}</pre>
    </div>
  </section>
</template>
<script>
import Actions from '@/store/actions'

export default {
  name: 'Admin',
  data () {
    return {
      isFetching: false,
      showDetails: false,
      users: [],
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '60',
          numeric: true,
        },
        {
          field: 'email',
          label: 'Email',
          searchable: true,
        },
        {
          field: 'main_profile.full_name',
          label: 'Name',
          searchable: true,
        },
        {
          field: 'created_at',
          label: 'Date',
          centered: true,
          searchable: true,
        },
      ]
    }
  },
  created () {
    this.isFetching = true
    this.$store.dispatch(Actions.ADMIN_GET_USERS)
      .then(data => {
        this.users = data
      })
      .finally(() => {
        this.isFetching = false
      })
  },
}

</script>
<style lang="scss" scoped>
</style>
