<template>
    <section class="section columns is-vcentered waves-bg">
      <div class="column is-flex">
        <img style="margin: auto auto;" src="../assets/img/logo_white@1x.svg" alt="">
      </div>
      <div class="column">
        <div class="form-holder column is-8-widescreen is-12-desktop">
          <section v-if="$store.state.error.currentError" class="is-medium">
            <RequestError :error="$store.state.error.currentError" :simple="true"/>
          </section>
          <b-message v-if="nextUrl" type="is-warning">Please log in to access that page</b-message>
          <router-view v-if="$route.name === 'AccountClaimExisting'" :recovery-token="recoveryToken" @login-success="handleExistingAccountClaim" />
          <router-view v-else-if="$route.name === 'AccountClaimNew'"  :recovery-token="recoveryToken" @login-success="handleNewAccountClaim" />
          <router-view v-else @login-success="handleLogin"></router-view>
          <div>
            <span v-if="$route.name === 'AccountClaimNew'" class="level-item has-text-grey">
              <router-link :to="{name : 'AccountClaimExisting'}" class="has-text-link has-text-weight-semibold">Already have an account?</router-link>
            </span>
            <span v-if="$route.name === 'AccountClaimExisting'" class="level-item has-text-grey">
               <router-link :to="{name: 'AccountClaimNew'}" class="has-text-link has-text-weight-semibold">Don't have an account yet?</router-link></span>
            <span v-if="$route.name === 'Login'" class="level-item has-text-grey">
              <router-link :to="{name: 'Registration', params:{nextUrl: nextUrl} }" class="has-text-link has-text-weight-semibold">Don't have an account yet?</router-link></span>
            <span v-if="$route.name === 'Login'" class="level-item has-text-grey">
              <router-link :to="{name: 'ForgotPassword' }" class="has-text-link has-text-weight-semibold">Password forgotten?</router-link></span>
            <span v-if="$route.name === 'ForgotPassword'" class="level-item has-text-grey">
              <router-link :to="{name: 'Login', params:{nextUrl: nextUrl} }" class="has-text-link has-text-weight-semibold">Back to login</router-link></span>
            <span  v-if="$route.name === 'Registration'" class="level-item"><router-link :to="{name : 'Login', params:{nextUrl: nextUrl} }" class="has-text-link has-text-weight-semibold">Already have an account?</router-link></span>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import Actions from '@/store/actions'

export default {
  name: 'Authentication',
  components: {
  },
  props: {
    recoveryToken: {
      required: false
    },
    nextUrl: {
      type: String,
      required: false
    },
  },
  methods: {
    handleLogin () {
      if (this.nextUrl) { this.$router.push({ path: this.nextUrl }) } else { this.$router.push({ name: 'AgreementList' }) }
    },
    handleExistingAccountClaim () {
      console.log('Login successful, call merge endpoint here.')
      this.$store.dispatch(Actions.MERGE_ACCOUNT, {
        userId: this.$store.getters.userId,
        payload: { recovery_token: this.recoveryToken }
      })
        .finally(() => {
          this.$router.push({ name: 'ProfileListMerge' })
        })
        .catch(error => {
          console.error(`Could not merge account : ${error}`)
          this.$store.dispatch(Actions.STICK_ERROR)
          this.$router.push({ name: 'ProfileList' })
        })
    },
    handleNewAccountClaim () {
      this.$router.push({ name: 'ProfileList' })
    }
  }
}
</script>
<style lang="scss" scoped>
  .form-holder {
    border-radius: 2px;
    background-color: white;
    padding: 2.5rem 1.5rem !important;

    .form {
      padding: 0 .5rem 1.5rem;
      max-width: 320px;
      margin: 0 auto;
    }
  }
  span {
    padding-top: 0.5rem;
  }
</style>
