const Actions = {
  // user
  REGISTER: 'Register',
  LOGIN: 'Log in',
  LOGOUT: 'Log out',
  AUTHENTICATE: 'Authenticate',
  LOAD_USER_FROM_TOKEN: 'Load user from token',
  RESET_PASSWORD: 'Reset password',
  SEND_PASSWORD_RESET: 'Send password reset',
  MERGE_ACCOUNT: 'Merge accounts',
  REFRESH_USER: 'Refresh user',
  GET_USER_PROFILES: 'Get user profiles',
  CREATE_USER_PROFILE: 'Create user profile',
  DELETE_USER_PROFILE: 'Delete profile',
  MERGE_USER_PROFILES: 'Merge profile',
  SET_USER_PROFILE: 'Set single user profile',
  ADMIN_GET_USERS: 'Get all users',

  // error
  ERROR: 'Error',
  RESET_ERROR: 'Reset Error',
  STICK_ERROR: 'Stick Error',

  // profile
  GET_PROFILE: 'Get profile',
  UPDATE_PROFILE: 'Update profile',

  // cruise
  GET_USER_CRUISES: 'Get user cruises',
  GET_CRUISE: 'Get cruise',
  UPDATE_CRUISE: 'Update cruise',
  CREATE_USER_CRUISE: 'Create user cruise',
  DELETE_CRUISE: 'Delete cruise',
  REFRESH_CRUISE: 'Refresh cruise',
  GET_CRUISE_GENERATED_VERSIONS: 'Get generated versions of cruise',
  SET_CRUISE_LOCKED: 'Set cruise locked status',
  GET_CRUISE_ROLE_STATUSES: 'Get cruise user roles status',
  VALIDATE_CRUISE: 'Validate cruise',
  GENERATE_CONTRACT: 'Generate contract',
  START_SETTLING: 'Start settling',
  CANCEL_SETTLING: 'Cancel settling',
  SETTLE_CONTRACT: 'Settle contract',
  ARCHIVE_CONTRACT: 'Archive contract',
  CANCEL_SETTLE: 'Reopen contract (cancel settle)',
  ADD_FUEL_BUNKER: 'Add a new fuel bunker',
  UPDATE_FUEL_BUNKER: 'Update a fuel bunker',
  DELETE_FUEL_BUNKER: 'Delete a fuel bunker',
  ADD_CRUISE_APPROVAL: 'Add cruise approval',
  DELETE_CRUISE_APPROVAL: 'Delete cruise approval',
  GET_PROFILES_FOR_ROLE: 'Get profiles for cruise role',
  ADD_PROFILE_TO_ROLE: 'Add profile as cruise role',
  DELETE_PROFILE_FROM_ROLE: 'Delete profile from cruise role',
  ADD_PROFILE_DISEMB: 'Add disemb data to passenger profile',

  // payment
  GET_CRUISE_PAYMENT_CALLS: 'Get all payment calls for cruise',
  ADD_PAYMENT_CALL: 'Add payment call',
  UPDATE_PAYMENT_CALL: 'Update payment call',
  DELETE_PAYMENT_CALL: 'Delete payment call',
  ADD_PAYMENT: 'Add payment',
  // UPDATE_PAYMENT: 'Update payment',
  CANCEL_PAYMENT: 'Cancel payment',

  // yacht
  GET_USER_YACHTS: 'Get user yachts',
  GET_YACHT: 'Get yacht',
  CREATE_YACHT: 'Create yacht',
  UPDATE_YACHT: 'Update yacht',
  DELETE_YACHT: 'Delete yacht',
  UPLOAD_YACHT_PICTURE: 'Upload yacht picture',
  DELETE_YACHT_PICTURE: 'Delete yacht picture',

  // contact
  GET_USER_CONTACTS: 'Get user contacts',
  CREATE_USER_CONTACT: 'Create contact',
  DELETE_CONTACT: 'Delete contact',

  // waypoint
  GET_CRUISE_WAYPOINTS: 'Get cruise waypoints',
  CREATE_WAYPOINT: 'Create waypoint',
  SET_SELECTED_WAYPOINT: 'Set selected waypoint',
  SAVE_WAYPOINT: 'Save waypoint',
  DELETE_WAYPOINT: 'Delete waypoint',

  // geo
  GET_PORTS: 'Get ports',
  GET_COUNTRIES: 'Get countries',

}

export default Actions
