var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news",attrs:{"id":"News1"}},[_c('div',{staticClass:"news-container"},[_c('h4',[_vm._v("Follow the last news about Wolfgang")]),_c('div',{staticClass:"news-box-wrap"},[(_vm.blogdetails.length > 0)?_c('carousel',{attrs:{"navText":[false],"items":3.5,"loop":false,"margin":30,"responsiveClass":true,"autoplay":false,"dots":false,"responsive":{0: {
             items: 1,
             nav: true,
         rewind:true,
         responsiveClass: true,

           },
           600: {
             items: 3,
             nav: true,
         rewind:true,
         responsiveClass: true,

           },
           1000: {
             items: 3,
             nav: true,
             loop: false,
             margin: 30,
         rewind:true,

           }}}},_vm._l((_vm.blogdetails),function(blog,index){return _c('div',{key:index,staticClass:"news-box"},[_c('div',{staticClass:"news-box-img",style:({
              'background-image':
                'url(' +
                blog._embedded['wp:featuredmedia'][0].source_url +
                ')'
            })}),_c('h5',[_c('a',{staticClass:"blog_title",attrs:{"target":"_blank","href":blog.link}},[_vm._v(_vm._s(blog.title.rendered))])]),_c('p',[_vm._v(_vm._s(_vm.getPostDate(blog.date)))])])}),0):_vm._e()],1)]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-account"},[_c('h3',[_vm._v("Discover Wolfgang")]),_c('button',{staticClass:"try-demo-big fnt-lib-i",attrs:{"id":"contact-us-trigger"}},[_vm._v(" Contact Us ")]),_c('p',[_vm._v("Already have an Account?")]),_c('a',{staticClass:"signin fnt-lib-i",attrs:{"id":"signin-trigger-account"}},[_vm._v("Log in")])])
}]

export { render, staticRenderFns }