<template>
  <section class="section">
    <div class="container section-box content">
      <h3 class="title">Wolfgang's SuperYacht Cruise Contract</h3>
      <ul>
        <li><a href="/files/W4.0_SYCA_Standard_terms_June21.pdf">Standard Terms</a> <a href="/files/FR_W.4.0_SYCA_Conditions_générales_June21.pdf">[French]</a></li>
        <li><a href="/files/W3_SYCA_Special_Terms_July20.pdf">Special Terms</a> <a href="/files/W3_SYCA_Special_Terms_July20_FR.pdf">[French]</a></li>
        <li><a href="/files/W3_Exhibit_3_Tax_regime_applicable_in_France_V3_3_July20.pdf">Exhibit 3 Tax Regime Applicable in France</a> <a href="/files/W3_Exhibit_3_Tax_regime_applicable_in_France_V3_3_July20_FR.pdf">[French]</a></li>
      </ul>
      <h3 class="title">Resources</h3>
      <ul>
        <li><a href="/tos">Wolfgang's Terms of Service (CGU/CGV)</a></li>
      </ul>
      <h3 class="title">Contact</h3>
      <ul>
        <li>✉️ <a href="mailto:info@wolfgang.pro">info@wolfgang.pro</a></li>
        <li>📞 <a href="tel:+33 7 86 98 11 24">+33 7 86 98 11 24</a></li>
      </ul>
      <h3 class="title"><router-link :to="{name: 'FAQ'}">Frequently Asked Questions 👉</router-link></h3>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Help',
}

</script>
<style lang="scss" scoped>
</style>
