<template>
  <b-notification :closable="false" class="loading-box" data-test="loading" aria-busy="true">
    <b-loading :active="true" :is-full-page="false" />
  </b-notification>
</template>

<script>
export default {
  name: 'Loading',
  props: {
  }
}
</script>
<style lang="scss" scoped>
  .loading-box {
    position: relative;
    height: 100px;
  }
</style>
