import { render, staticRenderFns } from "./DocumentSection.vue?vue&type=template&id=1d70dbb6&scoped=true"
import script from "./DocumentSection.vue?vue&type=script&lang=js"
export * from "./DocumentSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d70dbb6",
  null
  
)

export default component.exports