<template>
  <section class="section">
    <b-progress v-if="status != 'SIGNED'" :type="progressType"></b-progress>
  </section>
</template>
<script>
import { signContractWithOauth } from '@/api/contract'
import Actions from '@/store/actions'

export default {
  name: 'ContractSigning',
  props: {
    code: {
      type: String,
      required: false,
      default: null
    },
    state: {
      type: String,
      required: false,
      default: null
    },
    cruiseId: {
      type: Number,
      required: true
    },
    cruiseVersion: {
      type: Number,
      required: true
    },
    status: {
      type: String,
      required: false
    }
  },
  data () {
    // "code=76faf71bc653ee9da2d423dec04b9229&state=quBhDQ2gnp8aPWxi9JmYPdW1p8bKLd"
    return {
    }
  },
  computed: {
    progressType: function () {
      if (this.status === 'WAITING') {
        return 'is-warning'
      } else if (this.status === 'RETRIEVING') {
        return 'is-success'
      } else {
        return 'is-info'
      }
    }
  },
  created () {
    console.log(`signContractWithOauth ${this.code} ${this.state}`)
    console.log(`signing cruise ${this.cruiseId} ${this.cruiseVersion}`)
    signContractWithOauth({
      cruiseId: this.cruiseId,
      cruiseVersion: this.cruiseVersion,
      userId: this.$store.getters.userId,
      oauthCode: this.code,
      oauthState: this.state
    })
      .then(({ data }) => {
        console.log('returned', data)
      })
      .catch(() => {
        console.log('sticking error:', this.$store.state.error.currentError)
        this.$store.dispatch(Actions.STICK_ERROR)
      })
      .finally(() => {
        this.$router.replace({ name: 'ContractSection', params: { cruiseId: this.cruiseId, cruiseVersion: this.cruiseVersion } })
      })
  }
}
</script>
