import dayjs from 'dayjs'
import moment from 'moment'
import 'moment-timezone'

function formatDate (d, format) {
  if (!d) { return 'n/a' }
  if (typeof d === 'string') { d = dayjs(d).toDate() }
  const d2 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 12)
  return d2.toLocaleDateString([], format)
}

export default {
  value2date: (value) => {
    const date = new Date(value)
    return value ? new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000) : null
  },
  date2value: (date) => {
    return date ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000) : null
  },
  string2date: (d) => {
    return d ? dayjs(d).toDate() : null
  },
  date2stringDateOnly: (d) => {
    if (!d) { return null }
    const isoDate = new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString()
    return isoDate.split('T')[0] + 'T00:00:00Z'
  },
  localeDatetime: (d) => {
    if (!d) { return 'n/a' }
    return moment(d).format('MMMM Do YYYY, H:mm')
  },
  localeDate: (d) => {
    return formatDate(d, { year: 'numeric', month: 'long', day: 'numeric' })
  },
  localeDateLong: (d) => {
    return formatDate(d, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
  },
  datetimeCreatorWithTZ: (tz) => {
    if (!tz || tz === '') tz = 'UTC'
    // console.log(`datetimeCreatorWithTZ called: ${tz} | ${moment().tz(tz).toDate()}`)
    return moment().tz(tz).toDate()
  },
  getOffsetForDate: (d, tz) => {
    if (!d) { return null }
    d = moment(d).toDate()
    return (d.getTimezoneOffset() - moment.tz.zone(tz).utcOffset(d))
  },
  formatDateWithTZ: (d, tz) => {
    if (!tz || tz === '') tz = 'UTC' // Intl.DateTimeFormat().resolvedOptions().timeZone
    var offset = moment.tz(d, tz).format('Z')
    if (offset === '+00:00') offset = 'UTC'
    // console.log('formatDateWithTZ', d, tz, offset, d.toLocaleString())
    return d.toLocaleString().replace(/:\d{2}( PM| AM|$)/, '$1') + ' ' + offset
  }
}
