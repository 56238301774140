<template>
  <div class="lap-section">
    <div style="position:relative;text-align: center;">
      <img class="frame" src="@/assets/img/image/frame.png" />
      <video playsinline player.controls="false" preload="metadata" class="laptop_gif" style="pointer-events: none;" muted loop autoplay ><source src="../assets/img/image/laptop.mp4" type="video/mp4"></video>
    </div>

    <h4 class="fnt-lib-r" >Bluetech for Superyachts</h4>
    <div class="about-wrapper-lap">
    <div class="d-flex lap-op">
      <div class="lap-d fnt-p">
        <p>Thanks to itinerary planning, Wolfgang can introduce environmental optimization to your cruises.</p>
      </div>
      <div class="lap-d fnt-p">
        <p>We harness the power of AI to develop the first environmental impact model of yachting to analyze and reduce its footprint</p>
      </div>
      <div class="lap-d fnt-p">
        <p>We empower captains and crew with unique software for controlling emissions and environmental management</p>
      </div>
    </div>
    </div>
    <button class="try-demo-big fnt-lib-i try-demo-trigger">Contact Us</button>

  </div>
</template>

<script>
import jQuery from 'jquery'
const $ = jQuery

export default {
  mounted () {
    $('.try-demo-trigger').click(function () {
      $('#contact-us-modal').addClass('active')
      $('body').append('<div class="modal-overlay"></div>')
    })
    $('.close-modal').click(function () {
      $('#contact-us-modal').removeClass('active')
      $('.modal-overlay').remove()
    })
  }
}
</script>

<style scoped src="@/assets/css/style.css">

</style>