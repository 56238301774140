<template>
  <div>
    <div data-test="yacht" :aria-busy="isLoading">
      <div v-if="userYachts" class="columns is-vcentered">
        <div class="column is-gapless">
          <h1 class="title">Yacht</h1>
        </div>
        <div class="column">
          <multi-select
            ref="yachtSelectionBox"
            :value="selectedYacht"
            :aria-busy="isLoading"
            data-test="yacht-select"
            :disabled="cruiseReadOnly"
            :loading="isLoading"
            label="name"
            placeholder="Type yacht name or create new…"
            select-label=""
            track-by="id"
            :hide-selected="false"
            :options="userYachts"
            deselect-label="Deselect yacht"
            @input="updateCruiseYacht"
          >
            <template #beforeList>
              <div>
                <a style="display: block; padding:.75rem;" class="is-italic" @click="enableYachtCreation">Add new</a>
              </div>
            </template>
            <template #noResult>No matching yacht name.</template>
            <template #option="props" >
                <span class="option__title">{{ props.option.name }}</span>
            </template>
          </multi-select>
        </div>
      </div>
      <div v-if="cruiseYacht">
        <yacht-detail-form :yacht-id="cruiseYacht.id" :yacht-version="cruiseYacht.version" :cruise-read-only="cruiseReadOnly" :is-embedded="true" />
      </div>
    </div>
    <b-modal
      :active.sync="isYachtCreationModalShown"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="min-width: 300px;">
        <YachtCreateForm
          @yacht-created="handleYachtCreated"
        />
      </div>
    </b-modal>
    <hr />
    <role-form
      :agreement-id="agreementId"
      role="carriers"
      role-friendly-name-singular="carrier"
      role-friendly-name-plural="carriers"
    />
    <hr />
    <role-form
      :agreement-id="agreementId"
      role="central_agents"
      role-friendly-name-singular="central agent"
      role-friendly-name-plural="central agents"
      multiple
    />
    <hr />
    <role-form
      :agreement-id="agreementId"
      role="captains"
      role-friendly-name-singular="captain"
      role-friendly-name-plural="captains"
      multiple
    />
  </div>
</template>

<script>
import RoleForm from '@/components/forms/cruise/RoleForm'
//
import MultiSelect from 'vue-multiselect'
import YachtCreateForm from '@/components/forms/yacht/YachtCreateForm'
import YachtDetailForm from '@/components/forms/yacht/YachtDetailForm'
import Actions from '@/store/actions'

export default {
  name: 'YachtSection',
  components: {
    RoleForm,
    YachtCreateForm,
    YachtDetailForm,
    MultiSelect
  },
  props: ['agreementId'],
  data () {
    return {
      isYachtCreationModalShown: false,
      isLoading: true,
    }
  },
  computed: {
    cruiseReadOnly () { return this.$store.getters.cruiseReadOnly },
    userYachts () { return this.$store.state.yacht.yachts },
    cruiseYacht () { return this.$store.state.cruise.cruise.yacht },
    selectedYacht () { return this.cruiseYacht ? this.$store.state.yacht.yachts.find(y => y.id === this.cruiseYacht.id) : null } // Only use ID (since list might have more recent versions)
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.isLoading = true
      this.$store.dispatch(Actions.GET_USER_YACHTS)
        .finally(() => { this.isLoading = false })
    },
    updateCruiseYacht (yacht) {
      this.isLoading = true
      return this.$store.dispatch(Actions.UPDATE_CRUISE, { yacht_id: yacht ? yacht.id : null })
        .finally(() => { this.isLoading = false })
    },
    handleYachtCreated (createdYacht) {
      this.isLoading = true
      this.isYachtCreationModalShown = false
      this.updateCruiseYacht(createdYacht)
        .finally(() => { this.isLoading = false })
    },
    enableYachtCreation () {
      this.isYachtCreationModalShown = true
      this.$refs.yachtSelectionBox.$refs.search.blur()
    }
  }
}
</script>

<style lang="scss">
.b-tabs .tab-content {
  overflow: visible !important;
}

.form-wrapper {
  margin-top: 2rem;
  padding: 2rem;
  border: 1px solid lightgray;
  border-radius:4px;
}

.multiselect__option--selected {
  .option__delete {
    display: none;
  }
}
.option__delete {
  transition: background-color .2s ease;
  &:hover {
    background-color: #E53935; // Material red 600
  }
}
</style>
