import axios from '@/api/config'
import Actions from '@/store/actions'
import dayjs from 'dayjs'

const MU_SET_WAYPOINTS = 'μ: Set waypoints'
const MU_SET_WAYPOINT = 'μ: Set waypoint'
const MU_DELETE_WAYPOINT = 'μ: Delete waypoint'
const MU_SET_SELECTED_WAYPOINT = 'μ: Set selected waypoint'
const MU_ADD_TEMP_WAYPOINT = 'μ: Add temp waypoint'
const MU_SET_LOADING = 'μ: Set waypoints loading status'

const waypointModule = {
  state: {
    waypoints: [],
    selectedWaypoint: false,
    loading: false,
  },
  mutations: {
    [MU_SET_WAYPOINT] (state, { id, wpData }) {
      const found = state.waypoints.find(n => id === n.id)
      if (found) {
        Object.assign(found, wpData)
      }
    },
    [MU_ADD_TEMP_WAYPOINT] (state, newWP) {
      const tempWP = Object.assign({
        id: 666,
        errors: [],
        warnings: [],
        arr_date: new Date(),
        dep_date: new Date(),
        is_temp: true
      }, newWP)
      if (state.selectedWaypoint) {
        const wpIds = state.waypoints.map((wp) => wp.id)
        const idx = wpIds.indexOf(state.selectedWaypoint.id)
        state.waypoints.splice(idx + 1, 0, tempWP)
      } else {
        state.waypoints.push(tempWP)
      }
      state.selectedWaypoint = tempWP
    },
    [MU_SET_WAYPOINTS] (state, freshWPs) {
      state.waypoints = freshWPs
      if (state.selectedWaypoint) {
        state.selectedWaypoint = state.waypoints.find(wp => wp.id === state.selectedWaypoint.id)
      }

      var lastTz = 'UTC'
      state.waypoints.sort((a, b) => {
        return dayjs(a.arr_date).unix() - dayjs(b.arr_date).unix()
      })
        .forEach((item, index) => {
          item.sn = index + 1
          if (!(item.arr_date instanceof Date)) item.arr_date = dayjs(item.arr_date).toDate()
          if (!(item.dep_date instanceof Date)) item.dep_date = dayjs(item.dep_date).toDate()
          if (item.timezone) {
            lastTz = item.timezone
          } else {
            item.timezone = lastTz
          }
        })
      // if (state.waypoints.length > 0) {
      //   state.waypoints[0].is_call = true
      // }
    },
    [MU_DELETE_WAYPOINT] (state, id) {
      const index = state.waypoints.findIndex(item => item.id === id)
      state.waypoints.splice(index, 1)
      if (state.waypoints.length === 0) {
        state.selectedWaypoint = false
      } else if (state.selectedWaypoint && state.selectedWaypoint.id === id) {
        state.selectedWaypoint = state.waypoints[state.waypoints.length - 1]
      }
    },
    [MU_SET_SELECTED_WAYPOINT] (state, payload) {
      state.selectedWaypoint = payload
    },
    [MU_SET_LOADING] (state, payload) {
      state.loading = payload
    },
  },
  actions: {
    [Actions.CREATE_WAYPOINT] ({ commit, state, dispatch, rootState }, newWP) {
      if (state.waypoints.length === 0) {
        newWP.is_call = true
      }
      let selectedWpId = 0
      if (state.selectedWaypoint) {
        selectedWpId = state.selectedWaypoint.id
      }
      commit(MU_ADD_TEMP_WAYPOINT, newWP)

      return axios.post(`/cruise/${rootState.cruise.cruise.id}/waypoint/`, { ...newWP, selected_wp_id: selectedWpId })
        .then(({ data }) => {
          var ids = state.waypoints.map(wp => wp.id)
          var newWPs = data.filter((wp) => ids.indexOf(wp.id) === -1)
          commit(MU_SET_WAYPOINTS, data)
          dispatch(Actions.REFRESH_CRUISE)
          if (newWPs.length > 0) commit(MU_SET_SELECTED_WAYPOINT, newWPs[newWPs.length - 1])
          return data
        })
    },

    [Actions.SET_SELECTED_WAYPOINT] ({ commit }, payload) {
      commit(MU_SET_SELECTED_WAYPOINT, payload)
    },

    [Actions.GET_CRUISE_WAYPOINTS] ({ commit, rootState }) {
      commit(MU_SET_LOADING, true)
      return axios.get(`/cruise/${rootState.cruise.cruise.id}/waypoint/`)
        .then(({ data }) => {
          commit(MU_SET_WAYPOINTS, data)
          return data
        })
        .finally(() => {
          commit(MU_SET_LOADING, false)
        })
    },

    [Actions.SAVE_WAYPOINT] ({ commit, state, rootState, dispatch }, { waypointId, waypointData, commitTemp }) {
      if (commitTemp) {
        commit(MU_SET_WAYPOINT, { id: waypointId, wpData: waypointData })
      }
      return axios.put(`/cruise/${rootState.cruise.cruise.id}/waypoint/${waypointId}`, waypointData)
        .then(({ data }) => {
          commit(MU_SET_WAYPOINTS, data)
          dispatch(Actions.REFRESH_CRUISE)
          return data
        })
    },

    [Actions.DELETE_WAYPOINT] ({ commit, rootState, dispatch }, waypointId) {
      commit(MU_DELETE_WAYPOINT, waypointId)
      return axios.delete(`/cruise/${rootState.cruise.cruise.id}/waypoint/${waypointId}`)
        .then(({ data }) => {
          commit(MU_SET_WAYPOINTS, data)
          dispatch(Actions.REFRESH_CRUISE)
          return data
        })
    },
  },
}

export default waypointModule
