<template>
  <b-message v-if="error && (showValidationErrors || !isValidationError)" :title="simple ? '' : errorTitle" type="is-danger" aria-close-label="Close message">
    <template v-if="error.response && error.response.data.message">
      <p>{{ error.response.data.message }}</p>
    </template>
    <template v-else-if="error.response">
      {{ error.response.data.msg }}
      <ul v-for="(value, key) in errorMessages" :key="key">
        <li><strong>{{ key }}</strong>
          <ul>
            <li v-for="(val, index) in value" :key="index">{{val}}</li>
          </ul>
        </li>
      </ul>
    </template>
    <template v-else-if="error.request">Server not responding. If the problem persists, please contact us at <a href="mailto:support@wolfgang.pro">support@wolfgang.pro</a>.
    </template>
    <template v-else-if="error.message">{{ error }}</template>
    <template v-else-if="simple && errorTitle">{{ errorTitle }}</template>
    <template v-else>Error: {{ error }}</template>
    <pre v-if="showDebug" class="debug-details">Debug information:
      <template v-if="error.response && error.response.data && error.response.data.exception">
      {{ error.response.data.exception }}
      </template>
      {{ JSON.stringify(error, null, '\t') }}
    </pre>
    <div v-if="!simple" class="show-debug-button" @click="showDebug=!showDebug">
      <b-icon pack="fas" icon="cogs" size="is-small" class="icon" />
    </div>
  </b-message>
</template>

<script>
import Actions from '@/store/actions'
import { nestedVal } from '@/helpers/utils'

export default {
  name: 'RequestError',
  props: {
    error: {
      required: true
    },
    simple: {
      type: Boolean,
      default: false
    },
    showValidationErrors: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showDebug: false
    }
  },
  computed: {
    errorTitle () {
      if (this.error.title) {
        return this.error.title
      } else if (!this.error.response) {
        return 'Connection Error'
      } else if (this.isValidationError) {
        return 'Validation Error'
      } else if (this.error.response.status === 401) {
        return 'Unauthorised'
      } else if (this.error.response.status === 404) {
        return 'Not Found'
      } else if (this.error.response.status >= 500 && this.error.response.status <= 599) {
        return 'Server Error'
      } else {
        return 'Client Error'
      }
    },
    isValidationError () {
      return (this.error.response && this.error.response.status === 422)
    },
    errorMessages () {
      // const res = nestedVal(this.error, ['response', 'data'])
      // console.log('errors', res, this.error.response.data.errors)
      if (nestedVal(this.error, ['response', 'data'])) {
        if (this.error.response.data.errors) {
          // console.log(this.error.response.data.errors)
          const arr = this.error.response.data.errors
          if ('json' in arr) return arr.json
          if ('files' in arr) return arr.files
          return arr
        } else if (typeof this.error.response.data === 'object' && 'messages' in this.error.response.data) {
          return this.error.response.data.messages
        }
      }
      return []
    }
  },
  methods: {
    discardError: function () {
      this.$store.dispatch(Actions.RESET_ERROR, true)
    },
  }
}
</script>
<style lang="scss" scoped>

  .show-debug-button {
    text-align: right;
    margin-top: 10px;
    margin-bottom: -1.5em;
    margin-right: -0.75em;
    .icon {
      color: #BBB;
    }
  }
  pre.debug-details {
    max-width: 60em;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }
</style>
