<template>
  <div data-test="waypoint-form" class="card" :aria-busy="isUpdating">
      <header v-if="!noHeader" class="card-header">
        <p v-if="selectedWP && selectedWP.is_temp" class="card-header-title">Adding waypoint…</p>
        <p v-else-if="selectedWP" class="card-header-title">Selected waypoint: {{ selectedWP.sn }}</p>
        <p v-else class="card-header-title">Create new waypoint</p>
      </header>
      <div class="card-content is-clearfix">
        <!-- Number -->
        <div v-if="!selectedWP" class="columns">
          <div class="column has-text-success">Click on map to add marker</div>
        </div>
        <div v-if="selectedWP" :key="selectedWP.id">
          <!-- LatLng -->
          <b-message
            v-if="fieldMessage(selectedWP,'embs')"
            :type="fieldType(selectedWP,'embs')"
          >
            {{ fieldMessage(selectedWP,'embs') }}
          </b-message>
          <b-message
            v-if="fieldMessage(selectedWP,'disembs')"
            :type="fieldType(selectedWP,'disembs')"
          >
            {{ fieldMessage(selectedWP,'disembs') }}
          </b-message>
          <div class="columns is-mobile">
            <div class="column">
              <b-field>
                <p class="control">
                  <span class="button is-small is-static control-label">lat.</span>
                </p>
                <b-input
                  :value="roundLatitude"
                  data-test="latitude"
                  size="is-small"
                  :disabled="cruiseReadOnly || selectedWP.is_temp"
                  expanded
                  @change.native="e => updateWp(selectedWP, 'latitude', e.target.value)"
                />
              </b-field>
            </div>
            <div class="column">
              <b-field>
                <p class="control">
                  <span class="button is-small is-static control-label">lon.</span>
                </p>
                <b-input
                  :value="roundLongitude"
                  data-test="longitude"
                  size="is-small"
                  :disabled="cruiseReadOnly || selectedWP.is_temp"
                  expanded
                  @change.native="e => updateWp(selectedWP, 'longitude', e.target.value)"
                />
              </b-field>
            </div>
          </div>
          <template v-if="selectedWP.is_temp">
            <div class="columns is-mobile">
              <div class="column">
                <progress class="progress is-small is-primary" max="100">10%</progress>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="columns gapless">
              <div class="column geoname">
                <span v-if="selectedWP.is_national_water && selectedWP.geoname" data-test="national-waters">National Waters of: {{ selectedWP.geoname.display_name }}</span>
              </div>
              <div class="column geoname">
                <span v-if="selectedWP.timezone" data-test="timezone">{{ selectedWP.timezone }} time</span>
              </div>
            </div>
            <!-- is_call -->
            <div class="columns is-mobile">
              <div class="column">
                <b-field
                  :type="fieldType(selectedWP,'is_call')"
                  :message="fieldMessage(selectedWP,'is_call')"
                >
                  <b-checkbox
                    ref="is_call"
                    :value="selectedWP.is_call"
                    data-test="is_call"
                    class="is-pulled-right"
                    size="is-small"
                    :disabled="cruiseReadOnly || callOnly"
                    @input="val => updateWp(selectedWP, 'is_call', val)"
                  >This waypoint is a Call
                  </b-checkbox>
                </b-field>
              </div>
            </div>
            <!-- Call -->
            <div v-if="selectedWP.is_call" class="columns">
              <div class="column">
                <b-field>
                  <p class="control">
                    <span class="button is-small is-static control-label">Call</span>
                  </p>
                  <b-input
                    :value="selectedWP.call_location_str"
                    data-test="call_location_str"
                    size="is-small"
                    :disabled="cruiseReadOnly"
                    expanded
                    @change.native="e => updateWp(selectedWP, 'call_location_str', e.target.value)"
                  />
                </b-field>
              </div>
            </div>
            <!-- Arrival -->
            <div class="columns is-mobile">
              <div class="column">
                <b-field :type="fieldType(selectedWP,'arr_date')">
                  <p class="control">
                    <span class="button is-small is-static control-label">arrival</span>
                  </p>
                  <p data-test="arr_date" class="control is-expanded">
                    <b-datetimepicker
                      ref="arr_date"
                      :value="selectedWP.arr_date"
                      placeholder="Click to select..."
                      icon="calendar"
                      pack="fas"
                      :disabled="cruiseReadOnly"
                      size="is-small"
                      :datepicker="{'first-day-of-week':1, 'date-creator':datetimeCreatorWithTZ}"
                      :timepicker="{'time-creator':datetimeCreatorWithTZ}"
                      :datetime-formatter="(d) => formatDateWithTZ(d, selectedWP.timezone)"
                      :tz-offset="getOffsetForDate(selectedWP.arr_date, selectedWP.timezone)"
                      :use-html5-validation="false"
                      @input="(val) => { datetimeVals['arr_date'] = val }"
                      @blur="updateWp(selectedWP, 'arr_date', datetimeVals['arr_date'])"
                    />
                  </p>
                </b-field>
                <p v-if="fieldType(selectedWP,'arr_date')">
                  <b-field
                  data-test="arr_date_msg"
                  :type="fieldType(selectedWP,'arr_date')"
                  :message="fieldMessage(selectedWP,'arr_date')"
                  />
                </p>
              </div>
            </div>
            <!-- Departure -->
            <div v-if="selectedWP.is_call" class="columns is-mobile">
              <div class="column">
                <b-field>
                  <p class="control">
                    <span class="button is-small is-static control-label">departure</span>
                  </p>
                  <p data-test="dep_date" class="control is-expanded">
                    <b-datetimepicker
                      ref="dep_date"
                      :value="selectedWP.dep_date"
                      placeholder="Click to select..."
                      icon="calendar"
                      pack="fas"
                      :disabled="cruiseReadOnly"
                      size="is-small"
                      :datepicker="{'first-day-of-week':1}"
                      :timepicker="{}"
                      :datetime-formatter="(d) => formatDateWithTZ(d, selectedWP.timezone)"
                      :tz-offset="getOffsetForDate(selectedWP.dep_date, selectedWP.timezone)"
                      :use-html5-validation="false"
                      @input="(val) => { datetimeVals['dep_date'] = val }"
                      @blur="updateWp(selectedWP, 'dep_date', datetimeVals['dep_date'])"
                    />
                  </p>
                </b-field>
                <p v-if="fieldType(selectedWP,'dep_date')">
                  <b-field
                  :type="fieldType(selectedWP,'dep_date')"
                  :message="fieldMessage(selectedWP,'dep_date')"
                  data-test="dep_date_msg"
                  />
                </p>
              </div>
            </div>
            <button
              v-if="selectedWP.id && !cruiseReadOnly"
              data-test="delete"
              class="button is-danger is-small is-pulled-right"
              @click.prevent="onDeleteClick(selectedWP)"
            >
              <b-icon icon="trash-alt" pack="fas"></b-icon>
              <span>Delete waypoint</span>
            </button>
          </template>
        </div>
      </div>
    </div>
</template>

<script>
import DateFormatters from '@/helpers/date_formatters'
import Actions from '@/store/actions'

export default {
  name: 'WayPointForm',
  components: {
    // DatePicker
  },
  props: {
    cruiseReadOnly: {
      type: Boolean,
      default: false
    },
    selectedWP: {},
    noHeader: {
      type: Boolean,
      default: false
    },
    callOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isUpdating: false,
      datetimeVals: {},
    }
  },
  computed: {
    roundLatitude: function () {
      return this.selectedWP.latitude.toFixed(4)
    },
    roundLongitude: function () {
      return this.selectedWP.longitude.toFixed(4)
    },
  },
  methods: {
    ...DateFormatters,
    updateWp (wp, prop, val, confirmed = false) {
      if (this.cruiseReadOnly) return
      if (!confirmed) {
        if (prop === 'is_call' && !val && wp.has_disembs) {
          this.$buefy.dialog.confirm({
            message: 'This call has passengers embarking/disembarking. This action will remove these emb/disembs.',
            confirmText: 'Proceed anyway',
            type: 'is-warning',
            onConfirm: () => this.updateWp(wp, prop, val, true),
            onCancel: () => { this.$refs.is_call.newValue = true }
          })
          return
        }
      }
      const newWp = Object.assign({ [prop]: val })
      this.isUpdating = true
      this.$store.dispatch(Actions.SAVE_WAYPOINT, { waypointId: wp.id, waypointData: newWp })
        .finally(() => { this.isUpdating = false })
    },
    onDeleteClick (wp) {
      if (wp.has_disembs) {
        this.$buefy.dialog.confirm({
          message: 'This call has passengers embarking/disembarking. This action will remove these emb/disembs.',
          confirmText: 'Proceed anyway',
          type: 'is-warning',
          onConfirm: () => {
            this.isUpdating = true
            this.$store.dispatch(Actions.DELETE_WAYPOINT, wp.id)
              .finally(() => { this.isUpdating = false })
          }
        })
        return
      }

      this.isUpdating = true
      this.$store.dispatch(Actions.DELETE_WAYPOINT, wp.id)
        .finally(() => { this.isUpdating = false })
    },
    fieldType (wp, field) {
      if ('errors' in wp && field in wp.errors) {
        return 'is-danger'
      } else if ('warnings' in wp && field in wp.warnings) {
        return 'is-warning'
      } else {
        return ''
      }
    },
    fieldMessage (wp, field) {
      if ('errors' in wp && field in wp.errors) {
        return wp.errors[field]
      } else if ('warnings' in wp && field in wp.warnings) {
        return wp.warnings[field]
      } else {
        return ''
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .geoname {
      padding: 0;
      margin: 0;
      font-size: 70%;
      font-style: italic;
      text-align: right;
  }
  .card-content {
    padding: 1rem;
    @include touch {
      padding: 1rem;
    }
  }
  .columns .column {
    padding: 0.4rem 0.5rem 0.4rem 0.5rem;
    @include touch {
      padding: 0.2rem 0.5rem 0.4rem 0.5rem;
    }
  }
</style>
<style lang="scss">
  .control .datepicker input.input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
</style>
