<template>
  <section class="section">
    <div class="container">
      <nav class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <BackButton @click.native="onClickBack" />
          </div>
        </div>
        <div class="level-right"/>
      </nav>
      <div class="section-box">
        <profile-form :profile-id="id" :is-self="false" />
      </div>
    </div>
  </section>
</template>

<script>
import ProfileForm from '@/components/forms/profile/ProfileForm'
export default {
  name: 'ContactDetail',
  components: {
    ProfileForm
  },
  props: ['id'],
  data () {
    return {
    }
  },
  methods: {
    onClickBack () {
      this.$router.push({ name: 'ContactsList' })
    }
  }
}
</script>
<style lang="scss" scoped>
  .contact-name{
    font-size: 30px;
    margin-top: 42px;
}
</style>
