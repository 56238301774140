<template>
  <div class="news" id="News1">
    <div class="news-container">
      <h4>Follow the last news about Wolfgang</h4>
      <div class="news-box-wrap ">
        <carousel v-if="blogdetails.length > 0" :navText=[false] :items="3.5" :loop="false" :margin= 30 :responsiveClass="true" :autoplay="false" :dots="false" :responsive="{0: {
               items: 1,
               nav: true,
           rewind:true,
           responsiveClass: true,

             },
             600: {
               items: 3,
               nav: true,
           rewind:true,
           responsiveClass: true,

             },
             1000: {
               items: 3,
               nav: true,
               loop: false,
               margin: 30,
           rewind:true,

             }}" >
          <div
            class="news-box"
            v-for="(blog, index) in blogdetails"
            :key="index"
          >
            <div
              class="news-box-img"
              :style="{
                'background-image':
                  'url(' +
                  blog._embedded['wp:featuredmedia'][0].source_url +
                  ')'
              }"
            ></div>

            <h5>
              <a class="blog_title" target="_blank" :href="blog.link">{{
                blog.title.rendered
              }}</a>
            </h5>
            <p>{{ getPostDate(blog.date) }}</p>
          </div></carousel
        >
      </div>
    </div>
    <div class="create-account">
      <h3>Discover Wolfgang</h3>
      <button class="try-demo-big fnt-lib-i" id="contact-us-trigger">
        Contact Us
      </button>
      <p>Already have an Account?</p>
      <a class="signin fnt-lib-i" id="signin-trigger-account">Log in</a>
    </div>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

import jQuery from 'jquery'
import axios from 'axios'
import moment from 'moment'
const $ = jQuery
export default {
  components: {
    carousel
  },
  mounted () {
    if (process.env.VUE_APP_BLOG_REST_URL) {
      this.blogRestUrl = `${process.env.VUE_APP_BLOG_REST_URL}/wp-json/wp/v2/posts`
    } else {
      this.blogRestUrl = null
    }

    if (this.blogRestUrl) {
      axios
        .get(this.blogRestUrl, {
          params: {
            per_page: this.perPage,
            categories: this.category,
            _embed: true
          }
        })
        .then((response) => {
          this.blogdetails = response.data
        })
    }
    $(document).ready(function () {
      $('#signin-trigger-account').click(function () {
        $('#signin-modal').addClass('active')
        $('body').append('<div class="modal-overlay"></div>')
      })
      $('.close-modal').click(function () {
        $('#signin-modal').removeClass('active')
        $('.modal-overlay').remove()
      })
    })
    $('#contact-us-trigger').click(function () {
      $('#contact-us-modal').addClass('active')
      $('body').append('<div class="modal-overlay"></div>')
    })
    $('.close-modal').click(function () {
      $('#contact-us-modal').removeClass('active')
      $('.modal-overlay').remove()
    })
  },
  data () {
    return {
      blogdetails: [],
      blogRestUrl: ''
    }
  },
  methods: {
    getPostDate (date) {
      return moment(date).format('LL')
    }
  }
}
</script>

<style scoped src="@/assets/css/style.css"></style>
