<template>
  <div class="signup-and-signin-modal" id="signin-modal">
    <a class="close-modal"><img src="@/assets/img/image/BT_close.svg" /></a>
    <form autocomplete="on" @submit.prevent="login">
      <h2>Login</h2>
      <section v-if="$store.state.error.currentError" class="is-medium">
        <RequestError :error="$store.state.error.currentError" :simple="true" />
      </section>
      <b-field class="c-field" label="Email">
        <b-input
          v-model="loginData.email"
          type="email"
          autocomplete="email"
          placeholder="john.doe@example.xyz"
          required
        />
      </b-field>

      <b-field class="c-field" label="Password">
        <b-input
          v-model="loginData.password"
          type="password"
          autocomplete="current-password"
          placeholder="Password"
          password-reveal
          required
        />
      </b-field>
      <div style="text-align: right; color: #005e77; font-size: 18px">
        <u><a id="forgot-password-link">Forgot password</a></u>
      </div>

      <button
        :is-loading="isSending"
        :is-disabled="isSending"
        class="create-account-btn"
      >
        Login
      </button>
      <a class="accnt-a" id="account-signup">Don't have an account yet ?</a>
    </form>
  </div>
</template>

<script>
import jQuery from 'jquery'
import Actions from '@/store/actions'

const $ = jQuery
export default {
  name: 'Login',
  data () {
    return {
      loginData: {
        email: '',
        password: ''
      },
      isSending: true
    }
  },
  mounted () {
    $('#signin-trigger').click(function () {
      $('#signin-modal').addClass('active')
      $('body').append('<div class="modal-overlay"></div>')
    })
    $('.close-modal').click(function () {
      $('#signin-modal').removeClass('active')
      $('.modal-overlay').remove()
    })

    setTimeout(function () {
      $(document).on('click', '.modal-overlay', function () {
        $('.close-modal').trigger('click')
      })
    }, 500)

    $('#account-signup').click(function () {
      $('#signin-modal').removeClass('active')
      $('#signup-modal').addClass('active')
    })
  },
  methods: {
    login () {
      this.$store.dispatch(Actions.RESET_ERROR)
      this.$store
        .dispatch(Actions.LOGIN, this.loginData)
        .then(() => {
          this.$router.push({ name: 'AgreementList' })

          $('#signin-modal').removeClass('active')
          $('.modal-overlay').remove()
        })
        .finally(() => {
          this.isSending = false
        })
    }
  }
}
</script>

<style src="@/assets/css/style.css"></style>
