import Vue from 'vue'
import Vuex from 'vuex'
// Modules
import userModule from './modules/user'
import profileModule from './modules/profile'
import cruiseModule from './modules/cruise'
import yachtModule from './modules/yacht'
import geoModule from './modules/geo'
import contactModule from './modules/contact'
import waypointModule from './modules/waypoint'
import errorModule from './modules/error'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    user: userModule,
    profile: profileModule,
    cruise: cruiseModule,
    yacht: yachtModule,
    geo: geoModule,
    contact: contactModule,
    waypoint: waypointModule,
    error: errorModule
  }
})
