var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',_vm._l((this.collapsibleWaypoints),function(collapse,index){return _c('b-collapse',{key:index,staticClass:"card",class:{
        'is-selected': _vm.isSelected(collapse),
        'is-call': !collapse.type,
      },attrs:{"animation":"slide","open":index === 0,"aria-id":'waypoint-card' + index},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [(collapse.type === 'legs')?_c('div',{staticClass:"card-header",class:{ 'active-header': _vm.isSelected(collapse) },attrs:{"role":"button","aria-controls":'waypoint-card' + index,"aria-expanded":props.open}},[_c('div',{staticClass:"card-header-title-container"},[_c('p',{staticClass:"card-header-title"},[_vm._v(" Legs from waypoint "+_vm._s(collapse.startWaypoint)+" to waypoint "+_vm._s(collapse.endWaypoint)+" ")]),_c('p',[_vm._v(" Speed: "+_vm._s(collapse.speed === Infinity ? "undefined" : `${collapse.speed?.toFixed(2)} kt`)+" ")]),(!!collapse.consumption)?_c('p',[_vm._v(" Consumption: "+_vm._s(collapse.consumption?.toFixed(2))+" L/h ")]):_vm._e(),_c('p',[_vm._v("Distance: "+_vm._s(collapse.distance?.toFixed(2))+" NM")]),_c('p',[_vm._v(" Time: "+_vm._s(collapse.duration.hours === 0 && collapse.duration.minutes === 0 ? "undefined" : "")+" "+_vm._s(collapse.duration.hours !== 0 ? `${collapse.duration.hours} ${ collapse.duration.hours === 1 ? "hour" : "hours" }` : "")+" "+_vm._s(collapse.duration.minutes !== 0 ? `${collapse.duration.minutes} ${ collapse.duration.minutes === 1 ? "minute" : "minutes" }` : "")+" ")])]),(
              !collapse.type ||
              (collapse.type === 'legs' && !!collapse.waypoints)
            )?_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":props.open ? 'chevron-up' : 'chevron-down'}})],1):_vm._e()]):_c('div',{staticClass:"card-header",class:{ 'active-header': _vm.isSelected(collapse) },attrs:{"role":"button","aria-controls":'waypoint-card' + index,"aria-expanded":props.open},on:{"click":function($event){return _vm.onSelect(collapse)}}},[_c('div',{staticClass:"card-header-title-container"},[_c('p',{staticClass:"card-header-title call-location"},[_vm._v(" "+_vm._s(collapse.call_location_str || `Call in ${collapse.call_location}`)+" ")]),_c('p',[_vm._v(" Day "+_vm._s(index === 0 ? "1" : collapse.arrivalDay + 1)+_vm._s(index !== 0 && collapse.arrivalDay !== collapse.depDay ? ` > Day ${collapse.depDay + 1}` : "")+" ")])]),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":props.open ? 'chevron-up' : 'chevron-down'}})],1)])]}}],null,true)},[(
          !collapse.type || (collapse.type === 'legs' && !!collapse.waypoints)
        )?_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content",on:{"click":function($event){!collapse.type ? _vm.onSelect(collapse) : null}}},[(!collapse.type)?_c('b-field',{staticClass:"call-name"},[_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-small is-static control-label"},[_vm._v("Call")])]),_c('b-input',{attrs:{"value":collapse.call_location_str,"data-test":"call-location","size":"is-small","disabled":_vm.cruiseReadOnly,"expanded":""},nativeOn:{"change":function($event){return ((e) => _vm.updateWp(collapse, 'call_location_str', e.target.value)).apply(null, arguments)}}})],1):_vm._e(),_c('b-table',{staticClass:"waypoints-table",class:{ 'call-table': !collapse.type },attrs:{"data":collapse.type === 'legs' ? collapse.waypoints : [collapse],"selected":_vm.selectedWaypoint ? _vm.selectedWaypoint : null},on:{"select":_vm.onSelect}},[_c('b-table-column',{attrs:{"label":"WP","data-test":"wp_idx"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row?.sn)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":"Call"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"checkbox-container"},[_c('b-checkbox',{attrs:{"value":props.row?.is_call,"data-test":"is_call","disabled":_vm.cruiseReadOnly ||
                    index === 0 ||
                    !props.row?.is_national_water},on:{"input":(val) => _vm.updateWp(props.row, 'is_call', val)}}),_c('span',{staticClass:"small-text",class:{ subtle: !props.row?.is_national_water }},[_vm._v(_vm._s(!props.row?.is_national_water ? "Waypoints in international waters cannot be calls" : "This waypoint is a call"))])],1)]}}],null,true)}),_c('b-table-column',{attrs:{"label":"Latitude","data-test":"lat_idx"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"column"},[_c('b-field',{staticClass:"legs-card"},[_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-small is-static control-label"},[_vm._v("lat.")])]),_c('b-input',{attrs:{"value":props.row?.latitude?.toFixed(4),"data-test":"latitude","size":"is-small","disabled":_vm.cruiseReadOnly,"expanded":""},nativeOn:{"change":function($event){return ((e) => _vm.updateWp(props.row, 'latitude', e.target.value)).apply(null, arguments)}}})],1),(!props.row?.is_temp && !collapse.type)?_c('b-field',{staticClass:"desktop-only",attrs:{"type":_vm.fieldType(props.row, 'arr_date')}},[_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-small is-static control-label"},[_vm._v("arrival")])]),_c('p',{staticClass:"control is-expanded",attrs:{"data-test":"arr_date"}},[_c('b-datetimepicker',{ref:"arr_date",refInFor:true,attrs:{"value":props.row.arr_date,"data-test":"arr_date","placeholder":"Pick date & time…","icon":"calendar","pack":"fas","size":"is-small","disabled":_vm.cruiseReadOnly,"datepicker":{ 'first-day-of-week': 1 },"timepicker":{},"datetime-formatter":(d) => _vm.formatDateWithTZ(d, props.row.timezone),"tz-offset":_vm.getOffsetForDate(
                          props.row.arr_date,
                          props.row.timezone
                        ),"use-html5-validation":false},on:{"input":(val) => {
                          _vm.datetimeVals['arr_date'] = val
                        },"blur":function($event){return _vm.updateWp(
                          props.row,
                          'arr_date',
                          _vm.datetimeVals['arr_date']
                        )}}})],1)]):_vm._e()],1)]}}],null,true)}),_c('b-table-column',{attrs:{"label":"Longitude","data-test":"lon_idx"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"column"},[_c('b-field',{staticClass:"legs-card"},[_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-small is-static control-label"},[_vm._v("lon.")])]),_c('b-input',{attrs:{"value":props.row?.longitude?.toFixed(4),"data-test":"longitude","size":"is-small","disabled":_vm.cruiseReadOnly,"expanded":""},nativeOn:{"change":function($event){return ((e) => _vm.updateWp(props.row, 'longitude', e.target.value)).apply(null, arguments)}}})],1),(!props.row?.is_temp && !collapse.type)?_c('b-field',{staticClass:"desktop-only",attrs:{"type":_vm.fieldType(props.row, 'dep_date')}},[_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-small is-static control-label"},[_vm._v("departure")])]),_c('p',{staticClass:"control is-expanded",attrs:{"data-test":"dep_date"}},[_c('b-datetimepicker',{ref:"dep_date",refInFor:true,attrs:{"value":props.row.dep_date,"data-test":"dep_date","placeholder":"Pick date & time…","icon":"calendar","pack":"fas","size":"is-small","disabled":_vm.cruiseReadOnly,"datepicker":{ 'first-day-of-week': 1 },"timepicker":{},"datetime-formatter":(d) => _vm.formatDateWithTZ(d, props.row.timezone),"tz-offset":_vm.getOffsetForDate(
                          props.row.dep_date,
                          props.row.timezone
                        ),"use-html5-validation":false},on:{"input":(val) => {
                          _vm.datetimeVals['dep_date'] = val
                        },"blur":function($event){return _vm.updateWp(
                          props.row,
                          'dep_date',
                          _vm.datetimeVals['dep_date']
                        )}}})],1)]):_vm._e()],1)]}}],null,true)}),_c('b-table-column',{attrs:{"label":"","data-test":"geoname_idx"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row?.is_national_water && props.row?.geoname)?_c('span',{staticClass:"small-text",attrs:{"data-test":"national-waters"}},[_vm._v("National Waters of: "+_vm._s(props.row.geoname.display_name))]):_c('span',{staticClass:"small-text",attrs:{"data-test":"international-waters"}},[_vm._v("International Waters")]),(collapse.timezone && !collapse.type)?_c('p',{staticClass:"timezone-small-text desktop-only",attrs:{"data-test":"timezone"}},[_vm._v(" "+_vm._s(collapse.timezone)+" time ")]):_vm._e()]}}],null,true)}),_c('b-table-column',{attrs:{"label":"Arrival","data-test":"arrival_idx"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(!props.row?.is_temp)?_c('b-field',{staticClass:"legs-card",class:{ 'mobile-only': !collapse.type },attrs:{"type":_vm.fieldType(props.row, 'arr_date')}},[_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-small is-static control-label"},[_vm._v("arrival")])]),_c('p',{staticClass:"control is-expanded",attrs:{"data-test":"arr_date"}},[_c('b-datetimepicker',{ref:"arr_date",refInFor:true,attrs:{"value":props.row.arr_date,"data-test":"arr_date","placeholder":"Pick date & time…","icon":"calendar","pack":"fas","size":"is-small","disabled":_vm.cruiseReadOnly,"datepicker":{ 'first-day-of-week': 1 },"timepicker":{},"datetime-formatter":(d) => _vm.formatDateWithTZ(d, props.row.timezone),"tz-offset":_vm.getOffsetForDate(props.row.arr_date, props.row.timezone),"use-html5-validation":false},on:{"input":(val) => {
                        _vm.datetimeVals['arr_date'] = val
                      },"blur":function($event){return _vm.updateWp(
                        props.row,
                        'arr_date',
                        _vm.datetimeVals['arr_date']
                      )}}})],1)]):_vm._e()]}}],null,true)}),(!collapse.type)?_c('b-table-column',{attrs:{"label":"Departure","data-test":"departure_idx"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(!props.row?.is_temp)?_c('b-field',{staticClass:"legs-card",class:{ 'mobile-only': !collapse.type },attrs:{"type":_vm.fieldType(props.row, 'dep')}},[_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-small is-static control-label"},[_vm._v("departure")])]),_c('p',{staticClass:"control is-expanded",attrs:{"data-test":"dep_date"}},[_c('b-datetimepicker',{ref:"dep_date",refInFor:true,attrs:{"value":props.row.dep_date,"data-test":"dep_date","placeholder":"Pick date & time…","icon":"calendar","pack":"fas","size":"is-small","disabled":_vm.cruiseReadOnly,"datepicker":{ 'first-day-of-week': 1 },"timepicker":{},"datetime-formatter":(d) => _vm.formatDateWithTZ(d, props.row.timezone),"tz-offset":_vm.getOffsetForDate(props.row.dep_date, props.row.timezone),"use-html5-validation":false},on:{"input":(val) => {
                        _vm.datetimeVals['dep_date'] = val
                      },"blur":function($event){return _vm.updateWp(
                        props.row,
                        'dep_date',
                        _vm.datetimeVals['dep_date']
                      )}}})],1)]):_vm._e()]}}],null,true)}):_vm._e(),_c('b-table-column',{attrs:{"label":"","data-test":"timezone_idx"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"geoname",class:{ 'mobile-only': !collapse.type }},[(props.row?.timezone)?_c('span',{staticClass:"small-text",attrs:{"data-test":"timezone"}},[_vm._v(_vm._s(props.row.timezone)+" time")]):_vm._e()])]}}],null,true)}),(collapse.type === 'legs')?_c('b-table-column',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(!_vm.cruiseReadOnly)?_c('div',{staticClass:"leg-delete-button",attrs:{"data-test":"delete"},on:{"click":function($event){return _vm.onDeleteClick(props.row)}}},[_c('b-button',{attrs:{"type":"is-danger","size":"is-small"}},[_c('b-icon',{attrs:{"icon":"trash-alt","pack":"fas"}})],1)],1):_vm._e()]}}],null,true)}):_vm._e()],1),(!collapse.type)?_c('div',{staticClass:"call-footer-container"},[_c('p',[_vm._v(" Call duration: "+_vm._s(collapse.duration.days)+" days, "+_vm._s(collapse.duration.hours)+" hours, "+_vm._s(collapse.duration.minutes)+" minutes ")]),(!_vm.cruiseReadOnly)?_c('b-button',{attrs:{"type":"is-danger","size":"is-small"},on:{"click":function($event){return _vm.onDeleteClick(collapse)}}},[_c('span',{staticClass:"delete-button-content"},[_c('b-icon',{attrs:{"icon":"trash-alt","pack":"fas"}}),_vm._v(" Delete Waypoint ")],1)]):_vm._e()],1):_vm._e()],1)]):_vm._e()])}),1),(_vm.callWaypoints && _vm.callWaypoints.length)?_c('div',{staticClass:"has-text-centered pt-5"},[_vm._v(" Total distance of the cruise: "),_c('strong',[_vm._v(_vm._s(this.totalDistance !== 0 ? this.totalDistance : "n/a")+" miles")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }