<template>
  <form data-test="contact-create-form" @submit.prevent="send">
    <header class="modal-card-head">
      <p class="modal-card-title">Add Contact</p>
    </header>
    <section class="modal-card-body">
      <RequestError :error="$store.state.error.currentError" :simple="true" />
      <b-field label="First name">
        <b-input
          v-model="contactData.first_name"
          autocomplete="given-name"
          placeholder="John"
          required
        />
      </b-field>
      <b-field label="Last name">
        <b-input
          v-model="contactData.last_name"
          autocomplete="family-name"
          placeholder="Doe"
          required
        />
      </b-field>
      <div v-if="role !== 'passengers'">
        <b-field label="Email">
          <b-input
            v-model="contactData.email"
            type="email"
            autocomplete="email"
            placeholder="john.doe@example.xyz"
            required
          />
        </b-field>
        <b-field>
          <b-checkbox v-model="contactData.send_invite">
            Send email invite
          </b-checkbox>
        </b-field>
      </div>
    </section>
    <footer class="modal-card-foot">
      <section class="section">
        <button class="button is-danger" type="button" @click="$parent.close()">
          Cancel
        </button>
        <SubmitButton :is-loading="isSending" :is-disabled="isSending">
          Add contact
        </SubmitButton>
      </section>
    </footer>
  </form>
</template>

<script>

import Actions from '@/store/actions'
import { mapGetters } from 'vuex'

export default {
  name: 'ContactForm',
  components: {
  },
  props: {
    role: {
      type: String,
    }
  },
  data () {
    return {
      contactData: {},
      isSending: false
    }
  },
  computed: {
    ...mapGetters([
      'userId'
    ])
  },
  created () {
    this.initContactData()
  },
  methods: {
    initContactData () {
      this.contactData = {
        first_name: '',
        last_name: '',
        send_invite: true,
        email: ''
      }
    },
    send () {
      if (this.role === 'passengers') {
        this.contactData.send_invite = false
        this.contactData.email = 'passenger.' + this.userId + '_' + Date.now() + '@wolfgang.pro'
      }
      this.isSending = true
      var claimRoute = this.$router.resolve({
        name: 'AccountClaim',
        params: { recoveryToken: '{token}' }
      })
      var claimURL = window.location.origin + decodeURIComponent(claimRoute.href)
      // console.log(claimURL)
      this.contactData.claim_account_url = claimURL

      this.$store.dispatch(Actions.CREATE_USER_CONTACT, this.contactData)
        .then(contact => {
          this.initContactData()
          this.$store.dispatch(Actions.RESET_ERROR)
          this.$emit('contact-created', contact)
        })
        .catch(error => {
          // HTTP NOT_MODIFIED -> trying to create contact with own email
          if (error.response.status === 304) {
            this.$store.dispatch(Actions.RESET_ERROR)
            this.$store.dispatch(Actions.CREATE_USER_PROFILE, {
              first_name: this.contactData.first_name,
              last_name: this.contactData.last_name
            })
              .then(profile => {
                this.initContactData()
                this.$emit('profile-created', profile)
              })
          }
        })
        .finally(() => {
          this.isSending = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>

footer{
  .section {
    padding: 0 !important;
  }
}

</style>
