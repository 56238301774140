<template>
  <section class="section">
      <div class="container">
        <nav class="level is-mobile">
          <div class="level-left" />
          <div class="level-right">
            <div class="level-item">
              <CreateButton @click.native="createCruise">Create new agreement</CreateButton>
            </div>
          </div>
        </nav>
        <div v-if="cruises.length || isFetching" id="ContractList" class="section-box">
          <b-table :data="cruises">
            <b-table-column v-slot="props" label="#" width="20" field="id" sortable>
              <span class="agreement-id"><router-link :to="{name: 'AgreementDetail', params: {agreementId: props.row.id}}">{{ props.row.id | pad }}</router-link></span>
            </b-table-column>
            <b-table-column v-slot="props" label="Name" field="name" sortable>
              <span class="agreement-name"><router-link :to="{name: 'AgreementDetail', params: {agreementId: props.row.id}}">{{ props.row.name }}</router-link></span>
            </b-table-column>
            <b-table-column v-slot="props" label="Yacht" field="yacht_name" sortable>
              <span v-if="props.row.yacht">
                {{ props.row.yacht_name }}
              </span>
              <span v-else class="has-text-grey">n/a</span>
            </b-table-column>
            <b-table-column v-slot="props" field="delivery_date" label="Departure date" sortable>
              <span v-if="props.row.delivery_date">
                {{ $dayjs(props.row.delivery_date).format('YYYY/MM/DD')  }}
              </span>
            </b-table-column>

            <b-table-column v-slot="props" field="redelivery_date" label="Arrival date" sortable>
              <span v-if="props.row.redelivery_date">
                {{ $dayjs(props.row.redelivery_date).format('YYYY/MM/DD') }}
              </span>
            </b-table-column>
            <b-table-column v-slot="props" class="status is-narrow" field="status" label="Status" sortable>
              <template v-if="props.row.status === 'DRAFT'">
                <span class="draft">◉</span>
                <span class="status__text">
                  <span v-if="props.row.version > 0">Addendum</span>
                  Draft
                </span>
              </template>
              <template v-else-if="props.row.status === 'LOCKED'">
                <b-icon icon="lock" class="approved"></b-icon>
                <span class="status__text">Approved</span>
              </template>
              <template v-else-if="props.row.status === 'CONTRACT'">
                <span class="contract">◉</span>
                <span class="status__text">Contract</span>
              </template>
              <template v-else-if="props.row.status === 'ADDENDUM'">
                <span class="contract">◉</span>
                <span class="status__text">Addendum</span>
              </template>
              <template v-else-if="props.row.status === 'SETTLING'">
                <span class="settling">◉</span>
                <span class="status__text">Settling</span>
              </template>
              <template v-else-if="props.row.status === 'SETTLED'">
                <span class="settled">◉</span>
                <span class="status__text">Settled</span>
              </template>
              <template v-else-if="props.row.status === 'ARCHIVED'">
                <span class="archived">◉</span>
                <span class="status__text">Archived</span>
              </template>
              <template v-else>
                <span class="status__text">{{ props.row.status }}</span>
              </template>
            </b-table-column>
            <b-table-column v-slot="props"  label="" :sortable="false">
              <router-link :to="{name: 'AgreementDetail', params: {agreementId: props.row.id}}">
                <span class="edit-button">
                  <b-icon v-if="props.row.write_access && props.row.status != 'SETTLED'  && props.row.status != 'ARCHIVED'" icon="pencil-alt" size="is-small"/>
                  <b-icon v-else icon="eye" size="is-small"/>
                </span>
              </router-link>
            </b-table-column>
            <!-- <template slot="detail" slot-scope="props">
              <div class="level">
                <div class="media-content">
                  <div class="content">
                    <div v-if="props.row.yacht">
                      <p class="image is-64x64">
                        <img src="http://via.placeholder.com/128x128"/>
                      </p>
                      <span>{{ props.row.yacht.name }}</span>
                    </div>
                    <div v-else>
                      <p>No yacht assigned to this contract yet.</p>
                    </div>
                  </div>
                </div>
              </div>
          </template> -->
          <template #empty>
              <table-placeholder :is-fetching="isFetching" :aria-busy="isFetching"></table-placeholder>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import TablePlaceholder from '@/components/UI/TablePlaceholder'
import Actions from '@/store/actions'

export default {
  name: 'AgreementList',
  components: {
    TablePlaceholder,
  },
  filters: {
    pad (value) {
      var s = String(value)
      while (s.length < 5) { s = '0' + s }
      return s
    }
  },
  data () {
    return {
      isFetching: false,
    }
  },
  computed: {
    cruises () {
      return this.$store.state.cruise.cruises.map(c => {
        const yachtName = c.yacht ? c.yacht.name : '-'
        return Object.assign({ yacht_name: yachtName }, c)
      })
    }
  },
  created () {
    this.isFetching = true
    this.$store.dispatch(Actions.GET_USER_CRUISES)
      .finally(() => {
        this.isFetching = false
      })
  },
  methods: {
    createCruise () {
      if (!this.$store.state.user.user.is_admin) {
        this.$buefy.dialog.alert('Please contact us to create a new agreement: <a href="mailto:info@wolfgang.pro">info@wolfgang.pro </a>')
      } else {
        this.$store.dispatch(Actions.CREATE_USER_CRUISE, {})
          .then((newCruise) => {
            this.$router.push({ name: 'AgreementDetail', params: { agreementId: newCruise.id } })
          })
      }
    },
    yachtName (yacht) {
      if (yacht) {
        return yacht.name
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
#ContractList {

  table {
    border-radius: 0;
  }

  .agreement-id {
    font-family: monospace;
    font-weight: bold;
  }

  .agreement-name {
    font-weight: bold;
  }

  .edit-button {
    display: inline-flex;
    background-color: #DBEBF5;
    color: $primary;
    padding: 6px;
    border-radius: 4px;
    line-height: 1;
    transition: background-color .2s ease;

    &:hover {
      background-color: #c0def3;
    }
  }

  .delete-button {
    display: inline-flex;
    background-color: #ffdfdf;
    color: red;
    padding: 6px;
    border-radius: 4px;
    line-height: 1;
  }

  td {
    // display: flex;
    // font-size: .9rem;

    .status__text {
      margin-left: .3rem;
      color: $grey;
    }

    .draft, .settling {
      color: #D50000;
    }

    .contract, .settled {
      color: #00C853;
    }

    .approved {
      color: #FFD600
    }
  }

}
</style>
