import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import store from '../store'

import Actions from '@/store/actions'

// Agreements
import AgreementList from '@/views/AgreementList'
// Agreement Detail
import QuickViewSection from '@/views/AgreementDetail/sections/QuickView/QuickViewSection'
import ContractSection from '@/views/AgreementDetail/sections/ContractSection'
import YachtSection from '@/views/AgreementDetail/sections/YachtSection'
import MappingSection from '@/views/AgreementDetail/sections/MappingSection'
import ClientsSection from '@/views/AgreementDetail/sections/ClientsSection'
import BrokersSection from '@/views/AgreementDetail/sections/BrokersSection'
import ServicesSection from '@/views/AgreementDetail/sections/ServicesSection'
import PriceSection from '@/views/AgreementDetail/sections/PriceSection'
import DocumentSection from '@/views/AgreementDetail/sections/DocumentSection'

import ContractSigning from '@/components/forms/signing/ContractSigning'

// Auth
import Authentication from '@/views/Authentication'
import ClaimForm from '@/components/forms/auth/ClaimForm'
import RegistrationForm from '@/components/forms/auth/RegistrationForm'
import ForgotPassword from '@/components/forms/auth/ForgotPassword'
import LoginForm from '@/components/forms/auth/LoginForm'
import RecoveryForm from '@/components/forms/auth/RecoveryForm'
// Yacht
import YachtList from '@/views/YachtList'
import YachtCreate from '@/views/YachtDetail/YachtCreate'
import YachtDetail from '@/views/YachtDetail/YachtDetail'
// contacts
import ContactsList from '@/views/ContactsList'
import ContactDetail from '@/views/ContactDetail/ContactDetail'
// Profile
import ProfileList from '@/views/ProfileList'
// import ProfileDetail from '@/views/ProfileDetail'

import Help from '@/views/Help'
import FAQ from '@/views/FAQ'
import Admin from '@/views/Admin'

const AgreementDetail = () => import('@/views/AgreementDetail/AgreementDetail')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      meta: {
        guest: true
      },
      component: Home
    },
    {
      path: '/authentication',
      component: Authentication,
      props: true,
      redirect: {
        name: 'Home'
      },
      meta: {
        guest: true
      },
      children: [
        {
          path: 'registration',
          name: 'Registration',
          component: RegistrationForm
        },
        {
          path: 'recovery/:recoveryToken',
          name: 'Recovery',
          props: true,
          component: RecoveryForm
        },
        {
          path: 'forgot-password',
          name: 'ForgotPassword',
          component: ForgotPassword
        }
      ]
    },
    {
      path: '/claim_account/:recoveryToken',
      name: 'AccountClaim',
      meta: {
        guest: true
      },
      redirect: {
        name: 'AccountClaimNew'
      },
      props: true,
      component: Authentication,
      children: [
        {
          path: 'new',
          name: 'AccountClaimNew',
          component: ClaimForm
        },
        {
          path: 'merge',
          name: 'AccountClaimExisting',
          component: LoginForm
        }
      ]
    },
    {
      path: '/agreement',
      name: 'AgreementList',
      component: AgreementList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/agreement/:agreementId',
      component: AgreementDetail,
      meta: {
        requiresAuth: true
      },
      props: true,
      children: [
        {
          path: '',
          name: 'AgreementDetail',
          component: QuickViewSection,
          props: true
        },
        {
          path: 'contract/:cruiseVersion?',
          name: 'ContractSection',
          component: ContractSection,
          props: (route) => {
            const cruiseVersion = Number.parseInt(
              route.params.cruiseVersion,
              10
            )
            if (Number.isNaN(cruiseVersion)) {
              return null
            }
            return { cruiseVersion }
          },
          children: [
            {
              path: 'sign',
              name: 'ContractSigning',
              component: ContractSigning,
              props: (route) => ({
                code: route.query.code,
                state: route.query.state
              })
            }
          ]
        },
        {
          path: 'yacht',
          name: 'YachtSection',
          component: YachtSection,
          props: true
        },
        {
          path: 'mapping',
          name: 'MappingSection',
          component: MappingSection,
          props: true
        },
        {
          path: 'clients',
          name: 'ClientsSection',
          component: ClientsSection,
          props: true
        },
        {
          path: 'brokers',
          name: 'BrokersSection',
          component: BrokersSection,
          props: true
        },
        {
          path: 'services',
          name: 'ServicesSection',
          component: ServicesSection,
          props: true
        },
        {
          path: 'price',
          name: 'PriceSection',
          component: PriceSection,
          props: true
        },
        {
          path: 'documents',
          name: 'DocumentSection',
          component: DocumentSection,
          props: true
        }
      ]
    },
    {
      path: '/profile',
      name: 'ProfileList',
      meta: {
        requiresAuth: true
      },
      component: ProfileList,
      props: { suggestMerging: false }
    },
    {
      path: '/help',
      name: 'Help',
      meta: {
        requiresAuth: true
      },
      component: Help
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: FAQ
    },
    {
      path: '/admin',
      name: 'Admin',
      meta: {
        requiresAuth: true
      },
      component: Admin
    },
    {
      path: '/profile_with_merge',
      name: 'ProfileListMerge',
      meta: {
        requiresAuth: true
      },
      component: ProfileList,
      props: { suggestMerging: true }
    },
    {
      path: '/profile/:profileId',
      name: 'ProfileDetail',
      meta: {
        requiresAuth: true
      },
      component: ProfileList,
      props: (route) => {
        const profileId = Number.parseInt(route.params.profileId, 10)
        if (Number.isNaN(profileId)) {
          route.params.profileId = undefined
        } else {
          route.params.profileId = profileId
        }
        return route.params
      }
    },
    {
      path: '/yachts',
      name: 'YachtList',
      meta: {
        requiresAuth: true
      },
      component: YachtList
    },
    {
      path: '/yachts/create',
      name: 'YachtCreate',
      meta: {
        requiresAuth: true
      },
      component: YachtCreate
    },
    {
      path: '/yachts/:id',
      name: 'YachtDetail',
      meta: {
        requiresAuth: true
      },
      component: YachtDetail,
      props: (route) => {
        const id = Number.parseInt(route.params.id, 10)
        if (Number.isNaN(id)) {
          return -1
        }
        return { id }
      }
    },
    {
      path: '/contacts',
      name: 'ContactsList',
      meta: {
        requiresAuth: true
      },
      component: ContactsList
    },
    {
      path: '/contacts/:id',
      name: 'ContactDetail',
      meta: {
        requiresAuth: true
      },
      component: ContactDetail,
      props: (route) => {
        const id = Number.parseInt(route.params.id, 10)
        if (Number.isNaN(id)) {
          return -1
        }
        return { id }
      }
    },
    {
      // TODO: Redirect to 404 error instead:
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.name === 'AccountClaimNew') {
    store.dispatch(Actions.LOGOUT).then(() => {
      next()
    })
  }
  store
    .dispatch(Actions.AUTHENTICATE)
    .then(() => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
          if (to.name === 'Admin' && !store.getters.user.is_admin) {
            console.log(
              `Unauthorised Admin page access: ${store.getters.user.id}`
            )
            next({ name: 'Home' })
          } else {
            next()
          }
        } else {
          console.log(`redirecting to login (tried to access: ${to.fullPath})`)
          next({
            name: 'Login',
            params: { nextUrl: to.fullPath }
          })
        }
      } else if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isLoggedIn) {
          next({
            name: 'AgreementList'
          })
        } else {
          next()
        }
      } else {
        next()
      }
    })
    .catch(() => {
      console.log('Authenticate error')
      next({
        name: 'Login',
        params: { nextUrl: to.fullPath }
      })
    })
})

export default router
