<template>
  <div>
    <form autocomplete="on" @submit.prevent="claim">
      <legend>Claim your account</legend>
      <b-field><p>Choose a login email and password for your new account:</p></b-field>
      <b-field label="Email">
        <b-input
          v-model="registrationData.new_email"
          type="email"
          autocomplete="email"
          placeholder="john.doe@example.xyz"
          required
        />
      </b-field>
      <b-field label="Password">
        <b-input
          v-model="registrationData.new_password"
          type="password"
          autocomplete="new-password"
          placeholder="Password"
          required
        />
      </b-field>
      <b-field class="pt-2">
        <b-checkbox required>
          I agree with Wolfgang's Terms of Service including the GDPR provisions.
        </b-checkbox>
      </b-field>
      <b-field class="pb-2">
        <a href="https://wolfgang.pro/tos">Read the Terms of Service</a>
      </b-field>
      <b-field>
        <SubmitButton
          :is-loading="isSending"
          :is-disabled="isSending"
        >
          Create account
        </SubmitButton>
      </b-field>
    </form>
  </div>
</template>

<script>

import Actions from '@/store/actions'

export default {
  name: 'ClaimForm',
  props: {
    recoveryToken: {
      required: true
    }
  },
  data () {
    return {
      registrationData: {
        new_email: '',
        new_password: ''
      },
      isSending: false
    }
  },
  methods: {
    claim () {
      this.isSending = true
      this.$store.dispatch(Actions.RESET_PASSWORD, {
        ...this.registrationData,
        recovery_token: this.recoveryToken
      })
        .then(() => this.$store.dispatch(Actions.LOGIN, {
          email: this.registrationData.new_email,
          password: this.registrationData.new_password
        }))
        .then(() => this.$emit('login-success'))
        .finally(() => {
          this.isSending = false
        })
    }
  }
}
</script>
