<template>
  <div>
    <role-form
      :agreement-id="agreementId"
      role="brokers"
      role-friendly-name-singular="broker"
      role-friendly-name-plural="brokers"
      multiple
    />
    <hr/>
    <role-form
      :agreement-id="agreementId"
      role="stakeholders"
      role-friendly-name-singular="stakeholder"
      role-friendly-name-plural="stakeholders"
      multiple
    />
  </div>
</template>

<script>
import RoleForm from '@/components/forms/cruise/RoleForm'

export default {
  name: 'BrokersSection',
  components: {
    RoleForm
  },
  props: ['agreementId']
}
</script>
