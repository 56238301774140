<template>
  <div>
    <div
      v-if="generatedVersions.length > 0"
      class="field has-addons"
    >
      <div
        v-if="generatedVersions.length > 1"
        class="control"
      >
        <div class="select is-primary">
          <select
            :disabled="loadingDraft"
            @change="selectVersion($event.target.value)"
          >
            <option
              v-for="v in generatedVersions"
              :key="v.version"
              :value="v.version"
              :selected="currentCruise.version == v.version"
              :disabled="!v.read_access"
            >
              {{ v.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          :disabled="loadingDraft"
          @click="agreeToTerms(currentCruise.version)"
        >
          Download PDF
        </b-button>
      </div>
      <div class="control">
        <b-button
          type="is-text"
          :disabled="loadingDraft"
          icon-left="angle-double-down"
          @click="scrollTo('standardTerms')"
        >
          Standard terms
        </b-button>
      </div>
      <div class="control">
        <b-button
          type="is-text"
          :disabled="loadingDraft"
          icon-left="angle-double-down"
          @click="scrollTo('taxRegime')"
        >
          Tax regime
        </b-button>
      </div>
    </div>
    <Loading v-else-if="loadingVersions" />
    <router-view
      :cruise-id="currentCruise.id"
      :cruise-version="cruiseVersion"
      :status="userSignature ? userSignature.status : ''"
    />
    <template v-if="isGenerated">
      <div class="columns">
        <div
          v-if="
            canSign &&
              !isSigning &&
              signatures &&
              (!userSignature || userSignature.status === 'FAILED')
          "
          class="column is-narrow"
        >
          <div class="control">
            <b-button
              type="is-primary"
              tag="a"
              icon-left="file-signature"
              @click="isComponentModalActive = true"
            >
              Sign
            </b-button>
            <form
              ref="form"
              autocomplete="on"
              @submit.prevent="startSigning"
            >
              <b-modal
                :active.sync="isComponentModalActive"
                has-modal-card
              >
                <div
                  class="modal-card"
                  style="width: auto"
                >
                  <header class="modal-card-head">
                    <p class="modal-card-title">
                      Wolfgang policy
                    </p>
                  </header>
                  <section class="modal-card-body">
                    <b-field>
                      <p>
                        By clicking on <i>Sign Contract</i>, you acknowledge
                        that you have read and accept Wolfgang's
                        <a
                          :href="`${serverURL}/tos`"
                          target="_new"
                        >Terms and Conditions</a>.
                      </p>
                    </b-field>
                    <b-checkbox required>
                      I agree with the Special & Standard terms of this
                      contract including the GDPR provisions.
                    </b-checkbox>
                  </section>
                  <footer class="modal-card-foot">
                    <button
                      class="button"
                      type="button"
                      @click="isComponentModalActive = false"
                    >
                      Cancel
                    </button>
                    <button class="button is-primary">
                      Sign contract
                    </button>
                  </footer>
                </div>
              </b-modal>
            </form>
          </div>
        </div>
        <div
          v-for="s in signatures"
          :key="s.id"
          class="column is-narrow"
        >
          <contract-signature
            :signature="s"
            :can-sign="canSign"
            :is-signing="isSigning"
            @update-signatures="updateSignatures"
          />
        </div>
        <div class="column is-expanded" />
      </div>
    </template>
    <div
      class="watermarked"
      :data-watermark="watermark"
    >
      <div
        v-if="draft && !loadingDraft"
        v-html="draft"
      />
      <Loading v-else />
    </div>
  </div>
</template>

<script>
import {
  getContractDraft,
  getContractPdf,
  getContractSignatures,
  checkBillingCanSign,
} from '@/api/contract'

import { apiURL, serverURL } from '@/api/config'
import { nestedVal } from '@/helpers/utils'
import ContractSignature from '@/components/forms/signing/ContractSignature'
import Actions from '@/store/actions'

const scrollToElement = require('scroll-to-element')

export default {
  name: 'ContractSection',
  components: {
    ContractSignature,
  },
  props: {
    cruiseVersion: {
      type: Number,
      default: undefined,
    },
  },
  data () {
    return {
      draft: false,
      loadingDraft: true,
      loadingVersions: false,
      signatures: false,
      generatedVersions: [],
      isComponentModalActive: false,
      serverURL,
    }
  },
  computed: {
    cruise () {
      return this.$store.state.cruise.cruise
    },
    currentCruise () {
      if (
        this.generatedVersions.length > 0 &&
        this.cruiseVersion !== undefined &&
        this.cruiseVersion !== this.cruise.version
      ) {
        const cur = this.generatedVersions.find(
          (c) => c.version === this.cruiseVersion
        )
        if (cur === undefined) {
          console.log('Unknown/unavailable contract version')
          return this.cruise
        }
        return {
          id: this.cruise.id,
          ...cur,
        }
      } else {
        return this.cruise
      }
    },
    isGenerated () {
      return (
        this.currentCruise.status !== 'DRAFT' &&
        this.currentCruise.status !== 'LOCKED'
      )
    },
    watermark () {
      if (this.isGenerated) {
        return ''
      } else {
        return 'Draft   '.repeat(100)
      }
    },
    isSigning () {
      return this.$route.name === 'ContractSigning'
    },
    canSign () {
      return window.digidentityEnabled && this.currentCruise.can_approve
    },
    userProfileIds () {
      return this.$store.getters.userProfileIds
    },
    userSignature () {
      if (!this.signatures) return false
      return this.signatures.find((s) =>
        s.roles.some((r) => this.userProfileIds.includes(r.profile.id))
      )
    },
  },
  created () {
    this.getGeneratedVersions().then(() => {
      this.loadDraft(this.currentCruise.version)
    })
  },
  methods: {
    scrollTo (id) {
      if (id === 'standardTerms') {
        scrollToElement('#standardTerms')
      } else {
        scrollToElement('#taxRegime')
      }
    },
    selectVersion (version) {
      this.$router.replace(
        { name: 'ContractSection', params: { cruiseVersion: version } },
        this.loadDraft(version)
      )
    },
    loadDraft (version) {
      this.loadingDraft = true
      this.signatures = false
      getContractDraft(this.currentCruise.id, version)
        .then((response) => {
          this.draft = response.data
          this.loadingDraft = false
          this.updateSignatures()
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.draft =
              '<em>You are not authorised to view this version of the contract.</em>'
          } else {
            return Promise.reject(error)
          }
        })
    },
    getGeneratedVersions () {
      if (this.cruise.generated_versions_len === 0) {
        this.generatedVersions = []
        return Promise.resolve()
      }
      this.loadingVersions = true
      return this.$store
        .dispatch(Actions.GET_CRUISE_GENERATED_VERSIONS)
        .then((versions) => {
          let counter = 0
          // var versions = [...generated_versions] // Need to use spread to do sort on a copy of the array
          versions = versions
            .sort((g1, g2) => g1.version - g2.version)
            .map((v) => ({
              ...v,
              name:
                v.status === 'CONTRACT'
                  ? 'Contract'
                  : 'Addendum #' + (counter += 1),
            }))
            .reverse()

          if (['DRAFT', 'LOCKED'].includes(this.cruise.status)) {
            versions.unshift({
              status: this.cruise.status,
              version: this.cruise.version,
              name: 'Current draft',
              read_access: this.cruise.read_access,
              write_access: this.cruise.write_access,
            })
          }
          this.generatedVersions = versions
          this.loadingVersions = false
        })
    },
    agreeToTerms (version) {
      this.$buefy.dialog.confirm({
        message:
          'I agree with the Special & Standard terms of this contract including the GDPR provisions',
        cancelText: 'Disagree',
        confirmText: 'Agree',
        type: 'is-primary',
        hasIcon: false,
        onConfirm: () => {
          this.downloadPdf(version)
        },
      })
    },
    downloadPdf (version) {
      getContractPdf(this.currentCruise.id, version).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Wolfgang_Contract_${this.currentCruise.id
          .toString()
          .padStart(4, '0')}.pdf`
        link.click()
      })
    },
    startSigning () {
      checkBillingCanSign({
        cruiseId: this.currentCruise.id,
        cruiseVersion: this.currentCruise.version,
      }).then(() => {
        const signingRoute = this.$router.resolve({
          name: 'ContractSigning',
          params: { cruiseVersion: this.currentCruise.version },
        })
        // console.log(`redirect to:`, window.location.origin + signingRoute.href, apiURL)
        window.location.href =
          apiURL +
          '/sign/login?next_url=' +
          encodeURIComponent(window.location.origin + signingRoute.href)
      })
    },
    updateSignatures () {
      // Todo: debounce
      getContractSignatures({
        cruiseId: this.currentCruise.id,
        cruiseVersion: this.currentCruise.version,
      })
        .then(({ data }) => {
          this.signatures = data
          if (this.isSigning && !this.userSignature) {
            console.log('TIMER signing && !this.userSignature')
            setTimeout(this.updateSignatures.bind(this), 2000)
          }
        })
        .catch((error) => {
          if (
            error.response.status === 401 &&
            nestedVal(error, 'response.data.errors.digidentity') ===
              'token-expired'
          ) {
            console.log('TOKEN EXPIRED')
          } else {
            Promise.reject(error)
          }
        })
    },
  },
  // watch: {
  //   cruiseVersion: function (newVal, oldVal) {
  //   },
  // }
}
</script>
<style lang="scss">
.watermarked {
  position: relative;
  overflow: hidden;
}

.watermarked::before {
  position: absolute;
  // top: -20%;
  left: -150%;
  display: block;
  width: 500%;
  // height: 150%;

  transform: rotate(-45deg);
  content: attr(data-watermark);

  opacity: 0.05;
  line-height: 4em;
  letter-spacing: 5px;
  word-spacing: 80px;
  font-size: 120px;
  font-style: italic;
  color: #f00;
}

@media print {
  .contract-template {
    font-family: "Comic Sans MS" !important;
    font-size: 30px !important;
  }
  .contract-template ul {
    display: none;
  }
}

// TODO: make that path cleaner:
@import "@../../../templates/contract/styles.css";
</style>
