import Actions from '@/store/actions'
import { nestedVal } from '@/helpers/utils'

const MU_ADD_ERROR = 'μ: Add error'
const MU_RESET_ERROR = 'μ: Reset error'
const MU_STICK_ERROR = 'μ: Stick error'

const errorsModule = {
  state: {
    errors: [],
    currentError: false,
  },
  mutations: {
    [MU_ADD_ERROR] (state, error) {
      state.errors.push(error)
      state.currentError = error
    },
    // MU_LAST_ERROR (state, error) {
    //   if (state.errors.length > 0) { state.currentError = state.errors[state.errors.length - 1] }
    //   console.log(state.currentError)
    // },
    [MU_RESET_ERROR] (state) {
      state.currentError = false
    },
    [MU_STICK_ERROR] (state) {
      state.currentError.sticky = true
    }
  },
  actions: {
    [Actions.ERROR] ({ commit, state }, error) {
      // If endpoint was requested as a blob, we need to decode potential error:
      if (nestedVal(error, ['response', 'data']) && error.response.data instanceof Blob) {
        const fr = new FileReader()
        fr.onload = function () {
          error.response.data = JSON.parse(this.result)
          commit(MU_ADD_ERROR, error)
        }
        fr.readAsText(error.response.data)
      } else {
        commit(MU_ADD_ERROR, error)
      }
      // TODO: Maybe automatically send debug info to REST server
      // console.log(state.currentError)
    },
    [Actions.RESET_ERROR] ({ commit, state }, manual = false) {
      if (state.currentError && (manual || !state.currentError.sticky)) {
        commit(MU_RESET_ERROR)
      }
      // TODO: Maybe automatically send debug info to REST server
      // console.log(state.currentError)
    },
    [Actions.STICK_ERROR] ({ commit, state }) {
      if (state.currentError) {
        commit(MU_STICK_ERROR)
      }
    }
  },
  getters: {
    validationErrors: (state) => {
      const error = state.currentError
      if (nestedVal(error, ['response', 'data', 'messages', 'validation'])) {
        return error.response.data.messages.validation
      } else if (nestedVal(error, ['response', 'data', 'errors']) && error.response.status === 422) {
        return ('json' in error.response.data.errors ? error.response.data.errors.json : error.response.data.errors)
      } else {
        return null
      }
    },
  }
}

export default errorsModule
