<template>
  <div class="pictogram" :style="styles">
    <slot></slot>
  </div>
</template>

<script>
import toMaterialStyle from 'material-color-hash'

export default {
  name: 'Pictogram',
  props: {
    backgroundColor: {
      type: String,
      default: '#F4F6FA'
    },
    color: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: '3em'
    },
    name: {
      type: String
    }
  },
  computed: {
    styles () {
      var styles = {
        backgroundColor: this.backgroundColor,
        width: this.size,
        height: this.size
      }
      if (this.name) {
        styles.color = toMaterialStyle(this.name, '700').backgroundColor
      } else if (this.color) {
        styles.color = this.color
      }
      return styles
    }
  }
}
</script>

<style lang="scss">
  .pictogram {
    color: $primary-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:50%;
    font-weight: 600;
    font-size: 1.25rem;
    @include mobile {
      font-size: 1rem;
    }
  }
</style>
