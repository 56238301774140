var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"services"},[_c('div',{attrs:{"sticky-container":""}},[_c('div',{directives:[{name:"sticky",rawName:"v-sticky"}],staticClass:"sticky",attrs:{"sticky-offset":"offset"}},[_vm._m(0)]),_c('div'),_c('div'),_c('div',[_c('div',{staticClass:"scrollspy-example",staticStyle:{"margin-top":"-555px"},attrs:{"data-bs-spy":"scroll","data-bs-target":"#list-example","data-bs-offset":"100","tabindex":"0"}},[_vm._m(1),_c('div',{staticClass:"slides boat1_slides",attrs:{"id":"list-item-2"}},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-md-12 col-lg-6 p-0",attrs:{"data-aos":"zoom-in","data-aos-easing":"linear","data-aos-delay":"500","data-aos-duration":"1000"}},[_c('div',{staticClass:"boat1_video_slide"},[_c('video',{staticClass:"boat1_video",staticStyle:{"pointer-events":"none"},attrs:{"playsinline":"","player.controls":"false","muted":"","loop":"","preload":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../assets/img/image/boat1.mp4"),"type":"video/mp4"}})])]),_vm._m(2)]),_vm._m(3)])]),_vm._m(4),_c('div',{staticClass:"slides",attrs:{"id":"list-item-4"}},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-md-12 col-lg-6 p-0",attrs:{"data-aos":"zoom-in","data-aos-easing":"linear","data-aos-delay":"500","data-aos-duration":"1000"}},[_c('div',{staticClass:"boat1",style:({backgroundImage: 'url('+require('../assets/img/image/slidelast.png')+')'})}),_vm._m(5)]),_vm._m(6)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-group phone-wrap",attrs:{"id":"list-example"}},[_c('a',{staticClass:"list-group-item list-group-item-action",attrs:{"href":"#list-item-1"}},[_c('img',{attrs:{"src":require("@/assets/img/image/SCREEN1.png")}})]),_c('a',{staticClass:"list-group-item list-group-item-action",attrs:{"href":"#list-item-2"}},[_c('img',{attrs:{"src":require("@/assets/img/image/SCREEN2.png")}})]),_c('a',{staticClass:"list-group-item list-group-item-action",attrs:{"href":"#list-item-3"}},[_c('img',{attrs:{"src":require("@/assets/img/image/SCREEN3.png")}})]),_c('a',{staticClass:"list-group-item list-group-item-action last-sec",attrs:{"href":"#list-item-4"}},[_c('img',{staticStyle:{"padding-left":"25px"},attrs:{"src":require("@/assets/img/image/SCREEN5.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slides",attrs:{"id":"list-item-1"}},[_c('div',{staticClass:"mobile-view-phone"},[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/img/image/PHONE1.png")}})]),_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-md-12 col-lg-4 sv-txt-padding-left sv-txt"},[_c('h4',{staticClass:"fnt-lib-r"},[_vm._v("Register and boost your business")]),_c('ul',{staticClass:"fnt-p",attrs:{"data-aos":"fade-up","data-aos-easing":"ease-out-cubic","data-aos-duration":"1000","data-aos-delay":"1000"}},[_c('li',[_vm._v("Register now for free to benefit from fast-track services when season comes")]),_c('li',[_vm._v("Cruise more! 9-day cruises for the cost of a 7-day charter")]),_c('li',[_vm._v("Attract retail clients with competitive pricing without affecting your net margin")])])]),_c('div',{staticClass:"col-md-12 col-lg-6 illu-wrap"},[_c('img',{staticClass:"svg-img",attrs:{"loading":"lazy","src":require("@/assets/img/image/ILLU1.svg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-view-phone"},[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/img/image/PHONE2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 col-lg-4 sv-txt sv-txt-padding-left sv-boat-text",staticStyle:{"margin-left":"auto"}},[_c('h4',{staticClass:"fnt-lib-r"},[_vm._v("Planning & contracting: simple, fast and safe")]),_c('ul',{staticClass:"fnt-p",attrs:{"data-aos":"fade-up","data-aos-easing":"ease-out-cubic","data-aos-duration":"1000","data-aos-delay":"500"}},[_c('li',[_vm._v("Simple: we handle the drafting, updating and all calculations live for you")]),_c('li',[_vm._v("Fast: answer your clients inquiries and change of plans in record time")]),_c('li',[_vm._v("Safe: all calculations and optimizations are backed by our proprietary platform")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slides",attrs:{"id":"list-item-3"}},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-md-12 col-lg-4 sv-txt-padding-left sv-txt"},[_c('h4',{staticClass:"fnt-lib-r"},[_vm._v("The expert in cruise and transport contract")]),_c('ul',{staticClass:"fnt-p",attrs:{"data-aos":"fade-up","data-aos-easing":"ease-out-cubic","data-aos-duration":"1000","data-aos-delay":"1000"}},[_c('li',[_vm._v("The most efficient legal and fiscal framework available")]),_c('li',[_vm._v("Our contract templates are vetted by the French fiscal authorities")]),_c('li',[_vm._v("We help safeguard you against liabilities and risk of re-qualification")])])]),_c('div',{staticClass:"col-md-12 col-lg-6 illu-wrap"},[_c('div',{staticClass:"mobile-view-phone mt-2"},[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/img/image/PHONE3.png")}})]),_c('img',{staticClass:"svg-img",attrs:{"loading":"lazy","src":require("@/assets/img/image/ILLU2.svg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-view-phone"},[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/img/image/PHONE4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 col-lg-4 sv-txt-padding-right sv-txt sv-boat-text",staticStyle:{"margin-left":"auto"}},[_c('h4',{staticClass:"fnt-lib-r"},[_vm._v("All-inclusive service for your peace of mind")]),_c('p',{attrs:{"data-aos":"fade-up","data-aos-easing":"ease-out-cubic","data-aos-duration":"1000","data-aos-delay":"500"}},[_c('ul',{staticClass:"fnt-p"},[_c('li',[_vm._v("We collaborate with your team and minimize your workload")]),_c('li',[_vm._v("All documents included: contracts, addenda and reports, digitally signed and traced")]),_c('li',[_vm._v("24/7 compliance supervision and hotline during the cruise")])]),_c('span',{staticStyle:{"color":"#a8b1bb","font-size":"1.2em"}},[_vm._v("You can focus on your clients. We handle the contracts!")])])])
}]

export { render, staticRenderFns }