<template>
  <div>
    <div v-if="cruiseReadOnly && !waypoints.length" class="message is-dark">
      <div v-if="loading" class="message-body">
        Loading waypoints, please wait…
      </div>
      <div v-else class="message-body">
        No waypoints have been mapped for this cruise.
      </div>
    </div>
    <div v-else class="columns waypoints">
      <div class="column is-two-thirds waypoints-map">
        <waypoints-map :is-map-active="!cruiseReadOnly"></waypoints-map>
      </div>
      <div class="column is-one-third">
        <waypoint-form :cruise-read-only="cruiseReadOnly" :selected-w-p="selectedWP"></waypoint-form>
      </div>
    </div>
    <waypoints-list :cruise-read-only="cruiseReadOnly"></waypoints-list>
  </div>
</template>

<script>
import WaypointForm from './waypoints/WaypointForm'
import WaypointsList from './waypoints/WaypointsList'
import WaypointsMap from './waypoints/WaypointsMap'
import Actions from '@/store/actions'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MappingSection',

  components: {
    WaypointsList,
    WaypointForm,
    WaypointsMap,
  },
  computed: {
    ...mapGetters(['cruiseReadOnly']),
    ...mapState({
      selectedWP: state => state.waypoint.selectedWaypoint,
      waypoints: state => state.waypoint.waypoints,
      loading: state => state.waypoint.loading
    }),
  },
  created () {
    this.$store.dispatch(Actions.GET_CRUISE_WAYPOINTS)
    this.$store.dispatch(Actions.GET_USER_YACHTS)
  },
}
</script>
<style lang="scss">
  .columns.waypoints {
    padding: 0;
    .waypoints-map {
      padding-right: 0.5rem !important;
    }
  }
 </style>
