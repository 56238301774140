import axios from '@/api/config'
import Actions from '@/store/actions'

const MU_SET_YACHTS = 'μ: Set yachts'
const MU_ADD_YACHT = 'μ: Add yacht'
const MU_SET_YACHT = 'μ: Update yacht'
const MU_SET_YACHT_PICTURES = 'μ: Update yacht pictures'
const MU_DELETE_YACHT = 'μ: Delete yacht'
const MU_SET_LOADING = 'μ: Set loading'
const MU_SET_UPLOADING = 'μ: Set uploading'

const yachtModule = {
  state: {
    yachts: [],
    yachtsOldVersions: [],
    loading: false,
    uploading: false,
  },
  mutations: {
    [MU_SET_YACHTS] (state, yachts) {
      state.yachts = yachts
    },
    [MU_ADD_YACHT] (state, yacht) {
      state.yachts = [...state.yachts, yacht]
    },
    [MU_SET_YACHT] (state, yacht) {
      if (yacht.is_current_version) {
        const yachtIdx = state.yachts.findIndex(y => y.id === yacht.id)
        if (yachtIdx !== -1) {
          state.yachts.splice(yachtIdx, 1, yacht)
        } else {
          state.yachts.push(yacht)
        }
      } else {
        const yachtIdx = state.yachtsOldVersions.findIndex(y => y.id === yacht.id && y.version === yacht.version)
        if (yachtIdx !== -1) {
          state.yachtsOldVersions.splice(yachtIdx, 1, yacht)
        } else {
          state.yachtsOldVersions.push(yacht)
        }
      }
    },
    [MU_SET_YACHT_PICTURES] (state, { yachtId, pictures }) {
      var yacht = state.yachts.find(y => y.id === Number(yachtId))
      yacht.pictures = pictures
    },
    [MU_DELETE_YACHT] (state, yachtId) {
      state.yachts = state.yachts.filter(yacht => Number(yacht.id) !== Number(yachtId))
    },
    [MU_SET_LOADING] (state, isLoading) {
      state.loading = isLoading
    },
    [MU_SET_UPLOADING] (state, isUploading) {
      state.uploading = isUploading
    },
  },
  actions: {
    [Actions.GET_USER_YACHTS] ({ commit, getters }) {
      commit(MU_SET_LOADING, true)
      return axios.get(`/yacht/by_user/${getters.userId}/`)
        .then(({ data }) => {
          commit(MU_SET_YACHTS, data)
          return data
        })
        .finally(() => { commit(MU_SET_LOADING, false) })
    },
    [Actions.GET_YACHT] ({ commit, state }, { yachtId, yachtVersion = undefined }) {
      commit(MU_SET_LOADING, true)
      return axios.get(`/yacht/${yachtId}` + (yachtVersion !== undefined ? `/${yachtVersion}` : ''))
        .then(({ data }) => {
          commit(MU_SET_YACHT, data)
          return data
        })
        .finally(() => { commit(MU_SET_LOADING, false) })
    },
    [Actions.CREATE_YACHT] ({ commit, getters }, yachtData) {
      commit(MU_SET_UPLOADING, true)
      return axios.post(`/yacht/by_user/${getters.userId}/`, yachtData)
        .then(({ data: yacht }) => {
          commit(MU_ADD_YACHT, yacht)
          return yacht
        })
        .finally(() => { commit(MU_SET_UPLOADING, false) })
    },
    [Actions.UPDATE_YACHT] ({ commit }, { yachtId, yachtVersion = undefined, yachtData = {} }) {
      commit(MU_SET_UPLOADING, true)
      return axios.put(
        `/yacht/${yachtId}` + (yachtVersion !== undefined ? `/${yachtVersion}` : ''),
        yachtData
      )
        .then(({ data }) => {
          commit(MU_SET_YACHT, data)
          return data
        })
        .finally(() => { commit(MU_SET_UPLOADING, false) })
    },
    [Actions.DELETE_YACHT] ({ commit }, yachtId) {
      commit(MU_SET_UPLOADING, true)
      return axios.delete(`/yacht/${yachtId}`)
        .then(() => {
          commit(MU_DELETE_YACHT, yachtId)
        })
        .finally(() => { commit(MU_SET_UPLOADING, false) })
    },
    [Actions.UPLOAD_YACHT_PICTURE] ({ commit, dispatch }, { yachtId, pictureData }) {
      const maxFileSize = process.env.VUE_APP_REST_MAX_UPLOAD_SIZE || 3
      const formData = new FormData()
      for (let i = 0; i < pictureData.length; i++) {
        formData.append('pictures', pictureData[i])
      }

      commit(MU_SET_UPLOADING, true)
      return axios.post(`/yacht/${yachtId}/image`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(({ data: pictures }) => {
          commit(MU_SET_YACHT_PICTURES, { yachtId, pictures })
        })
        .catch(err => {
          dispatch(Actions.ERROR, { title: `File size is exceeding the ${maxFileSize}MB limit`, ...err })
        })
        .finally(() => { commit(MU_SET_UPLOADING, false) })
    },
    [Actions.DELETE_YACHT_PICTURE] ({ commit }, { yachtId, pictureIdx }) {
      commit(MU_SET_UPLOADING, true)
      return axios.delete(`/yacht/${yachtId}/image/${pictureIdx}`)
        .then(({ data: pictures }) => {
          commit(MU_SET_YACHT_PICTURES, { yachtId, pictures })
        })
        .finally(() => { commit(MU_SET_UPLOADING, false) })
    },
  },
  getters: {
    yacht: (state) => (id, version = undefined) => {
      const y = state.yachts.find(y => y.id === Number(id) &&
        (version === undefined || y.version === Number(version)))
      if (y === undefined) {
        return state.yachtsOldVersions.find(y => y.id === Number(id) &&
          (version === undefined || y.version === Number(version)))
      } else {
        return y
      }
    },
  }
}

export default yachtModule
