<template>
<fragment>
 <TopSection />

 <Banner/>
    <Services/>
    <LapSection/>
    <LandingTabs/>
    <PricePlan/>
    <News/>
    <BottomSection/>
    <signup/>
    <Login/>
    <ForgotPasswordLink/>
    <ContactUs/>
  </fragment>
</template>
<script>
import TopSection from '@/components/TopSection'
import Banner from '@/components/Banner'
import Services from '@/components/Services'
import LapSection from '@/components/LapSection'
import LandingTabs from '@/components/LandingTabs'
import PricePlan from '@/components/PricePlan'
import News from '@/components/News'
import Signup from '@/components/Signup'
import Login from '@/components/Login'
import ForgotPasswordLink from '@/components/ForgotPasswordLink'
import ContactUs from '@/components/ContactUs'
import BottomSection from '@/components/BottomSection'

export default {
  name: 'Home',
  components: {
    TopSection,
    Banner,
    Services,
    LapSection,
    LandingTabs,
    PricePlan,
    News,
    Signup,
    Login,
    ForgotPasswordLink,
    BottomSection,
    ContactUs

  },
}
</script>