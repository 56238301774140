<template>
  <div class="approval-card" data-test="role-status-card">
    <div class="columns is-mobile is-gapless is-vcentered">
      <div class="column is-narrow">
        <pictogram :name="roleStatus.profile.full_name">
          <span>{{ roleStatus.profile.initials.toUpperCase() }}</span>
        </pictogram>
      </div>
      <div class="column">
        <div class="columns is-multiline is-gapless approval-card__text">
          <div class="column is-12">
            <span class="has-text-weight-semibold">{{ roleStatus.profile.full_name }}<span v-if="isUser">&nbsp;(You)</span></span><br/>
          </div>
          <div class="column is-12">
            <span>{{ `${roleStatus.role.charAt(0)}${roleStatus.role.slice(1).toLowerCase()}` }}</span>
          </div>
          <div v-if="cruiseStatus == 'DRAFT' || cruiseStatus == 'LOCKED'" class="column is-12 status is-size-7">
            <template v-if="roleStatus.current_approval">
              <span class="approved">◉</span>
              <span class="text">Approved</span>
            </template>
            <template v-else-if="!roleStatus.current_approval && roleStatus.past_approval">
              <span  class="outdated">◉</span>
              <span class="text">Outdated</span>
            </template>
            <template v-else-if="!roleStatus.current_approval && !roleStatus.past_approval">
              <span  class="pending">◉</span>
              <span class="text">Pending</span>
            </template>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
    <div v-if="cruiseStatus == 'DRAFT' || cruiseStatus == 'LOCKED'" class="columns approvals is-gapless is-vcentered is-mobile">
      <div v-if="isUser" class="column">
        <template v-if="roleStatus.current_approval">
          <button type="button" :class="[
            'button',
            'is-small',
            'is-warning',
            { 'is-loading': isFetchingApproval }
          ]" @click="handleApprovalRemove">Remove</button>
        </template>
        <template v-else>
          <button type="button" :class="[
            'button',
            'is-small',
            'is-success',
            { 'is-loading': isFetchingApproval }
          ]" @click="handleApproval">Approve</button>
        </template>
      </div>
    </div>
    <div v-if="roleStatus.last_edit_dt" class="last-edit">
      <span>Last Edit: {{ localeDatetime(roleStatus.last_edit_dt) }}</span>
    </div>
  </div>
</template>

<script>
import Pictogram from '@/components/UI/Pictogram'
import Actions from '@/store/actions'
import DateFormatters from '@/helpers/date_formatters'

export default {
  name: 'RoleStatusCard',
  components: {
    Pictogram
  },
  props: {
    roleStatus: {
      type: Object,
      required: true
    },
    isUser: {
      type: Boolean,
      default: false
    },
    cruiseStatus: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isFetchingApproval: false
    }
  },
  methods: {
    ...DateFormatters,
    handleApproval () {
      this.$buefy.dialog.confirm({
        message: 'Do you fully approve this agreement?',
        confirmText: 'Approve',
        type: 'is-success',
        hasIcon: false,
        onConfirm: () => {
          this.isFetchingApproval = true
          this.$store.dispatch(Actions.ADD_CRUISE_APPROVAL)
            .finally(() => { this.isFetchingApproval = false })
        }
      })
    },
    handleApprovalRemove () {
      this.$buefy.dialog.confirm({
        message: 'Do you want to revoke your approval?',
        confirmText: 'Revoke',
        type: 'is-warning',
        hasIcon: false,
        onConfirm: () => {
          this.isFetchingApproval = true
          this.$store.dispatch(Actions.DELETE_CRUISE_APPROVAL)
            .finally(() => { this.isFetchingApproval = false })
        }
      })
    }
  },
}
</script>

<style lang="scss">
  .approval-card {
    &__text {
      margin-left: .75rem !important;
    }

    .status {
      .approved {
        color: #23D160;
      }
      .pending {
        color: #D50000;
      }
      .outdated {
        color: #FFD600;
      }
      .text {
        margin-left: 0.25rem;
      }
    }

    // @include mobile {
    //   .pictogram {
    //     display: none;
    //   }
    // }

    .approvals {
      font-size: .9rem;
      margin-bottom: .2rem !important;
    }

    .last-edit {
      font-size: .9rem;
      color: #999;
      font-style: italic;
    }

    // avoid nested column margin issues:
    >.columns {
        margin: 0;
        padding: 0;
    }

    .is-gapless {
      margin-bottom: 0.2rem !important;
    }
  }

</style>
