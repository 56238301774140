<template>
  <b-modal
    :active.sync="showPaymentModal"
    has-modal-card
    :destroy-on-hide="true"
    aria-role="dialog"
    aria-modal
    trap-focus
    :on-cancel="cancelPayment"
  >
    <form class="modal-card" style="width: 600px" @submit="submitPayment">
      <header class="modal-card-head">
        <p class="modal-card-title">Payment</p>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <b-message v-if="paymentMessage" type="is-info"><p v-html="paymentMessage" /></b-message>
          <b-message v-if="products.length == 0">
            No purchases are available to allow this action on this agreement. If you believe this might be an error, please <a href="mailto:support@wolfgang.pro">contact our support</a>.
          </b-message>
          <b-field v-if="products.length > 1">
            <b-select
              v-model="product"
              placeholder="Select a purchase…"
              size="is-large"
              required
            >
              <option v-for="p in products" :key="p.product_id" :value="p">{{ p.product_name }}
                <span v-if="p.price > 0">({{p.currency_sign}}{{p.price}})</span>
                <span v-else>(free)</span>
              </option>
            </b-select>
          </b-field>
          <template v-if="product">
            <h4 v-if="products.length == 1">{{ product.product_name }}</h4>
            <p v-html="product.description" />
            <b-message v-if="product.promos && product.promos.length" type="is-success">Special conditions:<ul><li v-for="(promo,i) in product.promos" :key="i" v-html="promo" /></ul></b-message>
            <table v-if="product.price > 0" class="pricing">
              <tr>
                <td>
                  Price (excluding VAT):
                </td>
                <td>
                  <strong>{{ product.currency_sign}}&nbsp;{{ product.price}}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <b-select v-model="selectedVat" placeholder="Select a VAT rate…" required>
                    <option v-for="(vat,i) in vatRates" :key="vat[0]" :value="i">VAT: {{ vat[2]*100 }}% ({{ vat[1] }}){{ vat[0] === 'NON_EU_COMPANY' ? '*' : '' }}</option>
                  </b-select>
                </td>
                <td>
                  <strong>{{ product.currency_sign }}&nbsp;{{ selectedVat !== null ? vatAmount : 'n/a' }}</strong>
                </td>
              </tr>
              <tr v-if="vatCode === 'NON_EU_COMPANY'">
                <td class="comment">*If you are elligible for VAT exemption, contact us directly for a refund.</td>
                <td/>
              </tr>
              <tr class="total">
                <td>
                  <strong>Total:</strong>
                </td>
                <td>
                  <strong>{{ product.currency_sign }}&nbsp;{{ selectedVat !== null ? totalPrice : 'n/a' }}</strong>
                </td>
              </tr>
            </table>
          </template>
          <fieldset v-show="product && product.price > 0">
            <b-field>
              <div id="card-element" />
            </b-field>
            <b-field>
              <div class="control">
                <b-button class="is-static">Name</b-button>
              </div>
              <b-input v-model="billingName" placeholder="Billing Name" required expanded />
            </b-field>
            <b-field>
              <div class="control">
                <b-button class="is-static">Email</b-button>
              </div>
              <b-input v-model="billingEmail" type="email" placeholder="Billing Email" required expanded />
            </b-field>
            <template v-if="profile && vatCode && vatCode !== 'PERSON'">
              <FormItem
                name="company_name"
                label="Company"
                :value="profile.company_name"
                custom-class=""
                required
                @change="updateProfileData"
              />
              <FormItem
                name="company_vat_num"
                label="VAT Number"
                :value="profile.company_vat_num"
                custom-class=""
                required
                uppercase
                @change="updateProfileData"
              />
              <FormItem
                name="company_reg_address"
                label="Address"
                :value="profile.company_reg_address"
                custom-class=""
                required
                @change="updateProfileData"
              />
              <div class="columns">
                <FormItem
                  name="company_reg_city"
                  label="City"
                  :value="profile.company_reg_city"
                  required
                  @change="updateProfileData"
                />
                <FormItem
                  name="company_reg_postcode"
                  label="Postcode"
                  :value="profile.company_reg_postcode"
                  required
                  uppercase
                  @change="updateProfileData"
                />
              </div>
              <FormItem
                name="company_reg_country_iso"
                label="Country"
                :value="profile.company_reg_country"
                custom-class=""
                is-country-select
                required
                @change="updateProfileData"
              />
            </template>
          </fieldset>
          <b-message v-if="error" type="is-danger" role="alert">{{error}}</b-message>
          <b-message v-if="success" type="is-success">{{success}}</b-message>
        </div>
      </section>
      <footer class="modal-card-foot">
        <template v-if="products.length != 0">
          <b-field><em><b-checkbox required>By checking this box, you acknowledge that you have read and accept Wolfgang's <a :href="serverURL + '/tos'" target="_new">Terms and Conditions</a>.</b-checkbox></em></b-field>
          <b-button v-if="product && product.price == 0" type="is-success" :disabled="loading" :loading="loading" @click="claimFree">
            Add for free
          </b-button>
          <b-button v-else type="is-primary" :disabled="loading" :loading="loading" native-type="submit">
            Purchase
          </b-button>
        </template>
        <template v-else>
          <b-button type="is-primary" @click="cancelPayment">
            Cancel
          </b-button>
        </template>
      </footer>
    </form>
  </b-modal>
</template>
<script>

import Vue from 'vue'

import axios, { serverURL } from '@/api/config'
// import axios from '@/api/config'
import Actions from '@/store/actions'
import { nestedVal, nestedTrue } from '@/helpers/utils'
import { eventBus } from '@/main.js'

const STRIPE_CHECKOUT_URL = 'https://js.stripe.com'

export default {
  components: {
  },
  data: () => ({
    intent: {},
    error: false,
    success: false,
    loading: false,
    stripe: null,
    card: null,
    paymentMessage: '',
    products: [],
    vatRates: [],
    selectedVat: null,
    product: null,
    billingName: null,
    billingEmail: null,
    showPaymentModal: false,
    queryConfig: null,
    resolve: null,
    reject: null,
    isSending: false,
    serverURL: serverURL,
  }),
  computed: {
    cruise: function () { return this.$store.state.cruise.cruise },
    vatCode: function () { return this.selectedVat !== null ? this.vatRates[this.selectedVat][0] : null },
    vatAmount: function () { return this.selectedVat !== null ? Math.round(100 * this.vatRates[this.selectedVat][2] * this.product.price) / 100.0 : null },
    totalPrice: function () { return this.vatAmount !== null ? this.vatAmount + this.product.price : null },
    currentUser: function () { return this.$store.state.user.user },
    profile: function () { return this.$store.getters.mainProfile },
  },
  mounted () {
    // Check if payment is not disabled:
    if (Number(process.env.VUE_APP_WOLFGANG_DISABLE_PAYMENT) === 1) return

    eventBus.$on('handle-payment', (response, resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.queryConfig = response.config
      this.initWithResponse(response)

      if (document.getElementById('stripev3')) {
        this.initStripe()
      } else {
        this.loadStripeCheckout(this.pk, 'v3', this.initStripe)
      }
    })
  },
  created () {
    this.billingName = this.currentUser.main_profile.full_name
    this.billingEmail = this.currentUser.email
  },
  methods: {
    updateProfileData (profileVar, val) {
      this.loading = true
      this.$store.dispatch(Actions.UPDATE_PROFILE, {
        profileId: this.profile.id,
        profileData: { [profileVar]: val }
      })
        .then((data) => {
          this.$emit('profile-changed', data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    initStripe () {
      if (!this.stripe) {
        console.log('Stripe PK:', process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY.substring(0, 7))
        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, { locale: 'en', })
      }

      this.showPaymentModal = true
      this.$nextTick(() => {
        this.card = this.stripe.elements().create('card', {
          style: {
            base: {
              fontSize: '18px'
            }
          }
        })
        this.card.mount('#card-element')
        this.card.addEventListener('change', ({ error }) => {
          if (error) {
            this.error = error.message
            if (Vue.rollbar) {
              Vue.rollbar.warning('Stripe listener error:', error.message)
            }
          }
        })
      })
    },
    loadStripeCheckout (pk, version = 'v3', callback) {
      const e = document.createElement('script')
      e.src = `${STRIPE_CHECKOUT_URL}/${version}`
      e.type = 'text/javascript'
      e.id = 'stripev3'
      document.getElementsByTagName('head')[0].appendChild(e)
      e.addEventListener('load', callback)
    },
    initWithResponse (response) {
      this.paymentMessage = response.data.message
      this.products = response.data.products
      this.error = false
      this.success = false
      if (this.products.length === 1) {
        this.product = this.products[0]
      } else {
        this.product = null
      }
      this.vatRates = response.data.vat_rates
    },
    submitOriginalQuery () {
      this.paymentMessage = ''
      this.products = []
      this.product = null
      this.loading = true

      this.queryConfig.is_retry = true
      return axios.request(this.queryConfig)
        .then(this.resolve)
        .catch(error => {
          if (error.response.status === 402) {
            console.log(error.config.is_retry)
            console.log('Second 402')
            this.initWithResponse(error.response)
          } else {
            this.reject(error)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    claimFree (event) {
      console.log('submitting free', this.product)
      return axios.post(`/billing/${this.cruise.id}/pay`, {
        product: this.product,
      })
        .then(this.handleServerResponse)
    },
    async submitPayment (event) {
      event.preventDefault()

      if (this.vatCode !== 'PERSON') {
        if (!this.profile.company_reg_country) {
          this.error = 'Please provide a registration country for your company on your profile page.'
          return
        }

        if (this.vatCode === 'EU_COMPANY') {
          var vatCountryCode = this.profile.company_reg_country.iso
          if (vatCountryCode === 'GR') { vatCountryCode = 'EL' } else if (vatCountryCode === 'UK') { vatCountryCode = 'GB' }

          const euVatCodes = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'ES', 'FI', 'FR', 'GB', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK']
          if (!euVatCodes.includes(vatCountryCode)) {
            this.error = "This registration country for your company does not belong to the EU, please select 'Non-EU company' in the VAT menu or update your profile."
            return
          }

          if (!this.profile.company_vat_num.startsWith(vatCountryCode)) {
            this.error = "Make sure your VAT number starts with the correct 2-letter EU country code and matches your company's country of registration."
            return
          }

          if (vatCountryCode === 'GB' && new Date().getFullYear() >= 2021) {
            this.error = 'The EU country you have dialed, is no longer available.'
            return
          }

          if (vatCountryCode === 'FR') {
            this.error = "Please select 'French Company' in the VAT menu."
            return
          }
        }
      }

      try {
        this.loading = true
        this.error = false

        this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.billingName,
            email: this.billingEmail,
          },
        })
          .then(this.handleStripeJsResult)
          .catch(this.handleServerError)
      } catch (error) {
        console.error(error)
        this.error = 'Stripe exception:' + error
        this.loading = false
      }
    },
    handleStripeJsResult (result) {
      if (result.error) {
        return Promise.reject(result.error)
      }

      const purchaseData = {
        product: this.product,
        vat_code: this.vatCode,
        billing_email: this.billingEmail,
      }
      var vatData = {}
      if (this.vatCode !== 'PERSON') {
        vatData = {
          company_name: this.profile.company_name,
          company_vat_num: this.profile.company_vat_num,
          company_reg_address: this.profile.company_reg_address,
          company_reg_city: this.profile.company_reg_city,
          company_reg_postcode: this.profile.company_reg_postcode,
          company_reg_country_iso: this.profile.company_reg_country.iso,
        }
      }

      if (result.paymentIntent) {
        return axios.post(`/billing/${this.cruise.id}/pay`, {
          payment_intent_id: result.paymentIntent.id,
          ...purchaseData,
          ...vatData,
        })
          .then(this.handleServerResponse)
      } else {
        return axios.post(`/billing/${this.cruise.id}/pay`, {
          payment_method_id: result.paymentMethod.id,
          ...purchaseData,
          ...vatData,
        })
          .then(this.handleServerResponse)
          .catch((error) => {
            if (Vue.rollbar) {
              Vue.rollbar.error('handleStripeJsResult error:', error)
            }
            if (error.response && error.response.status === 401) {
              console.log('Should resubmit query…')
              this.submitOriginalQuery()
            }
            return Promise.reject(error)
          })
      }
    },
    handleServerResponse (response) {
      if (response.error) {
        if (Vue.rollbar) {
          Vue.rollbar.error('handleServerResponse: response.error', response.error)
        }
        return Promise.reject(response.error)
      } else if (nestedTrue(response, 'data.requires_action')) {
        console.log('handleServerResponse, Requires further action:', response.data)
        return this.stripe.handleCardAction(response.data.payment_intent_client_secret)
          .then(this.handleStripeJsResult)
      } else {
        console.log('handleServerResponse: success:', response)
        this.success = 'Payment successful…'
        this.submitOriginalQuery()
        this.showPaymentModal = false
      }
    },
    handleServerError (error) {
      console.log('handleServerError, catch:', error.message)

      if (nestedVal(error, 'response.data.message')) this.error = error.response.data.message
      else if (nestedVal(error, 'response.data.status')) this.error = error.response.data.status
      else if ('message' in error) this.error = error.message

      this.loading = false
    },
    cancelPayment () {
      this.showPaymentModal = false
      this.reject('Payment cancelled')
    }
  }
}
</script>
<style lang="scss" scoped>
  table.pricing {
    td {
      padding-top: 0;
      border: none;
    }
    tr.total {
      border-top: 1px solid #DDD;
      td {
        padding-top: 3px;
      }
    }
    tr {
      td.comment {
        font-size: 80%;
      }
    }
  }

  // .columns {
  //   padding-bottom: 0;
  //   margin-bottom: 0 !important;
  // }
  .column {
    padding-bottom: 0;
  }
</style>
