<template>
  <fragment>
    <div class="banner">
      <h2 class="fnt-lib-r">Smarter and Greener<br/>Yachting!</h2>
      <div class="logo_center">
        <img
          v-show="elementVisible"
          src="@/assets/img/image/W_STROKE.png"
          class="img-fluid banner-logo"
        />
        <div class="yacht_align"></div>
      </div>
      <a href="#" class="try-demo fnt-lib-i contact-us-trigger-demo">Contact Us</a>
    </div>
    <div class="about-us" id="banner1">
      <div class="about-wrapper">
        <h2 class="fnt-lib-r">
          Sail different:<br/>discover Superyacht Cruises
        </h2>
          <ul class="bullets">
            <li>Simple and safe: we handle the complexity</li>
            <li>Fast: answer your clients' inquiries and change of plans in record time</li>
            <li>Safe: all calculations and optimizations are backed by our proprietary platform</li>
          </ul>
          <p class="fnt-p">
            Wolfgang services are the seamless gateway for yachts owners and professionals to the transport and cruise solutions.
        </p>
      </div>
    </div>
  </fragment>
</template>

<script>
import jQuery from 'jquery'
const $ = jQuery

export default {
  data () {
    return { elementVisible: false }
  },
  created () {
    this.elementVisible = true
  },
  mounted () {
    $('.contact-us-trigger-demo').click(function () {
      $('#contact-us-modal').addClass('active')
      $('body').append('<div class="modal-overlay"></div>')
    })
    $('.close-modal').click(function () {
      $('#contact-us-modal').removeClass('active')
      $('.modal-overlay').remove()
    })
  }
}
</script>

<style scoped src="@/assets/css/style.css"></style>
<style scoped type="scss">
ul.bullets  {
  margin-bottom: 1em;
  color: white;
  text-align: center;

  li {
    list-style-type: disc;
    list-style-position: inside;
  }

}
</style>
