import Vue from 'vue'
import router from './router'
import App from './App'
import store from './store'
// import './assets/css/style.css'
// import * as VueGoogleMaps from 'vue2-google-maps'
import Buefy from 'buefy'
import VCalendar from 'v-calendar'
import dayjs from 'dayjs'
import CreateButton from '@/components/UI/CreateButton'
import SubmitButton from '@/components/UI/SubmitButton'
import BackButton from '@/components/UI/BackButton'
import FormItem from '@/components/UI/FormItem'
import Loading from '@/components/UI/Loading'

import RequestError from '@/components/UI/RequestError'

// Styles
// import './styles/base.scss'
import 'vue-multiselect/dist/vue-multiselect.min.css'

// Importing fontawesome from vue-fontawesome for use in Buefy:
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Fragment from 'vue-fragment'

import AOS from 'aos'
import 'aos/dist/aos.css'
import VueScrollTo from 'vue-scrollto'
import Sticky from 'vue-sticky-directive'
Vue.use(Sticky)
// global register
Vue.use(Fragment.Plugin)
Vue.use(VueScrollTo)
// polyfill:
// TODO: use es-shims: https://github.com/es-shims/Object.entries
// import 'babel-polyfill'
if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj)
    var i = ownProps.length
    var resArray = new Array(i) // preallocate the Array
    while (i--) { resArray[i] = [ownProps[i], obj[ownProps[i]]] }
    return resArray
  }
}

// polyfill:
// TODO: use es-shims: https://github.com/es-shims/Object.entries
// import 'babel-polyfill'
if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj)
    var i = ownProps.length
    var resArray = new Array(i) // preallocate the Array
    while (i--) { resArray[i] = [ownProps[i], obj[ownProps[i]]] }
    return resArray
  }
}

Vue.config.productionTip = false
Vue.config.devtools = true

// Shared UI components
Vue.component('FormItem', FormItem)
Vue.component('CreateButton', CreateButton)
Vue.component('SubmitButton', SubmitButton)
Vue.component('BackButton', BackButton)
Vue.component('RequestError', RequestError)
Vue.component('Loading', Loading)
library.add(fas)
// library.add(far)
Vue.component('VueFontawesome', FontAwesomeIcon)

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
  // defaultTimeFormatter: function (date) {
  //   console.info(dayjs.utc(date).format('HH:mm'))
  //   return dayjs.utc(date).format('HH:mm')
  // }
})

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyCXzXeWA9TjvTaYepmZw_63_k1WFwglUNQ',
//     libraries: 'drawing'
//   }
// })
Vue.use(VCalendar, {
  firstDayOfWeek: 2
})

Vue.prototype.$dayjs = dayjs
/* eslint-disable no-new */

if (process.env.VUE_APP_ROLLBAR_CLIENT_TOKEN) {
  console.info(`Rollbar enabled: ${process.env.VUE_APP_ROLLBAR_CLIENT_TOKEN}`)
  var Rollbar = require('vue-rollbar')
  Vue.use(Rollbar, {
    accessToken: process.env.VUE_APP_ROLLBAR_CLIENT_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    source_map_enabled: true,
    autoInstrument: {
      network: true,
      networkRequestBody: true,
      networkResponseBody: true,
    },
    environment: process.env.NODE_ENV,
    payload: {
      client: {
        javascript: {
          code_version: process.env.VUE_APP_GIT_HASH
        }
      }
    }
  })

  Vue.config.errorHandler = function (err, vm, info) {
    Vue.rollbar.error(err)
    console.error(err)
    return true
  }
}

new Vue({
  el: '#app',
  mounted () {
    AOS.init()
  },
  router,
  store,
  render: h => h(App)
})

export const eventBus = new Vue()
