<template>
  <div class="columns is-vcentered">
    <div class="column is-7">
      <b-field has-addons>
        <b-tooltip
          v-if="!readOnly && !call.contract_code"
          class="control"
          label="Existing payments must be cancelled before deleting this call"
          :active="call.paid_amount != 0"
          position="is-right"
          multilined
          type="is-warning"
        >
          <b-button
            v-if="!readOnly"
            icon-left="trash-alt"
            class="mouseover-danger"
            pack="fas"
            type="is-text"
            :disabled="call.paid_amount != 0"
            @click="handleDelete(call.id)"
          />
        </b-tooltip>
        <p v-if="call.contract_code" class="control">
          <b-button class="is-static" expanded>
            {{ call.designation }}
          </b-button>
        </p>
        <p v-else class="control is-expanded">
          <b-input
            :value="call.designation"
            :disabled="readOnly"
            length="20"
            @change.native="(e) => updatePaymentCall('designation', e.target.value)"
          />
        </p>
        <p class="control">
          <span class="button is-static">{{ currency }}</span>
        </p>
        <b-tooltip
          label="Cannot be modified after payment"
          :active="!readOnly && call.paid_amount != 0"
          class="control is-expanded"
        >
            <b-input
              :value="formatPrice(call.amount)"
              custom-class="price"
              min="0"
              :placeholder="readOnly ? 'n/a' : 0"
              :disabled="readOnly || call.paid_amount != 0"
              expanded
              @change.native="(e) => updateAmount(e.target.value)"
            />
        </b-tooltip>
      </b-field>
    </div>
    <div class="column">
      <b-field>
        <template v-if="call.paid_ratio > 1 && (call.amount - call.paid_amount) < 0">
          <p class="control">
            <b-button
              class="is-static"
              icon-left="exclamation-triangle"
            >
              Overpaid by <strong>{{ currency }} {{ formatPrice(call.paid_amount - call.amount) }}</strong> <template v-if="call.last_payment_date"> on {{ localeDate(call.last_payment_date) }}</template>
            </b-button>
          </p>
        </template>
        <template v-else-if="call.paid_ratio == 1">
          <p class="control">
            <b-button class="is-static">
              Paid in full<template v-if="call.last_payment_date"> on {{ localeDate(call.last_payment_date) }}</template>
            </b-button>
          </p>
        </template>
        <template v-else-if="call.due_by_execution">
          <p class="control">
            <b-button class="is-static">
              to be paid on execution of the agreement
            </b-button>
          </p>
        </template>
        <template v-else-if="!isSettlement">
          <p class="control">
            <span class="button is-static">
              to be paid by:
            </span>
          </p>
          <b-datepicker
           icon="calendar"
           pack="fas"
           :disabled="readOnly || call.paid_amount != 0"
           :value="string2date(call.date_due)"
           :first-day-of-week="1"
           :date-formatter="localeDate"
           expanded
           editable
           @input="val => updateDateDue(val)"
          />
        </template>
        <p v-if="call.payments.length" class="control">
          <b-button
            @click="isPaymentListModalActive = true"
          >
            <b-icon
              icon="file"
              :type="call.paid_ratio < 1 ? 'is-danger' : 'is-primary'"
              outlined
            />
          </b-button>
        </p>
        <p v-if="call.amount != 0 && call.can_post_payments && call.paid_ratio != 1" class="control">
          <b-tooltip
            label="Must fill payment call info first"
            :active="!callFilledin"
            type="is-warning"
            position="is-left"
          >
            <b-button
              type="is-primary"
              :disabled="!callFilledin"
              @click="isPaymentModalActive = true"
            >
              {{ payOrCredit }}
            </b-button>
          </b-tooltip>
        </p>
      </b-field>
    </div>
    <b-modal :active.sync="isPaymentModalActive"
             has-modal-card
             trap-focus
             aria-role="dialog"
             aria-modal
    >
       <div class="modal-card" style="width: auto">
           <payment-form :call="call" />
        </div>
    </b-modal>
    <b-modal :active.sync="isPaymentListModalActive"
             has-modal-card
             trap-focus
             aria-role="dialog"
             aria-modal
    >
       <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Payment{{call.payments.length > 1 ? 's' : ''}} for {{call.designation}}</p>
          </header>
          <section class="modal-card-body">
            <RequestError :error="$store.state.error.currentError"  :simple="true" />
            <p class="section">
              <span v-if="call.amount < 0">
                Credit:
              </span>
              <span v-else>
                Call for fund:
              </span>
              {{ currency }} <strong>{{ formatPrice(call.amount) }}</strong>
              <span v-if="call.due_by_execution">
                to be paid on execution date of the contract.
              </span>
              <span v-if="call.date_due">
                to be paid by {{ localeDateLong(call.date_due) }}.
              </span>
            </p>
            <b-table
              :data="paymentsWithBalance"
              :striped="true"
              :hoverable="true"
              :class="callBalanceClass"
              td-attrs
            >
              <b-table-column field="cancel" v-slot="props">
                <a
                  v-if="!props.row.cancelled && !props.row.locked"
                  title="Cancel payment"
                  class="mouseover-danger"
                  @click="handleCancelPayment(props.row.id)"
                >
                  <b-icon icon="trash-alt" />
                </a>
              </b-table-column>
              <b-table-column
                field="date_paid"
                label="Date Paid"
                v-slot="props"
              >
              <span :class="{'cancelled': props.row.cancelled}">
                  {{ localeDateLong(props.row.date_paid) }}
              </span>
              </b-table-column>
              <b-table-column
                field="currency"
                label=""
                v-slot="props"
              >
              <span :class="{'cancelled': props.row.cancelled}">
                {{ currency }}
              </span>
              </b-table-column>
              <b-table-column
                field="amount"
                label="Amount"
                numeric
                class="price"
                v-slot="props"
              >
              <span :class="{'cancelled': props.row.cancelled}">
                {{ formatPrice(props.row.amount) }}
              </span>
              </b-table-column>
              <b-table-column
                field="balance"
                label="Remaining"
                numeric
                v-slot="props"
              >
              <span :class="{'cancelled': props.row.cancelled}">
                {{ formatPrice(props.row.balance) }}
              </span>
              </b-table-column>
              <b-table-column
                field="docs"
                label="Docs"
                v-slot="props"
              >
                <template v-if="props.row.public_url">
                  <a
                    icon="eye"
                    title="View document"
                    :href="serverURL + props.row.public_url"
                    target="_blank"
                  >
                    <b-icon icon="eye" size="is-small" />
                  </a>&nbsp;
                  <a
                    title="Download document"
                    :href="serverURL + props.row.public_url"
                    target="_blank"
                    download=""
                  >
                    <b-icon icon="file" size="is-small" />
                  </a>
                </template>
              </b-table-column>
              <template #footer>
                <th></th>
                <th>Total paid</th>
                <th>{{currency}}</th>
                <th>
                  <div class="is-numeric price">
                  {{ formatPrice(call.paid_amount) }}
                  </div>
                </th>
                <th>
                  <div class="is-numeric price">
                    {{ formatPrice(call.amount - call.paid_amount) }}
                  </div>
                </th>
              </template>
            </b-table>
          </section>
          <footer class="modal-card-foot" />
        </div>
    </b-modal>
  </div>
</template>

<script>

import Actions from '@/store/actions'
import DateFormatters from '@/helpers/date_formatters'
import NumFormatters from '@/helpers/num_formatters'
import PaymentForm from '@/components/forms/cruise/PaymentForm'
import { serverURL } from '@/api/config'

export default {
  name: 'PaymentCall',
  components: {
    PaymentForm,
  },
  props: {
    call: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      isLoading: false,
      isPaymentModalActive: false,
      isPaymentListModalActive: false,
      serverURL: serverURL,
    }
  },
  computed: {
    currency () {
      return this.$store.state.cruise.cruise.currency
    },
    readOnly () {
      return (!this.call.write_access || this.call.locked || this.isSettlement)
    },
    callFilledin () {
      return (this.call.amount && (this.call.due_by_execution || this.call.date_due || this.isSettlement))
    },
    paymentsWithBalance () {
      var balance = this.call.amount
      return this.call.payments.map((p) => {
        if (!p.cancelled) balance -= p.amount
        p.balance = balance
        return p
      })
    },
    callBalanceClass () {
      if (this.call.amount !== 0) {
        if (this.call.paid_ratio === 1) {
          return 'paid-in-full'
        } else if (this.call.paid_ratio < 1) {
          return 'balance-remaining'
        } else if (this.call.paid_ratio > 1) {
          return 'overpaid'
        }
      }
      return ''
    },
    isSettlement () {
      return this.call.contract_code === 'SETTLEMENT'
    },
    payOrCredit () {
      return (this.call.amount - this.call.paid_amount) < 0 ? 'Credit' : 'Pay'
    }
  },
  created () {
    this.isLoading = false
  },
  methods: {
    ...DateFormatters,
    ...NumFormatters,
    updateAmount (val) {
      val = val ? parseFloat(val.replace(/[ ,]/g, '')) : 0
      this.updatePaymentCall('amount', val)
        .then(() => {
          this.$emit('amount-changed', this.call)
        })
    },
    updateDateDue (val) {
      val = this.date2stringDateOnly(val)
      this.updatePaymentCall('date_due', val)
    },
    updatePaymentCall (pcVar, val) {
      return this.$store.dispatch(Actions.UPDATE_PAYMENT_CALL, {
        paymentCallId: this.call.id,
        paymentCallData: { [pcVar]: val }
      })
    },
    handleDelete (id) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this call for fund?',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          this.$store.dispatch(Actions.DELETE_PAYMENT_CALL, id)
        }
      })
    },
    handleCancelPayment (id) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to cancel this payment?',
        confirmText: 'Yes',
        cancelText: 'No',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          this.$store.dispatch(Actions.CANCEL_PAYMENT, id)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  html {
    @include mobile {
      font-size: 14px !important;
    }
  }

  .modal-card {
    font-weight: normal;
    font-size: 1rem;
  }

  .columns {
    margin-bottom: 0 !important;
  }

  .button.is-static {
    @include mobile {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
  }

  .paid-in-full th {
    background-color: $success;
  }

  .balance-remaining th {
    background-color: #F97;
  }

  .overpaid th {
    background-color: #FCA;
  }

  .cancelled {
    text-decoration: line-through;
  }
</style>
