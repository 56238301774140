var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"approval-card",attrs:{"data-test":"role-status-card"}},[_c('div',{staticClass:"columns is-mobile is-gapless is-vcentered"},[_c('div',{staticClass:"column is-narrow"},[_c('pictogram',{attrs:{"name":_vm.roleStatus.profile.full_name}},[_c('span',[_vm._v(_vm._s(_vm.roleStatus.profile.initials.toUpperCase()))])])],1),_c('div',{staticClass:"column"},[_c('div',{staticClass:"columns is-multiline is-gapless approval-card__text"},[_c('div',{staticClass:"column is-12"},[_c('span',{staticClass:"has-text-weight-semibold"},[_vm._v(_vm._s(_vm.roleStatus.profile.full_name)),(_vm.isUser)?_c('span',[_vm._v(" (You)")]):_vm._e()]),_c('br')]),_c('div',{staticClass:"column is-12"},[_c('span',[_vm._v(_vm._s(`${_vm.roleStatus.role.charAt(0)}${_vm.roleStatus.role.slice(1).toLowerCase()}`))])]),(_vm.cruiseStatus == 'DRAFT' || _vm.cruiseStatus == 'LOCKED')?_c('div',{staticClass:"column is-12 status is-size-7"},[(_vm.roleStatus.current_approval)?[_c('span',{staticClass:"approved"},[_vm._v("◉")]),_c('span',{staticClass:"text"},[_vm._v("Approved")])]:(!_vm.roleStatus.current_approval && _vm.roleStatus.past_approval)?[_c('span',{staticClass:"outdated"},[_vm._v("◉")]),_c('span',{staticClass:"text"},[_vm._v("Outdated")])]:(!_vm.roleStatus.current_approval && !_vm.roleStatus.past_approval)?[_c('span',{staticClass:"pending"},[_vm._v("◉")]),_c('span',{staticClass:"text"},[_vm._v("Pending")])]:_vm._e(),_vm._v("   ")],2):_vm._e()])])]),(_vm.cruiseStatus == 'DRAFT' || _vm.cruiseStatus == 'LOCKED')?_c('div',{staticClass:"columns approvals is-gapless is-vcentered is-mobile"},[(_vm.isUser)?_c('div',{staticClass:"column"},[(_vm.roleStatus.current_approval)?[_c('button',{class:[
          'button',
          'is-small',
          'is-warning',
          { 'is-loading': _vm.isFetchingApproval }
        ],attrs:{"type":"button"},on:{"click":_vm.handleApprovalRemove}},[_vm._v("Remove")])]:[_c('button',{class:[
          'button',
          'is-small',
          'is-success',
          { 'is-loading': _vm.isFetchingApproval }
        ],attrs:{"type":"button"},on:{"click":_vm.handleApproval}},[_vm._v("Approve")])]],2):_vm._e()]):_vm._e(),(_vm.roleStatus.last_edit_dt)?_c('div',{staticClass:"last-edit"},[_c('span',[_vm._v("Last Edit: "+_vm._s(_vm.localeDatetime(_vm.roleStatus.last_edit_dt)))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }