<template>
  <div data-test="yacht-form" class="yachtdetail is-clearfix" :aria-busy="isLoading || isUploading">
    <div v-if="isLoading && !yacht">
      <div class="columns">
        <div class="column has-text-centered">
          <b-icon
            icon="circle-notch"
            type="is-grey"
            size="is-large"
            custom-class="fa-spin"
          />
        </div>
      </div>
    </div>
    <div v-else-if="yacht">
      <div class="columns is-multiline is-gapless is-mobile images">
        <div v-for="(picture, i) in yacht.pictures" :key="i" class="column is-narrow">
            <div :class="{ frame: true, disabled: isReadOnly}">
              <img :src="getImageUrl(picture.public_url)" alt="" />
              <button v-if="!isReadOnly" class="delete-btn button is-danger" @click="deletePicture(i)">Remove</button>
            </div>
          </div>
        <div class="column" />
        <div v-if="!isReadOnly" class="column is-narrow">
            <b-field>
              <b-upload
                v-model="uploadFiles"
                multiple
                drag-drop
                :disabled="isUploading"
                accept="image/*"
                @input="startUpload"
              >
                <section class="section upload-area">
                  <div v-if="uploadFiles.length == 0" class="content has-text-centered">
                    <p>
                      <b-icon
                        icon="upload"
                        size="is-medium"
                      />
                    </p>
                    <h4 class="upload">Upload Pictures</h4>
                    <p class="upload">(Max {{ this.maxFileSize }}MB)</p>
                    <h4 class="hover">Click or drag files to upload…</h4>
                  </div>
                  <div v-else class="content has-text-centered">
                    <p>
                      <b-icon
                        icon="spinner"
                        size="is-medium"
                        custom-class="fa-spin"
                      />
                    </p>
                    <h4>Uploading…</h4>
                    <b-taglist>
                      <b-tag
                        v-for="(file, index) in uploadFiles"
                        :key="index"
                        type="is-primary"
                        closable
                        aria-close-label="Remove file"
                        @close="removeFileUpload(index); return false"
                        @click.native.prevent
                      >
                        {{file.name | shortenFilename}}
                     </b-tag>
                   </b-taglist>
                 </div>
               </section>
              </b-upload>
            </b-field>
          </div>
      </div>
      <form>
        <fieldset>
          <legend>General</legend>
          <div class="columns">
            <FormItem
              name="name"
              label="Yacht"
              :value="yacht.name"
              :read-only="isReadOnly"
              required
              @change="updateYachtData"
            />
            <FormItem
              name="flag_country_iso"
              :value="yacht.flag"
              :read-only="isReadOnly"
              :is-country-select="true"
              required
              @change="updateYachtData"
            />
            <FormItem
              name="port_of_registry"
              :value="yacht.port_of_registry"
              :read-only="isReadOnly"
              required
              @change="updateYachtData"
            />
          </div>
          <div class="columns">
            <FormItem
              name="type"
              :value="yacht.type"
              :read-only="isReadOnly"
              placeholder="Select a yacht type"
              :select-options="[{value: 'MOTOR', label: 'Motor Yacht'}, {value: 'SAIL', label: 'Sail Yacht'}]"
              required
              @change="updateYachtData"
            />
            <FormItem
              name="status"
              :value="yacht.status"
              :read-only="isReadOnly"
              placeholder="Select a status"
              :select-options="[{value: 'COMMERCIAL', label: 'Commercial'}, {value: 'PRIVATE', label: 'Private'}]"
              required
              @change="updateYachtData"
            />
            <FormItem
              name="imo_nb"
              :value="yacht.imo_nb"
              label="IMO"
              type="number"
              :read-only="isReadOnly"
              required
              @change="updateYachtData"
            />
            <FormItem
              name="loa"
              :value="yacht.loa"
              label="LOA"
              type="number"
              placeholder="Total length"
              :read-only="isReadOnly"
              unit="m"
              required
              @change="updateYachtData"
            />
          </div>
        </fieldset>
        <div v-if="!areDetailsShown" class="columns">
          <div class="column has-text-centered">
            <button type="button" class="button is-primary is-outlined" @click="areDetailsShown = true">Detailed info</button>
          </div>
        </div>
        <div v-if="areDetailsShown">
          <fieldset>
            <legend>Speed</legend>
            <b-message v-if="!isValidSpeed" type="is-warning">
              Missing required speed/consumption data: fuel consumption estimate will not be calculated.
            </b-message>
            <div class="columns is-vcentered">
              <div class="column">
                <div class="columns">
                  <FormItem
                    name="max_spd"
                    :value="yacht.max_spd"
                    label="Max speed"
                    type="number"
                    :read-only="isReadOnly"
                    unit="kt"
                    required
                    @change="updateYachtData"
                  />
                  <FormItem
                    name="max_spd_cons"
                    :value="yacht.max_spd_cons"
                    label="Consumption"
                    type="number"
                    :read-only="isReadOnly"
                    unit="L/h"
                    required
                    @change="updateYachtData"
                  />
                </div>
                <div class="columns">
                  <FormItem
                    name="cruis_spd"
                    :value="yacht.cruis_spd"
                    label="Cruising speed"
                    type="number"
                    :read-only="isReadOnly"
                    unit="kt"
                    required
                    @change="updateYachtData"
                  />
                  <FormItem
                    name="cruis_spd_cons"
                    :value="yacht.cruis_spd_cons"
                    label="Consumption"
                    type="number"
                    :read-only="isReadOnly"
                    unit="L/h"
                    required
                    @change="updateYachtData"
                  />
                </div>
                <div class="columns">
                  <FormItem
                    name="eco_spd"
                    :value="yacht.eco_spd"
                    label="Eco speed"
                    type="number"
                    :read-only="isReadOnly"
                    unit="kt"
                    @change="updateYachtData"
                  />
                  <FormItem
                    name="eco_spd_cons"
                    :value="yacht.eco_spd_cons"
                    label="Consumption"
                    type="number"
                    :read-only="isReadOnly"
                    unit="L/h"
                    @change="updateYachtData"
                  />
                </div>
                <div class="columns">
                  <FormItem
                    name="avg_generators_cons"
                    :value="yacht.avg_generators_cons"
                    label="Average generator fuel consumption"
                    type="number"
                    :read-only="isReadOnly"
                    placeholder="15"
                    :min="0"
                    unit="L/h"
                    @change="updateYachtData"
                  />
                </div>
              </div>
              <div v-if="consumptionPlotSrc" class="column is-2 is-hidden-mobile">
                <figure data-test="consumption-plot" class="image is-square">
                  <img :src="consumptionPlotSrc" alt="Consumption over speed" />
                </figure>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>Identification</legend>
            <div class="columns">
              <FormItem
                name="mmsi_num"
                :value="yacht.mmsi_num"
                label="MMSI"
                type="number"
                :read-only="isReadOnly"
                @change="updateYachtData"
              />
              <FormItem
                name="call_sign"
                :value="yacht.call_sign"
                :read-only="isReadOnly"
                @change="updateYachtData"
              />
              <FormItem
                name="official_num"
                :value="yacht.official_num"
                label="Official number"
                :read-only="isReadOnly"
                required
                @change="updateYachtData"
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>Shipyard</legend>
            <div class="columns">
              <FormItem
                name="year_built"
                :value="yacht.year_built"
                label="Keel laying"
                type="number"
                :read-only="isReadOnly"
                :max= "new Date().getFullYear()"
                @change="updateYachtData"
              />
              <FormItem
                name="yard"
                :value="yacht.yard"
                label="At shipyard"
                :read-only="isReadOnly"
                @change="updateYachtData"
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>Dimensions</legend>
            <div class="columns">
              <FormItem
                name="gross_tonnage"
                :value="yacht.gross_tonnage"
                label="GT"
                placeholder="Gross Tonnage"
                type="number"
                :read-only="isReadOnly"
                @change="updateYachtData"
              />
              <FormItem
                name="displacement"
                :value="yacht.displacement"
                type="number"
                :read-only="isReadOnly"
                unit="t"
                @change="updateYachtData"
              />
            </div>
            <div class="columns">
              <FormItem
                name="beam"
                :value="yacht.beam"
                label="Breadth"
                type="number"
                :read-only="isReadOnly"
                unit="m"
                @change="updateYachtData"
              />
              <FormItem
                name="draft"
                :value="yacht.draft"
                label="Draught"
                type="number"
                :read-only="isReadOnly"
                unit="m"
                @change="updateYachtData"
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>Cruising restrictions</legend>
            <div class="columns">
              <FormItem
                name="max_nb_passengers"
                :value="yacht.max_nb_passengers"
                label="Max. navigating passengers"
                type="number"
                :read-only="isReadOnly"
                :min="1"
                :max="12"
                required
                @change="updateYachtData"
              />
              <FormItem
                name="nb_berth"
                :value="yacht.nb_berth"
                label="Max. passengers with berth"
                type="number"
                :read-only="isReadOnly"
                :min="1"
                :max="12"
                required
                @change="updateYachtData"
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>Fuel</legend>
            <div class="columns">
              <FormItem
                name="fuel_capacity"
                :value="yacht.fuel_capacity"
                type="number"
                :read-only="isReadOnly"
                :min="1"
                unit="L"
                @change="updateYachtData"
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>Engine</legend>
            <div class="columns">
              <FormItem
                name="engine_type"
                :value="yacht.engine_type"
                :read-only="isReadOnly"
                @change="updateYachtData"
              />
              <FormItem
                name="engine_quantity"
                :value="yacht.engine_quantity"
                placeholder="Number of engines"
                :read-only="isReadOnly"
                type="number"
                :min="1"
                @change="updateYachtData"
              />
              <FormItem
                name="total_power"
                label="Power"
                :value="yacht.total_power"
                :read-only="isReadOnly"
                type="number"
                unit="kW"
                @change="updateYachtData"
              />
            </div>
          </fieldset>
          <div class="columns is-centered">
            <div v-if="!showDetails" class="column has-text-centered">
              <button type="button" class="button is-primary is-outlined" @click="areDetailsShown = false">Hide detailed info</button>
            </div>
            <div v-if="!isEmbedded && !isReadOnly" class="column has-text-centered">
              <button type="button" class="button is-danger" @click="deleteYacht">Delete yacht</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<script>

import Actions from '@/store/actions'
import { serverURL } from '@/api/config' // eslint-disable-line
import axios from '@/api/config' // eslint-disable-line

export default {
  name: 'YachtDetailForm',
  components: {
  },
  filters: {
    shortenFilename (filename) {
      const n = 18
      return (filename.length > n) ? filename.substr(0, n - 1) + '…' : filename
    }
  },
  props: {
    yachtId: {
      type: Number,
      required: true
    },
    yachtVersion: {
      type: Number,
      required: false,
      default: undefined
    },
    cruiseReadOnly: {
      type: Boolean,
      default: false
    },
    showDetails: {
      type: Boolean,
      default: false
    },
    isEmbedded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      maxFileSize: undefined,
      areDetailsShown: this.showDetails,
      uploadFiles: [],
      consumptionPlotSrc: null,
    }
  },
  mounted () {
    this.maxFileSize = process.env.VUE_APP_REST_MAX_UPLOAD_SIZE || 3
  },
  computed: {
    isReadOnly () {
      return this.isLoading || this.cruiseReadOnly || !this.yacht || !this.yacht.write_access || this.yacht.locked
    },
    isLoading () {
      return !this.yacht || this.$store.state.yacht.loading
    },
    isUploading () { return this.$store.state.yacht.uploading },
    yacht () { return this.$store.getters.yacht(this.yachtId, this.yachtVersion) },
    isValidSpeed () {
      return (this.yacht && this.yacht.max_spd && this.yacht.max_spd_cons && this.yacht.cruis_spd && this.yacht.cruis_spd_cons)
    }
  },
  watch: {
    yachtId (newYacht, oldYacht) {
      this.loadYacht()
    },
    yachtVersion (newYacht, oldYacht) {
      this.loadYacht()
    }
  },
  created () {
    this.$store.dispatch(Actions.GET_COUNTRIES)
      .then(this.loadYacht)
  },
  methods: {
    loadYacht () {
      this.consumptionPlotSrc = null
      this.$store.dispatch(Actions.GET_YACHT, {
        yachtId: this.yachtId,
        yachtVersion: this.yachtVersion
      })
        .then(() => {
          this.getConsumptionPlotImg()
        })
    },
    updateYachtData (yachtVar, val) {
      this.$store.dispatch(Actions.UPDATE_YACHT, {
        yachtId: this.yachtId,
        yachtVersion: this.yachtVersion,
        yachtData: { [yachtVar]: val }
      })
        .then(() => {
          if (yachtVar.endsWith('_cons') || yachtVar.endsWith('_spd')) {
            this.getConsumptionPlotImg()
          }
        })
    },
    deleteYacht () {
      this.$buefy.dialog.confirm({
        title: 'Deleting yacht',
        message: `Are you sure you want to <b>delete</b> yacht <i>${this.yacht.name}</i>?<br/>This action cannot be undone.`,
        confirmText: 'Delete Yacht',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch(Actions.DELETE_YACHT, this.yachtId)
            .then(() => {
              this.$router.push({ name: 'YachtList' })
            })
        }
      })
    },
    deletePicture (pictureIdx) {
      this.$buefy.dialog.confirm({
        title: 'Deleting yacht picture',
        message: 'Are you sure you want to <b>delete</b> this picture?',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch(Actions.DELETE_YACHT_PICTURE, { yachtId: this.yacht.id, pictureIdx: pictureIdx })
        }
      })
    },
    getImageUrl (url) {
      return url ? (serverURL + url) : require('@/assets/img/yacht/empty.png')
    },
    getConsumptionPlotImg (yachtId) {
      if (!this.isValidSpeed) {
        this.consumptionPlotSrc = null
        return
      }
      var self = this
      axios.get(`/yacht/${this.yacht.id}/${this.yacht.version}/speed_consumption.png`, { responseType: 'blob' })
        .then((response) => {
          var reader = new FileReader()
          reader.onloadend = function () {
            self.consumptionPlotSrc = reader.result
          }
          reader.readAsDataURL(response.data)
        })
        .catch(() => {
          self.consumptionPlotSrc = null
        })
    },
    startUpload () {
      const maxSize = this.maxFileSize * 1024 * 1024
      if (this.uploadFiles[0].size <= maxSize) {
        this.$store.dispatch(Actions.UPLOAD_YACHT_PICTURE, { yachtId: this.yacht.id, pictureData: this.uploadFiles })
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: `File size should not exceed ${this.maxFileSize}MB`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
      this.uploadFiles = []
    },
    removeFileUpload (idx) {
      this.uploadFiles.splice(idx, 1)
    }
  }
}
</script>
<style lang="scss">
.yachtdetail{
  .columns {
    margin-bottom: 0 !important;
    @include mobile {
      margin-bottom: 1rem !important;
    }
  }

  .section {
    padding: 1rem 0.5rem;
  }
  .upload-area {
    width:120px;
    height:120px;
    padding-top: 25px;
    @include mobile {
      width:100px;
      height:100px;
      padding-top: 15px;
    }

    h4 {
      font-size: 1rem !important;
      margin-bottom: 0;
    }

    p.upload {
      font-size: small;
    }
  }

  .upload-area:hover p.upload {
    display: none;
  }
  .upload-area h4.hover, .upload-area:hover h4.upload {
    display: none;
  }
  .upload-area:hover h4.hover {
    display: block;
  }

  .images {
    .frame {
      border: 2px #dbdbdb solid;
      margin: 2px;
      padding: 2px;
      line-height:0;
      position: relative;
      width: fit-content;
      .delete-btn {
        position: absolute;
        bottom: 2em;
        left: 50%;
        transform: translateX(-50%);
        display: none;
      }
      img {
        width: auto;
        height: auto;
        max-height: 200px;
        max-width: 200px;
        @include tablet-only {
          max-height:120px;
        }
        @include mobile {
          max-height:100px;
        }
      }
    }
    .frame:hover {
      img {
        opacity: 0.5;
      }
      .delete-btn {
        display: block;
      }
    }
    .frame.disabled:hover img {
      opacity: 1.0;
    }
  }
}
</style>
