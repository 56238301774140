var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'button',
    'is-primary',
    { 'is-loading': _vm.isLoading },
    { 'has-background-grey-light': _vm.isDisabled },
    { 'has-text-light': _vm.isDisabled }
  ],attrs:{"type":"submit","disabled":_vm.isDisabled}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }