<template>
  <section class="section">
    <div class="container">
      <nav class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <BackButton @click.native="onClickBack" />
          </div>
        </div>
        <div class="level-right">
        </div>
      </nav>
      <div class="section-box">
        <yacht-detail-form :yacht-id="id" :show-details="true" />
      </div>
    </div>
  </section>
</template>

<script>
import YachtDetailForm from '@/components/forms/yacht/YachtDetailForm'

export default {
  name: 'YachtDetail',
  components: {
    YachtDetailForm,
  },
  props: {
    id: {
      required: true
    }
  },
  methods: {
    onClickBack () {
      this.$router.push({ name: 'YachtList' })
    }
  }
}
</script>
