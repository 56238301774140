<template>
  <section class="section">
    <div class="container">
      <nav class="level is-mobile">
        <div class="level-left" />
        <div class="level-right">
          <div class="level-item">
            <CreateButton @click.native="isYachtCreationModalShown = true">Add Yacht</CreateButton>
          </div>
        </div>
      </nav>
      <div v-if="yachts.length" id="YachtList" class="container section-box">
        <div v-for="yacht in yachts" :key="yacht.id" class="columns wg-card">
          <div class="column columns is-vcentered">
            <div class="column">
              <h1 class="title">{{yacht.name}}</h1>
              <div>
                <router-link  class="detail-btn-wrapper" :to="{name: 'YachtDetail', params: {id: yacht.id }, props: true}">
                  <button class="button is-primary is-outlined detail-btn">Detailed info</button>
                </router-link>
              </div>
            </div>
            <div class="column">
              <div class="card-yachtdetails">
                <div v-if="yacht.flag" class="card-sub-details"><span class="caption">Flag:</span> {{yacht.flag.name}}</div>
                <div v-if="yacht.port_of_registry" class="card-sub-details"><span class="caption">Port of Registry:</span> {{yacht.port_of_registry}}</div>
                <div v-if="yacht.loa" class="card-sub-details"><span class="caption">Length over all:</span> {{yacht.loa}}m</div>
              </div>
            </div>
           </div>
          <div class="column is-two-fifths has-text-right">
            <img v-for="(picture,i) in yacht.pictures.slice(0,2)" :key="i" :src="getImageUrl(picture.public_url)" />
            <img v-if="yacht.pictures.length==0" :src="getImageUrl(false)" />
          </div>
        </div>
      </div>
      <Loading v-else-if="isLoading" />
    </div>
    <b-modal
      :active.sync="isYachtCreationModalShown"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="min-width: 300px;">
        <YachtCreateForm
          @yacht-created="handleYachtCreated($event)"
        />
      </div>
    </b-modal>
  </section>
</template>

<script>
import { serverURL } from '../api/config'
import Actions from '@/store/actions'
import YachtCreateForm from '@/components/forms/yacht/YachtCreateForm'

export default {
  name: 'YachtList',
  components: {
    YachtCreateForm,
  },
  data () {
    return {
      isYachtCreationModalShown: false,
    }
  },
  computed: {
    yachts () { return this.$store.state.yacht.yachts },
    isLoading () { return this.$store.state.yacht.loading }
  },
  created () {
    this.$store.dispatch(Actions.GET_USER_YACHTS)
  },
  methods: {
    createYacht () {
      this.$router.push({ name: 'YachtCreate' })
    },
    getImageUrl (url) {
      if (url) {
        return serverURL + url
      } else {
        return require('@/assets/img/yacht/empty.png')
      }
    },
    handleYachtCreated (yacht) {
      this.$router.push({ name: 'YachtDetail', params: { id: yacht.id } })
    }
  }
}
</script>
<style lang="scss" scoped>

.wg-card {
  border: 2px #dbdbdb solid;
  padding: 4px 2px;
  margin-bottom: 2rem !important;

  .title {
    font-weight: 600;
    font-size: 1.1rem;
    color: #4a4a4a;
  }

  .detail-btn-wrapper {
    text-align: center;
    display:block;
  }

  @include touch {
    margin-bottom: 1.5rem !important;

    .column {
      position: relative;
    }
    .title {
      margin-bottom: 2rem;
      // padding-right: 140px;
    }
  }
  .caption {
    color: #AAA;
  }

  // TODO: merge above and below

  img {
    width:auto;
    height:auto;
    max-height:200px;
    max-width:200px;
    border: 2px #dbdbdb solid;
    @include mobile {
      max-height:100px;
      border: 1px #dbdbdb solid;
    }
    @include tablet-only {
      max-height:120px;
    }
    @include desktop-only {
      max-height:150px;
    }
    padding: 2px;
    margin-left: 2px;
    margin-right: 2px;
  }

  @include touch {
    button.detail-btn {
      @include mobile {
        height: 26px;
        padding-top: 1px;
        width: 120px;
        position: absolute;
        right: 6px;
        top: 5px;

      }
    }
  }
}
</style>
