<template>
  <div>
    <span v-if="isSent" class="level-item has-text-grey">Your password has been changed.</span>
    <form
      v-if="!isSent"
      ref="form"
      autocomplete="on"
      @submit.prevent="recovery"
    >
      <legend>Change Password</legend>
      <b-field
        label="New Password"
      >
        <b-input
          v-model="new_password"
          type="password"
          autocomplete="password"
          placeholder="must be at least 8 characters"
          password-reveal
          min-length="8"
          required
        />
      </b-field>
      <b-field
        label="Confirm New Password"
        :type="notSamePassword ? 'is-danger' : null"
        :message="notSamePassword ? 'Password and confirmation password must match' : ''"
      >
        <b-input
          v-model="new_password_confirm"
          type="password"
          autocomplete="password"
          data-test="confirm"
          placeholder="must be at least 8 characters"
          password-reveal
          min-length="8"
          required
        />
      </b-field>
      <b-field>
        <SubmitButton
          :is-loading="isSending"
          :is-disabled="($refs.form && !$refs.form.checkValidity()) || notSamePassword || isSending"
        >
          Change Password
        </SubmitButton>
      </b-field>
    </form>
  </div>
</template>

<script>

import Actions from '@/store/actions'

export default {
  name: 'RecoveryForm',
  props: {
    recoveryToken: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      new_password: '',
      new_password_confirm: '',
      isSending: false,
      isSent: false,
    }
  },
  computed: {
    notSamePassword () {
      return (this.new_password_confirm.length > 0) && (this.new_password !== this.new_password_confirm)
    }
  },
  methods: {
    recovery () {
      this.isSending = true
      this.$store.dispatch(Actions.RESET_PASSWORD, {
        new_password: this.new_password,
        recovery_token: this.recoveryToken
      })
        .then(() => {
          this.$emit('login-success')
        })
        .catch(() => {})
        .finally(() => {
          this.isSending = false
        })
    }
  }
}
</script>
