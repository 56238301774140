<template>
  <div>
    <role-form
      role="clients"
      role-friendly-name-singular="client"
      role-friendly-name-plural="clients"
      multiple
      :agreement-id="agreementId"
    />
    <hr/>
    <role-form
      role="passengers"
      role-friendly-name-singular="passenger"
      role-friendly-name-plural="passengers"
      multiple
      :agreement-id="agreementId"
    />
  </div>
</template>

<script>
import RoleForm from '@/components/forms/cruise/RoleForm'

export default {
  name: 'ClientsSection',
  components: {
    RoleForm
  },
  props: ['agreementId']
}
</script>
