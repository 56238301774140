<template>
  <div>
    <nav
      v-if="!cruiseReadOnly"
      class="level is-mobile"
    >
      <div class="level-left" />
      <div class="level-right">
        <div class="level-item">
          <CreateButton @click.native="createService">
            Add Service
          </CreateButton>
        </div>
      </div>
    </nav>
    <Loading v-if="isLoading" />
    <div
      v-else
      id="ServicesList"
    >
      <div
        v-if="services.length==0"
        class="message is-dark"
      >
        <div class="message-body">
          No additional services have been set.
        </div>
      </div>
      <div
        v-for="service in services"
        :key="service.id"
        class="card"
      >
        <div class="card-content">
          <form @change="saveService(service)">
            <div class="columns">
              <div
                v-if="!cruiseReadOnly && !service.disabled"
                class="column is-narrow"
                style="padding-right: 0;"
              >
                <b-button
                  icon-left="trash-alt"
                  class="mouseover-danger"
                  pack="fas"
                  type="is-text"
                  @click="deleteService(service)"
                />
              </div>
              <div class="column is-two-thirds">
                <b-input
                  v-model="service.name"
                  type="text"
                  placeholder="Name"
                  :disabled="cruiseReadOnly || service.disabled"
                />
              </div>
              <div class="column">
                <b-field has-addons>
                  <p class="control">
                    <span class="button is-static">
                      {{ currency }}
                    </span>
                  </p>
                  <p class="control is-expanded">
                    <b-input
                      v-model="service.price"
                      placeholder="Price"
                      :disabled="cruiseReadOnly || service.disabled"
                      type="number"
                      step="0.01"
                      required
                    />
                  </p>
                </b-field>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <textarea
                  v-model="service.product"
                  class="textarea"
                  placeholder="Product description"
                  :disabled="cruiseReadOnly || service.disabled"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {
  getServices,
  putService,
  deleteService,
  postService
} from '@/api/services'

export default {
  name: 'ServicesSection',
  components: {
  },
  data () {
    return {
      services: [],
      isLoading: true
    }
  },
  computed: {
    cruiseReadOnly () {
      return this.$store.getters.cruiseReadOnly
    },
    currency () {
      return this.$store.state.cruise.cruise.currency
    }
  },
  created () {
    this.isLoading = true
    getServices(this.$store.state.cruise.cruise.id)
      .then(({ data }) => {
        this.services = data.map(s => { s.disabled = false; return s })
      })
      .finally(() => { this.isLoading = false })
  },
  // watch: {
  //   'services': function (oldVal, newVal) {
  //     this.scrollToEnd()
  //   }
  // },
  methods: {
    createService () {
      postService(this.$store.state.cruise.cruise.id, {})
        .then(({ data }) => {
          this.services = data.map(s => { s.disabled = false; return s })
          this.scrollToEnd()
        })
        .catch(() => {})
    },
    saveService (service) {
      putService(this.$store.state.cruise.cruise.id, service.id, {
        name: service.name === '' ? null : service.name,
        price: service.price === '' ? null : service.price,
        product: service.product === '' ? null : service.product
      })
        .then(({ data }) => {
          this.services = data.map(s => { s.disabled = false; return s })
        })
        .catch(() => {})
    },
    deleteService (service) {
      if (confirm('Delete service?')) {
        service.disabled = true
        deleteService(this.$store.state.cruise.cruise.id, service.id)
          .then(({ data }) => {
            this.services = data.map(s => { s.disabled = false; return s })
          })
          .catch(() => {})
      }
    },
    scrollToEnd: function () {
      setTimeout(function () {
        document.body.scrollTop = document.body.scrollHeight
      }, 10)
    }
  }
}
</script>
<style lang="scss" scoped>
#ServicesList .card {
  .card-content {
    padding: 0.75rem;
    @include touch {
      padding: 0.5rem;
    }
  }

  margin-bottom: 1.5rem;
}
</style>
