<template>
  <div class="signature">
    <b-tooltip v-if="isUserSignature" :active="signature.status_msg && signature.status_msg != ''" :label="signature.status_msg">
      <b-taglist attached>
        <b-tag size="is-medium" type="is-dark"><b>You</b> ({{ signatories }})</b-tag>
        <template v-if="signature.status=='NEW'">
          <b-tag size="is-medium" type="is-info">Starting</b-tag>
          <b-tag size="is-medium" type="is-info"><b-icon icon="spinner" class="fa-pulse" size="is-small" /></b-tag>
        </template>
        <template v-else-if="signature.status=='PREPARING'">
          <b-tag size="is-medium" type="is-info">Preparing for signature</b-tag>
          <b-tag size="is-medium" type="is-info"><b-icon icon="spinner" class="fa-pulse" size="is-small" /></b-tag>
        </template>
        <template v-else-if="signature.status=='UPLOADING'">
          <b-tag size="is-medium" type="is-info">Uploading contract</b-tag>
          <b-tag size="is-medium" type="is-info"><b-icon icon="spinner" class="fa-pulse" size="is-small" /></b-tag>
        </template>
        <template v-else-if="signature.status=='WAITING'">
          <b-tag size="is-medium" type="is-warning">Please sign on your device</b-tag>
          <b-tag size="is-medium" type="is-warning"><b-icon icon="mobile-alt" class="pulse" size="is-small" /></b-tag>
        </template>
        <template v-else-if="signature.status=='RETRIEVING'">
          <b-tag size="is-medium" type="is-info">Retrieving signed contract</b-tag>
          <b-tag size="is-medium" type="is-info"><b-icon icon="spinner" class="fa-pulse" size="is-small" /></b-tag>
        </template>
        <b-tag v-else-if="signature.status=='FAILED'" size="is-medium" type="is-danger">Signature Failed</b-tag>
        <template  v-else-if="signature.status=='SIGNED'">
          <b-tag size="is-medium" type="is-success">Signed</b-tag>
          <b-tag size="is-medium" type="is-success"><a @click="downloadPdf"><b-icon icon="file" /></a></b-tag>
        </template>
      </b-taglist>
    </b-tooltip>
    <b-taglist v-else attached>
      <b-tag size="is-medium" type="is-light">{{ signatories }}</b-tag>
      <b-tag v-if="['NEW', 'PREPARING', 'UPLOADING'].includes(signature.status)" size="is-medium" type="is-info">Signing in progress…</b-tag>
      <b-tag v-else-if="signature.status=='WAITING'" size="is-medium" type="is-warning">Awaiting user signature…</b-tag>
      <b-tag v-else-if="signature.status=='RETRIEVING'" size="is-medium" type="is-info">Retrieving signed contract…</b-tag>
      <b-tag v-else-if="signature.status=='FAILED'" size="is-medium" type="is-danger">Signature Failed</b-tag>
      <template v-else-if="signature.status=='SIGNED'">
        <b-tag size="is-medium" type="is-success">Signed</b-tag>
        <b-tag size="is-medium" type="is-success"><a @click="downloadPdf"><b-icon icon="file" /></a></b-tag>
      </template>
    </b-taglist>
  </div>
</template>
<script>
import { getSignedContractPdf } from '@/api/contract'

export default {
  name: 'ContractSignature',
  props: {
    signature: {
      type: Object,
      required: true,
    },
    canSign: {
      type: Boolean,
      required: false,
      default: false
    },
    isSigning: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data () {
    return {
      signedPdfURL: false,
      waitUntil: null,
    }
  },
  computed: {
    isUserSignature () {
      // return false
      return this.signature.roles.some(r => this.$store.getters.userProfileIds.includes(r.profile.id))
    },
    signatories () {
      return [...new Set(this.signature.roles.map(r => r.profile.full_name))].join(', ')
    }
  },
  watch: {
    signature: function (newVal, oldVal) {
      if (newVal && oldVal.status !== 'SIGNED') {
        console.log('ContractSignature signature has changed', oldVal, newVal)
        this.scheduleRefresh(newVal.status)
      }
    }
  },
  created () {
    this.scheduleRefresh(this.signature.status)
  },
  methods: {
    downloadPdf () {
      getSignedContractPdf({ cruiseId: this.signature.cruise.id, cruiseVersion: this.signature.cruise.version, docId: this.signature.claimed_document_id })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `Wolfgang_Contract_${this.signature.cruise.id.toString().padStart(4, '0')}_Signed_by_${this.signature.roles[0].profile.initials}.pdf`
          link.click()
        })
    },
    scheduleRefresh (status) {
      if (this.waitUntil && this.waitUntil > Date.now()) return
      this.waitUntil = new Date(Date.now() + 1000)

      if (this.isSigning) {
        console.log('TIMER scheduleRefresh isSigning')
        setTimeout(() => { this.$emit('update-signatures') }, 1 * 3000)
      } else if (status === 'WAITING') {
        console.log('TIMER scheduleRefresh WAITING')
        setTimeout(() => { this.$emit('update-signatures') }, 1 * 5000)
      } else if (!['FAILED', 'SIGNED'].includes(status)) {
        console.log('TIMER scheduleRefresh', status)
        setTimeout(() => { this.$emit('update-signatures') }, 1 * 10000)
      }
    }
  },
}
</script>
<style lang="scss" scoped>

.signature {
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.signature a {
  color: white;
  cursor: pointer;
}

.signature a:hover {
  color: #777;
}

.signature .tags:not(:last-child), .tags:last-child {
  margin-bottom: 0 !important;
}

.signature .tags:last-child {
  margin-bottom: 0 !important;
}

@keyframes animpulse {
  0% {  transform: scale(0.85); }
  50% {  transform: scale(1.1); }
  100% {  transform: scale(0.85); }
}
@-webkit-keyframes animpulse {
  0% {  -webkit-transform: scale(0.85); }
  50% {  -webkit-transform: scale(1.1); }
  100% {  -webkit-transform: scale(0.85); }
}

.pulse {
  animation: animpulse 1.2s ease-out infinite;
  -webkit-animation: animpulse 1.2s ease-out infinite;
}

</style>
