<template>
  <div id="app">
    <template v-if="$store.getters.isLoggedIn">
      <nav-bar/>
      <section v-if="$store.state.error.currentError" class="container error-container">
        <RequestError :error="$store.state.error.currentError"/>
      </section>
    </template>
    <router-view class="main"/>
    <!-- <footer class="footer">
      <div class="content has-text-centered">
        ©{{ currentYear }} Wolfgang — <a href="/tos">Terms and Conditions</a> — <router-link :to="{name: 'FAQ'}">FAQ</router-link>
      </div>
    </footer> -->
    <PaymentModal v-if="this.$store.state.user.user" />
  </div>
</template>
<script>
import NavBar from './components/NavBar'
import Actions from '@/store/actions'
import PaymentModal from '@/components/PaymentModal'

export default {
  name: 'App',
  components: {
    NavBar,
    PaymentModal
  },
  computed: {
    currentYear: () => new Date().getFullYear(),
  },
  watch: {
    $route (to, from) {
      this.$store.dispatch(Actions.RESET_ERROR)
    }
  },
}
</script>

<style lang="scss">

// @import "./styles/base.scss";
@import "~bulma";
@import "~buefy/src/scss/buefy";

#app {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

.main {
  flex: 1;
}

</style>
