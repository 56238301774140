<template>
  <section class="section">
    <header v-if="!isLoggedIn" class="header">
      <div class="header__content">
        <div class="header__logo">
          <img src="../assets/img/home/logo_name@1x.svg" width="180">
        </div>
        <div class="header__buttons">
          <router-link class="control button is-outlined is-link is-inverted is-medium" :to="{name: 'Login'}">Log in</router-link>
          <router-link class="control button is-link is-inverted is-medium" :to="{name: 'Registration'}">Sign up</router-link>
        </div>
      </div>
    </header>
    <div class="container section-box content">
      <h2 class="title" style="margin-bottom:2em;">Frequently Asked Questions</h2>
      <h3 class="subtitle"><a href="https://wolfgang.pro" title="Wolfgang - The Charter Supervision Software">Wolfgang.pro</a> contract app</h3>
      <ul>
        <li><strong>How are the calculations for fuel and vat pro-rata done?</strong><br/>
          The fuel is calculated based on the consumption curve that Wolfgang establishes for each yacht.<br/>
          The VAT pro-rata is calculated based on the distance travelled by each separate passenger through each country and international waters.
        </li>
        <li><strong>What if the actual fuel consumption differs at the end of the cruise?</strong><br/>
          When doing the contract settlement, the Captain enters the actual readings for consumption. Those readings take precedence over the estimate, and an adjustment is made to the final price.
        </li>
        <li><strong>Are the changes of itinerary unlimited?</strong><br/>
          Yes, you can change the itinerary as many times as you need. There is a certain number of addendum generations included in the initial price (please refer to <a href="https://wolfgang.pro/tos">ToS</a> for details) that you can extend if required.
        </li>
        <li><strong>Who can make a change to the draft?</strong><br/>
          All the professionals invited to a collaboration can participate in the elaboration of the contract. The client and passengers can only change their personal information. To manage and track changes, please refer to the use of the “approve” and “generate” locks.
        </li>
        <li><strong>Can I add an attorney, a manager or another user to the collaboration?</strong><br/>
          Yes, you can. There are a set of neutral roles that can be added to a collaboration.
        </li>
      </ul>
      <h3 class="subtitle">Cruise contract standard and special terms</h3>
      <ul>
        <li><strong>Can a stop at anchor be considered a “Call”?</strong><br/>
          Yes. Only short technical stops are usually excluded. Any stop in port or at anchor, that is long enough to allow a passenger, if they wish to, to disembark, will qualify as a “Call”.
        </li>
        <li><strong>What is the country of arbitration for the contract?</strong><br/>
          Default country of arbitration is France but the contract explicitly mentions that you can choose a country that fits better to the parties’ needs. In that case, just insert the clause in the “special conditions” tab.
        </li>
        <li><strong>In what languages is the contract available in?</strong><br/>
          The contract is available in French and translated to English. The French version has legal precedence.
        </li>
        <li><strong>Are crew changes to be tracked?</strong><br/>
          No, crew changes do not affect the contract.
        </li>
      </ul>
      <h3 class="subtitle">Special clauses</h3>
      <ul>
        <li><strong>Can I add a special clause for Covid cancellation or other?</strong><br/>
          Yes, you can add any specific clause in the “special conditions” tab of your contract.
        </li>
      </ul>
      <h3 class="subtitle">Brokers agreement</h3>
      <ul>
        <li><strong>How do you guarantee good practice between brokers and agents?</strong><br/>
          Wolfgang provides a broker’s agreement together with the main cruise agreement for brokers and agents to sign.
        </li>
      </ul>
      <h3 class="subtitle"><a href="https://wolfgang.pro" title="Wolfgang - The Charter Supervision Software">Wolfgang</a>’s Confidentiality - RGPD</h3>
      <ul>
        <li><strong>Does Wolfgang keep my agreements confidential? Is my data protected?</strong><br/>
          YES and YES. Data privacy is one of the fundamentals of Wolfgang. Your data is protected and only accessible to you and those you share it with. Wolfgang is designed to help you control how much or how little information you share, and where it goes.  Wolfgang strictly adheres to the rules of the European RGPD. It is your legal guarantee that we cannot use any data without your consent. Please refer to the <a href="https://wolfgang.pro/tos">ToS</a> for more details.
        </li>
        <li><strong>Where is my data stored?</strong><br/>
          Our servers are located in Germany. The servers that process the contract authentications and signatures are located in the Netherlands.
        </li>
        <li><strong>Are the contracts removed from the server at the end of the cruise or when it is archived?</strong><br/>
        The cruise draft can be deleted but signed contracts are kept on the server so as to be opposable by all signatories.
        </li>
      </ul>
      <h3 class="subtitle">Signatures and Authentications</h3>
      <ul>
        <li><strong>Who signs the contract?</strong><br/>
          The signatories of the contract are: the owner/carrier, the stakeholder, the broker, the client. Any one of them can delegate their signature to an authorized person that will sign on their behalf.
        </li>
        <li><strong>Does the name of the signatory appear in the contract?</strong><br/>
          Yes, for the legal validity of the contract and the security of the authentication, the actual name of the signatory will appear. If you are acting on behalf of your company, this will be mentioned in the contract.
        </li>
        <li><strong>Do we sign all pages of the contract?</strong><br/>
          The digital signature encompasses the whole document, that is: the special terms and the standard terms.
        </li>
        <li><strong>Who signs first? How are signatures traced?</strong><br/>
          Once the contract is generated, it is available for signature to all parties at the same time. The signature stamp includes the name of the signatory, the time, date and country of the signature.
        </li>
        <li><strong>Can I print and sign a paper version?</strong><br/>
          Yes. Once the contract is generated, you can access a PDF version for the signature. For legal reasons, those signing electronically must do so first.
        </li>
      </ul>
      <h3 class="subtitle">Travel agents - Tourism Code - Athens Convention - Financial guarantees</h3>
      <ul>
        <li><strong>Am I required to be a travel agent to use the cruise contract?</strong><br/>
          The regulatory requirements to use the cruise contract are the same as those to use the charter agreements. No more no less. They all depend on the country where your company is registered.
        </li>
        <li><strong>Must the owning company/carrier have a financial guarantee?</strong><br/>
          The Carrier in the contract, owner of the yacht, is not required to have an additional financial guarantee. Their usual insurance covers the service they provide.
        </li>
      </ul>
      <h3 class="subtitle">Pricing - Invoicing</h3>
      <ul>
        <li><strong>How much does <a href="https://wolfgang.pro" title="Wolfgang - The Charter Supervision Software">Wolfgang</a>’s service cost?</strong><br/>
          Our pricing policy is described in the <a href="https://wolfgang.pro/tos">terms of service</a>. Wolfgang charges a fee per contract when, and only when, the client is ready to sign the contract. If the cruise doesn’t happen, using Wolfgang doesn’t cost anything.
        </li>
        <li><strong>Who pays for <a href="https://wolfgang.pro" title="Wolfgang - The Charter Supervision Software">Wolfgang</a>’s fees?</strong><br/>
          The user who generates the contract is asked to provide payments details. Any user can proceed to payment but we recommend that either the broker or the central agent do so. Fees paid by the professional can then be added to the contract as an additional paid service and invoiced to the final client.
        </li>
        <li><strong>If the contract is cancelled, can I get a refund?</strong><br/>
          Refunds for cancelled contracts are not automatic. Please contact us for assistance.
        </li>
        <li><strong>Can I pay via wire transfer?</strong><br/>
          Default payment is only via credit card.
        </li>
      </ul>
      <h3 class="subtitle">Trainings - Webinar</h3>
      <ul>
        <li><strong>Do you organise webinars or training?</strong><br/>
          Yes we do. From one person, face to face, to your whole team via the internet. Please contact us to inquire about our options.
        </li>
      </ul>
      <h3 class="subtitle"><a href="https://wolfgang.pro" title="Wolfgang - The Charter Supervision Software">Wolfgang SAS</a> - the Company</h3>
      <ul>
        <li><strong>Who is Wolfgang?</strong><br/>
          Wolfgang was founded by Anthony Flores and Matthias du Verle, two former captains from the merchant navy turned yacht captains. We both operate on private superyachts. Our third associate is David du Verle, PhD in Bioinformatics: he is the brain behind Wolfgang’s tools and AI that make your life easier!
        </li>
        <li><strong>Is Wolfgang partnered with another company?</strong><br/>
          Wolfgang is financially and commercially independent from any other company. We believe that this service can only be rendered by a neutral platform that allows all professionals to collaborate.
        </li>
      </ul>
    </div>
  </section>
</template>

<script>

export default {
  name: 'FAQ',
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
  }
}

</script>
<style lang="scss" scoped>
  $headerHeight: 80px;

  .header {
    display: flex;
    justify-content: center;

    height: $headerHeight;
    width: 100%;

    padding: 1rem;
    background-image: linear-gradient(to bottom right, #1BADD9, #010A4F);

    &__content {
      display: flex;
      justify-content: space-between;
      flex-flow: row nowrap;
      width: 100%;
      max-width: $fullhd;
    }

    &__logo {
      display: inline-flex;
      align-items: center;
      flex: 1 0 auto;
    }

    &__buttons {
      display: flex;
      flex-flow: nowrap;
      flex: 0;

      & > *:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
</style>
