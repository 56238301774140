import Vue from 'vue'
import axios from '@/api/config'
import Actions from '@/store/actions'

const AUTH_PREFIX = '/user'

const AUTH_REQUEST = 'μ: Sent auth request'
const AUTH_SUCCESS = 'μ: Auth succeeded'
const AUTH_ERROR = 'μ: Auth error'
const LOGOUT = 'μ: Log out'
const SET_USER = 'μ: Update user'
const SET_USER_PROFILES = 'μ: Set user profiles'
const SET_USER_PROFILE_BY_ID = 'μ: Set user profile by ID'
const DELETE_USER_PROFILE = 'μ: Delete single user profile'

const userModule = {
  state: {
    status: '',
    token: (localStorage.getItem('jwt_token') || ''),
    user: false
  },
  mutations: {
    [AUTH_REQUEST] (state) {
      state.status = 'loading'
    },
    [AUTH_SUCCESS] (state, { token, user }) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    [AUTH_ERROR] (state) {
      state.status = 'error'
    },
    [LOGOUT] (state) {
      state.status = ''
      state.token = ''
      state.user = false
    },
    [SET_USER] (state, user) {
      state.user = user
    },
    [SET_USER_PROFILES] (state, profiles) {
      Vue.set(state.user, 'profiles', profiles)
    },
    [SET_USER_PROFILE_BY_ID] (state, profileData) {
      const idx = state.user.profiles.findIndex(p => p.id === profileData.id)
      if (idx === -1) {
        state.user.profiles.push(profileData)
      } else {
        state.user.profiles.splice(idx, 1, profileData)
      }
    },
    [DELETE_USER_PROFILE] (state, profileId) {
      state.user.profiles = state.user.profiles.filter(p => Number(p.id) !== Number(profileId))
    },
  },
  actions: {
    [Actions.GET_USER_PROFILES] ({ commit, getters }) {
      return axios.get(`/user/${getters.userId}/profile/`)
        .then(({ data }) => {
          commit(SET_USER_PROFILES, data)
          return data
        })
    },
    [Actions.CREATE_USER_PROFILE] ({ commit, getters }, profileData) {
      return axios.post(`/user/${getters.userId}/profile/`, profileData)
        .then(({ data: { profiles } }) => {
          commit(SET_USER_PROFILES, profiles)
          const lastProfile = profiles[profiles.length - 1]
          return lastProfile
        })
    },
    [Actions.SET_USER_PROFILE] ({ commit, getters }, profileData) {
      commit(SET_USER_PROFILE_BY_ID, profileData)
      return profileData
    },
    [Actions.DELETE_USER_PROFILE] ({ commit, dispatch }, profileId) {
      commit(DELETE_USER_PROFILE, profileId)
      return axios.delete(`/user/profile/${profileId}`)
        .then(() => {
          return dispatch(Actions.GET_USER_PROFILES)
        })
        .catch(err => {
          dispatch(Actions.GET_USER_PROFILES)
          return Promise.reject(err)
        })
    },
    [Actions.MERGE_USER_PROFILES] ({ commit, dispatch }, { profileId, withProfileId }) {
      commit(DELETE_USER_PROFILE, profileId)
      return axios.post(`/user/profile/${profileId}/merge_with/${withProfileId}`)
        .then(() => {
          return dispatch(Actions.GET_USER_PROFILES)
        })
        .catch(err => {
          dispatch(Actions.GET_USER_PROFILES)
          return Promise.reject(err)
        })
    },
    [Actions.REGISTER] ({ commit, dispatch }, payload) {
      return axios.post(`${AUTH_PREFIX}/`, payload)
    },
    [Actions.LOGIN] ({ commit, dispatch }, payload) {
      commit(AUTH_REQUEST)
      return axios.post(`${AUTH_PREFIX}/login`, payload)
        .then(resp => {
          const token = resp.data.access_token
          localStorage.setItem('jwt_token', token)
          return dispatch(Actions.LOAD_USER_FROM_TOKEN, token)
        })
        .catch(err => {
          commit(AUTH_ERROR)
          localStorage.removeItem('jwt_token')
          return Promise.reject(err)
        })
    },
    [Actions.LOGOUT] ({ commit }) {
      return new Promise((resolve, reject) => {
        commit(LOGOUT)
        localStorage.removeItem('jwt_token')
        delete axios.defaults.headers.common.Authorization
        resolve()
      })
    },
    [Actions.AUTHENTICATE] ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        if (state.token) {
          if (!state.user) {
            return dispatch(Actions.LOAD_USER_FROM_TOKEN, state.token)
              .then(() => {
                console.info('Found token in localStorage. User reloaded.')
                resolve()
              })
              .catch((err) => {
                dispatch(Actions.ERROR, err)
                resolve()
              })
          }
        }
        resolve()
      })
    },
    [Actions.LOAD_USER_FROM_TOKEN] ({ commit, dispatch }, token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      return axios.get(`${AUTH_PREFIX}/me`)
        .then(({ data }) => {
          commit(AUTH_SUCCESS, { token, user: data })
          return data
        })
        .catch((err) => {
          if (err.response &&
            (err.response.status === 401 || err.response.status === 422) &&
            err.config &&
            !err.config.__isRetryRequest) {
            console.warn('localStorage token invalid. Forced logout.')
            dispatch(Actions.LOGOUT)
              .then(() => {
                throw new Error('Session expired. Please log in again.')
              })
          } else {
            throw new Error(err)
          }
        })
    },
    [Actions.RESET_PASSWORD] ({ commit, dispatch }, data) {
      return axios.post(`${AUTH_PREFIX}/recover`, data)
        .then(({ data: { access_token: accessToken } }) => {
          return dispatch(Actions.LOAD_USER_FROM_TOKEN, accessToken)
        })
    },
    [Actions.SEND_PASSWORD_RESET] ({ commit, dispatch }, data) {
      return axios.post(`${AUTH_PREFIX}/reset_password`, data)
    },
    [Actions.MERGE_ACCOUNT] ({ commit, dispatch }, { userId, payload }) {
      return axios.post(`${AUTH_PREFIX}/${userId}/merge`, payload)
    },
    [Actions.REFRESH_USER] ({ commit, dispatch }) {
      return axios.get(`${AUTH_PREFIX}/me`)
        .then(({ data }) => {
          commit(SET_USER, data)
        })
    },
    [Actions.ADMIN_GET_USERS] ({ commit, getters }) {
      return axios.get('/user/')
        .then(({ data }) => {
          return data
        })
    },
  },
  getters: {
    user: state => state.user,
    userProfiles: state => state.user.profiles.filter(p => !p.soft_deleted),
    userProfileIds: (state, getters) => getters.userProfiles.map(p => p.id),
    userProfileById: (state, getters) => profileId => getters.userProfiles.find(p => p.id === profileId),
    userId: state => state.user.id,
    isLoggedIn: state => !!state.user,
    authStatus: state => state.status,
    mainProfile: (state, getters) => getters.userProfiles.find(p => p.is_main)
  }
}

export default userModule
