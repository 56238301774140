// Non-user profiles (contacts etc.)

import axios from '@/api/config'
import Actions from '@/store/actions'

const SET_PROFILE_BY_ID = 'μ: Set profile by ID'
// const DELETE_PROFILE = 'μ: Delete profile'

const profileModule = {
  state: {
    profiles: [],
  },
  mutations: {
    [SET_PROFILE_BY_ID] (state, profileData) {
      const idx = state.profiles.findIndex(p => p.id === profileData.id && p.version === profileData.version)
      if (idx === -1) {
        state.profiles.push(profileData)
      } else {
        state.profiles.splice(idx, 1, profileData)
      }
    },
  },
  actions: {
    [Actions.GET_PROFILE] ({ commit, dispatch, getters }, { profileId, profileVersion = undefined }) {
      return axios.get(`/user/profile/${profileId}` + (profileVersion !== undefined ? `/${profileVersion}` : ''))
        .then(({ data }) => {
          if (data.user_id === getters.userId) {
            return dispatch(Actions.SET_USER_PROFILE, data)
          } else {
            commit(SET_PROFILE_BY_ID, data)
            return data
          }
        })
    },
    [Actions.UPDATE_PROFILE] ({ commit, dispatch, getters }, { profileId, profileVersion = undefined, profileData }) {
      return axios.put(
        `/user/profile/${profileId}` + (profileVersion !== undefined ? `/${profileVersion}` : ''),
        profileData
      )
        .then(({ data }) => {
          if (data.user_id === getters.userId) {
            if ('is_main' in profileData) {
              dispatch(Actions.GET_USER_PROFILES)
            }
            return dispatch(Actions.SET_USER_PROFILE, data)
          } else {
            commit(SET_PROFILE_BY_ID, data)
            return data
          }
        })
    },
  },
  getters: {
    profileById: (state) => (profileId) => state.profiles.find(p => p.id === profileId),
    profileByIdVersion: (state, getters) => (profileId, version) => {
      const ownP = getters.userProfileById(profileId)
      if (ownP) {
        return ownP
      } else {
        return state.profiles.find(p => p.id === profileId && (version === undefined || p.version === version))
      }
    },
  }
}

export default profileModule
