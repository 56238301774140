<template>
  <b-button
    :icon-right="icon"
    :type="type"
    inverted
  >
    <slot />
  </b-button>
</template>

<script>
export default {
  name: 'CreateButton',
  props: {
    type: {
      type: String,
      default: 'is-primary'
    },
    icon: {
      type: String,
      default: 'plus'
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
