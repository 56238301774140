<template>
  <div class="signup-and-signin-modal" id="forgotPassword-modal">
    <div v-if="serverError" class="message is-danger">
      <div class="message-body">
        <p>{{ serverError }}</p>
      </div>
    </div>
    <div v-if="isSent" class="message is-success">
      <div class="message-body">
        <p>A password recovery email has been sent to the email address provided. If the email doesn't show up soon, check your spam folder for any mail from <strong>wolfgang.pro</strong>.</p>
      </div>
    </div>
    <a class="close-modal1"><img src="@/assets/img/image/BT_close.svg"></a>
    <form v-if="!isSent" autocomplete="on" @submit.prevent="recoverPasswordForm">
      <h2>Reset your password</h2>
      <b-field class="c-field" label="Email">
        <b-input
          v-model="recoverPasswordData.emailAddress"
          type="email"
          autocomplete="email"
          placeholder="john.doe@example.xyz"
          required
        />
      </b-field>
      <b-field class="c-field" label="First name">
        <b-input
          v-model="recoverPasswordData.firstName"
          autocomplete="given-name"
          placeholder="John"
          required
        />
      </b-field>
      <b-field class="c-field">
        <button :is-loading="isLoading" :is-disabled="isLoading" class="create-account-btn">
          Reset password
        </button>
      </b-field>
      <p style="font-size:18px;color: #1A202E;">We will email you instructions on how to reset your password.</p>
    </form>
  </div>
</template>

<script>
import jQuery from 'jquery'
import Actions from '@/store/actions'

const $ = jQuery
export default {
  name: 'ForgotPassword',
  data () {
    return {
      recoverPasswordData: {
        emailAddress: '',
        firstName: ''
      },
      isLoading: false,
      isSent: false,
      serverError: false
    }
  },
  mounted () {
    $(document).ready(function () {
      $('#forgot-password-link').click(function () {
        $('#sigin-modal').removeClass('active')
        $('#forgotPassword-modal').addClass('active')
      })
      $('.close-modal1').click(function () {
        $('#forgotPassword-modal').removeClass('active')
        $('#sigin-modal').addClass('active')
      })
    })
  },
  methods: {
    recoverPasswordForm: function () {
      this.$store.dispatch(Actions.RESET_ERROR)
      this.isLoading = true
      this.$store.dispatch(Actions.SEND_PASSWORD_RESET, {
        email: this.recoverPasswordData.emailAddress,
        first_name: this.recoverPasswordData.firstName
      })
        .then(() => {
          this.isSent = true
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped src="@/assets/css/style.css">

</style>