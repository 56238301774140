import axios from '@/api/config'
import Actions from '@/store/actions'
import localforage from 'localforage'

const MU_SET_PORTS = 'μ: Set ports'
const MU_SET_COUNTRIES = 'μ: Set countries'

const geoModule = {
  state: {
    ports: [],
    countries: [],
  },
  mutations: {
    [MU_SET_PORTS] (state, ports) {
      state.ports = ports
    },
    [MU_SET_COUNTRIES] (state, countries) {
      state.countries = countries
    },
  },
  actions: {
    [Actions.GET_PORTS] ({ commit, state }) {
      if (state.ports.length) {
        return Promise.resolve()
      } else {
        return localforage.getItem('wolfgang_ports')
          .then(ports => {
            if (!ports) {
              // Ports are not in BS, fetch them from the API.
              return axios.get('/geo/ports')
                .then(({ data: apiPorts }) => {
                  // Cache them in BS
                  localforage.setItem('wolfgang_ports', apiPorts)
                    .catch(() => {
                      console.error('Unable to cache ports in browser storage.')
                    })
                    .finally(() => {
                      commit(MU_SET_PORTS, apiPorts)
                    })
                })
            } else {
              // Ports are already cached in BS
              commit(MU_SET_PORTS, ports)
              return ports
            }
          })
      }
    },
    [Actions.GET_COUNTRIES] ({ commit, state }) {
      if (state.countries.length) {
        return Promise.resolve()
      } else {
        return localforage.getItem('wolfgang_countries_')
          .then(countries => {
            if (!countries) {
              // Ports are not in BS, fetch them from the API.
              return axios.get('/geo/countries')
                .then(({ data: apiCountries }) => {
                  // Cache them in BS
                  localforage.setItem('wolfgang_countries', apiCountries)
                    .then(() => {
                      return apiCountries
                    })
                    .catch(() => {
                      console.error('Unable to cache countries in browser storage.')
                      return {}
                    })
                    .finally(() => {
                      commit(MU_SET_COUNTRIES, apiCountries)
                    })
                })
            } else {
              commit(MU_SET_COUNTRIES, countries)
              return countries
            }
          })
      }
    }
  },
  getters: {
    getPortById: (state) => (id) => {
      return state.ports.find(port => port.id === Number(id))
    },
    getCountryById: (state) => (id) => {
      return state.ports.find(port => port.id === Number(id))
    }
  }
}

export default geoModule
