<template>
  <div v-if="!isRemoving" class="role-card">
    <div class="card-header">
      <div class="columns is-gapless is-mobile">
        <div class="column columns is-vcentered is-gapless">
          <div v-if="role != 'passengers'" class="column is-one-fourth"><p class="card-title">{{ profile.full_name }}</p></div>
          <div v-if="role == 'passengers'" class="column is-one-fifth"><p class="card-title">{{ profile.full_name }}</p></div>
          <div class="column">
            <div v-if="role == 'passengers'" class="columns is-gapless" data-test="emb-form">
              <div class="column">
                <b-field>
                  <p class="control">
                    <span class="button is-static">Boarding Call: </span>
                  </p>
                  <multi-select
                    v-model="emb_waypoint"
                    class="call_location"
                    label="call_location"
                    :show-labels="false"
                    :disabled="isRoleReadOnly || isLoading"
                    :loading="isLoading"
                    track-by="id"
                    :internal-search="false"
                    :options="callWaypoints"
                    @input="updateCruiseDisembs"
                  >
                    <template #noOptions>
                      <em>You must first create calls in the <router-link :to="{name: 'MappingSection'}">mapping section</router-link>…</em>
                    </template>
                  </multi-select>
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-field>
                  <p class="control">
                    <b-datetimepicker
                      v-model="emb_datetime"
                      placeholder="Click to select..."
                      icon="calendar"
                      pack="fas"
                      :disabled="isRoleReadOnly || isLoading"
                      :loading="isLoading"
                      :min-datetime="emb_waypoint ? emb_waypoint.arr_date : null"
                      :max-datetime="emb_waypoint ? emb_waypoint.dep_date : null"
                      width="175"
                      :clearable="false"
                      :datepicker="{'first-day-of-week':1}"
                      :timepicker="{}"
                      :datetime-formatter="(d) => formatDateWithTZ(d, emb_waypoint ? emb_waypoint.timezone : 'UTC')"
                      :tz-offset="getOffsetForDate(emb_datetime, emb_waypoint ? emb_waypoint.timezone : 'UTC')"
                      :use-html5-validation="false"
                      @input="updateCruiseDisembs"
                    />
                </p>
                </b-field>
              </div>
            </div>
            <div v-if="role == 'passengers'" class="columns is-gapless" data-test="disemb-form">
              <div class="column">
                <b-field>
                  <p class="control">
                    <span class="button is-static">Disembarking Call: </span>
                  </p>
                  <multi-select
                    v-model="disemb_waypoint"
                    class="call_location"
                    label="call_location"
                    :show-labels="false"
                    :disabled="isRoleReadOnly || isLoading"
                    :loading="isLoading"
                    track-by="id"
                    :internal-search="false"
                    :options="callWaypoints"
                    @input="updateCruiseDisembs"
                  >
                    <template #noOptions>
                      <em>You must first create calls in the <router-link :to="{name: 'MappingSection'}">mapping section</router-link>…</em>
                    </template>
                  </multi-select>
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-datetimepicker
                  v-model="disemb_datetime"
                  placeholder="Click to select..."
                  icon="calendar"
                  pack="fas"
                  :disabled="isRoleReadOnly || isLoading"
                  :loading="isLoading"
                  :min-datetime="disemb_waypoint ? disemb_waypoint.arr_date : null"
                  :max-datetime="disemb_waypoint ? disemb_waypoint.dep_date : null"
                  width="175"
                  :clearable="false"
                  :datepicker="{'first-day-of-week':1}"
                  :timepicker="{}"
                  :datetime-formatter="(d) => formatDateWithTZ(d, disemb_waypoint ? disemb_waypoint.timezone : 'UTC')"
                  :tz-offset="getOffsetForDate(disemb_datetime, disemb_waypoint ? disemb_waypoint.timezone : 'UTC')"
                  :use-html5-validation="false"
                  @input="updateCruiseDisembs"
                />
              </div>
            </div>
            <b-message v-if="validationError" type="is-danger">{{validationError}}</b-message>
            <b-message v-if="emb_waypoint && emb_waypoint.geoname.country.iso && emb_waypoint.geoname.country.iso !== 'FR'" type="is-danger">
              Passenger embarking in {{ emb_waypoint.geoname.country.name }}.
              This cruise agreement may not  be valid and will not be supported by Wolfgang.
            </b-message>
            <b-message v-if="role == 'passengers' && emb_waypoint && disemb_waypoint === emb_waypoint" type="is-warning">
              Passenger's embarkation and disembarkation are the same.
            </b-message>
          </div>
        </div>
        <div :class="this.role == 'passengers' ? 'column is-1' : 'column is-narrow'">
          <p>
              <a :disabled="isLoading" @click="toggleForm">
              {{ isFormShown ? 'Close' : isProfileReadOnly ? 'View' : 'Edit' }}
            </a>
          </p>
          <p v-if="!isRoleReadOnly && !parentLoading">
            <a :disabled="isLoading" class="has-text-danger" @click="handleRemove(profile.id)">Remove</a>
          </p>
          <b-field v-if="this.role == 'passengers' && this.roleProfiles.length > 1 && !isRoleReadOnly && !isProfileReadOnly">
            <multi-select
              ref="copySelectionBox"
              v-model="full_name"
              label="full_name"
              placeholder="Copy…"
              :options="roleProfilesFiltered"
              :loading="isLoading"
              :disabled="isRoleReadOnly || isLoading"
              :show-labels="false"
              @input="copyPassengers"
            >
              <template #beforeList>
                <div style="padding: .75rem;">
                  <a class="is-italic" @click="copyPassengers">Copy to all passengers</a>
                </div>
                <p class="subtitle pl-3 has-text-weight-medium is-size-6">
                  Copy to passenger:
                </p>
              </template>
            </multi-select>
          </b-field>
        </div>
      </div>
    </div>
    <div v-if="isFormShown" class="card-content">
      <profile-form :role="role" :profile-id="profile.id" :is-self="isSelf" :is-embedded="true" :profile-version="profile.version" @close="toggleForm" @profile-changed="handleProfileChanged"></profile-form>
    </div>
  </div>
</template>

<script>
import ProfileForm from '@/components/forms/profile/ProfileForm'
import MultiSelect from 'vue-multiselect'
import Actions from '@/store/actions'
import DateFormatters from '@/helpers/date_formatters'

export default {
  name: 'RoleProfileItem',
  components: {
    ProfileForm,
    MultiSelect,
  },
  props: {
    profile: {
      type: Object,
      required: true
    },
    role: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    parentLoading: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      isFormShown: false,
      isLoading: false,
      isRemoving: false,
      validationError: null,
      emb_datetime: null,
      disemb_datetime: null,
      emb_waypoint: null,
      disemb_waypoint: null,
      full_name: null
    }
  },
  computed: {
    roleProfilesFiltered () {
      return this.roleProfiles.filter(p => p.full_name !== this.profile.full_name)
    },
    roleProfiles () {
      if (this.role in this.$store.state.cruise.cruise) {
        return this.$store.state.cruise.cruise[this.role]
      } else {
        return []
      }
    },
    waypoints () {
      return this.$store.state.waypoint.waypoints
    },
    callWaypoints () {
      return (this.waypoints.filter(wp => wp.is_call))
    },
    isRoleReadOnly () {
      return (this.readOnly || !this.profile)
    },
    isProfileReadOnly () {
      return (!this.profile.write_access || this.profile.locked)
    },
    cruise () {
      return this.$store.state.cruise.cruise
    },
    disemb () {
      if (this.profile.disembs !== undefined && this.profile.disembs.length > 0) {
        return this.profile.disembs[0]
      } else {
        return {}
      }
    },
    isSelf () {
      return this.$store.getters.userProfileIds.includes(this.profile.id)
    }
  },
  watch: {
    disemb: function (newVal, oldVal) {
      this.setDisembData()
    },
  },
  created () {
    if (this.role === 'passengers') {
      if (!this.waypoints.length) {
        this.isLoading = true
        this.$store.dispatch(Actions.GET_CRUISE_WAYPOINTS)
          .then(() => { this.isLoading = false })
      }
      this.setDisembData()
    }
  },
  methods: {
    ...DateFormatters,
    toggleForm () {
      this.isFormShown = !this.isFormShown
    },
    copyPassengers () {
      this.$refs.copySelectionBox.$refs.search.blur()
      this.isLoading = true
      var passengersIds

      if (this.full_name && this.full_name.id) {
        passengersIds = this.roleProfiles.filter(p => p.id === this.full_name.id)
      } else {
        passengersIds = this.roleProfiles
      }

      Promise.all(
        passengersIds.forEach(p =>
          this.$store.dispatch(Actions.ADD_PROFILE_DISEMB, {
            refreshCruise: false,
            profileId: p.id,
            disembData: {
              emb_date: this.emb_datetime,
              disemb_date: this.disemb_datetime,
              emb_waypoint_id: this.emb_waypoint ? this.emb_waypoint.id : null,
              disemb_waypoint_id: this.disemb_waypoint ? this.disemb_waypoint.id : null
            }
          })
        )
      ).then(({ data: disembs }) => {
        this.$store.dispatch(Actions.REFRESH_CRUISE)
        this.validationError = null
      })
        .catch(error => {
          if (error.response) {
            this.validationError = error.response.data.message
          }
        })
        .finally(() => {
          this.isLoading = false
          this.full_name = null
        })
    },
    handleProfileChanged (data) {
      this.isLoading = true
      this.$store.dispatch(Actions.GET_PROFILES_FOR_ROLE, this.role)
        .finally(() => { this.isLoading = false })
    },
    setDisembData () {
      this.emb_datetime = this.disemb.emb_date ? this.$dayjs(this.disemb.emb_date).toDate() : null
      this.disemb_datetime = this.disemb.disemb_date ? this.$dayjs(this.disemb.disemb_date).toDate() : null
      this.emb_waypoint = this.disemb.emb_waypoint ? this.callWaypoints.find(wp => wp.id === this.disemb.emb_waypoint.id) : null
      this.disemb_waypoint = this.disemb.disemb_waypoint ? this.callWaypoints.find(wp => wp.id === this.disemb.disemb_waypoint.id) : null

      if (this.emb_waypoint && this.emb_datetime &&
          (this.emb_datetime < this.emb_waypoint.arr_date ||
          this.emb_datetime > this.emb_waypoint.dep_date)) {
        this.validationError = "Boarding time is outside of boarding call's arrival/departure times."
      }
      if (this.disemb_waypoint && this.disemb_datetime &&
          (this.disemb_datetime < this.disemb_waypoint.arr_date ||
            this.disemb_datetime > this.disemb_waypoint.dep_date)) {
        this.validationError = "Disembarking time is outside of disembarking call's arrival/departure times."
      }
    },
    updateCruiseDisembs () {
      if (this.disemb_waypoint && this.emb_waypoint &&
        this.disemb_waypoint.arr_date < this.emb_waypoint.dep_date) {
        alert('Disembarking call cannot be before boarding call')
        this.disemb_waypoint = this.emb_waypoint
        return false
      }
      if (this.emb_waypoint && !this.emb_datetime) {
        this.emb_datetime = this.emb_waypoint.dep_date
      }
      if (this.disemb_waypoint && !this.disemb_datetime) {
        this.disemb_datetime = this.disemb_waypoint.arr_date
      }

      if (this.emb_waypoint && this.emb_datetime &&
          (this.emb_datetime < this.emb_waypoint.arr_date ||
          this.emb_datetime > this.emb_waypoint.dep_date)) {
        this.emb_datetime = this.emb_waypoint.dep_date
      }
      if (this.disemb_waypoint && this.disemb_datetime &&
          (this.disemb_datetime < this.disemb_waypoint.arr_date ||
            this.disemb_datetime > this.disemb_waypoint.dep_date)) {
        this.disemb_datetime = this.disemb_waypoint.arr_date
      }

      this.$store.dispatch(Actions.ADD_PROFILE_DISEMB, {
        profileId: this.profile.id,
        disembData: {
          emb_date: this.emb_datetime,
          disemb_date: this.disemb_datetime,
          emb_waypoint_id: this.emb_waypoint ? this.emb_waypoint.id : null,
          disemb_waypoint_id: this.disemb_waypoint ? this.disemb_waypoint.id : null
        }
      })
        .then(({ data: disembs }) => {
          this.validationError = null
        })
        .catch(error => {
          if (error.response) {
            this.validationError = error.response.data.message
          }
        })
    },
    handleRemove (id) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to remove this entry?',
        confirmText: 'Remove',
        type: 'is-warning',
        hasIcon: false,
        onConfirm: () => {
          this.isRemoving = true
          this.$store.dispatch(Actions.DELETE_PROFILE_FROM_ROLE, {
            role: this.role,
            profileId: id
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">

.role-card {
  .card-header {
    padding: 0.75rem;
    @include touch {
      padding: 0.5rem;
    }
    // margin-bottom: 0 !important;
    .columns {
      width: 100%;
      margin-bottom: 0;

      >.column {
        margin-right: 0.5rem;
        @include mobile {
          margin-bottom: 0.2rem;
        }
        >.columns {
          // border: 1px green solid;
          margin-bottom: 0.25rem;
          // margin: 0.25rem 0.75rem 0.25rem 0.75rem;
          // padding: 0 0.25rem 0 0;

          >.column {
            margin-right: 0.25rem;
          }
        }
      }
    }

    .card-title {
      font-weight: normal;
      font-size: 120%;
    }
  }
}

</style>
