<template>
    <footer class="w-footer">

    <div class="container-fluid" style="border-top:2px solid white">
        <div class="row">
            <div class="col-md-4 d-flex align-items-center justify-content-center">
                <div class="footer-content">Wolfgang SAS<br/>
                    470 Promenade des Anglais<br/>
Nice - France</div>
            </div>
            <div class="col-md-4 d-flex align-items-center justify-content-center footer-border">
                <img loading="lazy" src="@/assets/img/image/footer-logo.svg" class="img-fluid footer-img">
            </div>
            <div class="col-md-4 d-flex align-items-center justify-content-center">
                <div class="footer-content">+33 489 41 92 14</div>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
export default {

}
</script>

<style scoped src="@/assets/css/style.css">
</style>
<style scoped type="scss">
.footer-content {
    text-align: center;
}
</style>