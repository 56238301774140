<template>
  <div :class="['form-item', customClass]">
    <b-field
      expanded
      :class="{required: required}"
      :message="validationErrors"
      :type="validationErrors ? 'is-danger' : ''"
    >
      <div class="control">
        <b-button class="is-static">
          {{ labelAuto }}
        </b-button>
      </div>
      <b-datepicker
        v-if="isDate"
        :data-test="name"
        expanded
        :disabled="readOnly"
        :value="dateValue"
        :date-formatter="localeDate"
        editable
        @input="(val) => updateForm(val)"
      />
      <multi-select
        v-else-if="isCountrySelect"
        v-model="selectedOption"
        :data-test="name"
        :disabled="readOnly || countries.length == 0"
        :placeholder="readOnly ? '' : 'Type country'"
        label="name"
        :show-labels="false"
        track-by="iso"
        :options="countries"
        :show-no-results="false"
        select-label=""
        :required="required"
        @input="updateFormCountry"
        @search-change="countrySearchText = $event"
      />
      <div
        v-else-if="selectOptions.length"
        class="control is-expanded"
      >
        <b-select
          v-model="selectedOption"
          :data-test="name"
          :disabled="readOnly"
          :placeholder="placeholder"
          :required="required"
          :expanded="expanded"
          @input="updateForm"
        >
          <option
            v-for="opt in selectOptions"
            :key="opt.value"
            :value="opt.value"
          >
            {{ opt.label }}
          </option>
        </b-select>
      </div>
      <b-input
        v-else-if="type === 'number'"
        :data-test="name"
        :disabled="readOnly"
        :value="formattedValue"
        type="number"
        :placeholder="placeholder"
        :expanded="expanded"
        :required="required"
        :min="min ? min : 0"
        :max="max"
        :step="step"
        :custom-class="inputClassAuto"
        :autocomplete="autocomplete"
        @change.native="(e) => updateForm(e.target.value)"
      />
      <b-input
        v-else
        :value="formattedValue"
        :data-test="name"
        :disabled="readOnly"
        :type="type"
        :placeholder="placeholder"
        :expanded="expanded"
        :required="required"
        :autocomplete="autocomplete"
        :custom-class="inputClassAuto"
        @change.native="(e) => updateForm(e.target.value)"
      />
      <p
        v-if="unit"
        class="control"
      >
        <span class="button is-static">{{ unit }}</span>
      </p>
    </b-field>
  </div>
</template>
<script>
import Actions from '@/store/actions'
import MultiSelect from 'vue-multiselect'
import StringHelpers from '@/helpers/string_helpers'
import DateFormatters from '@/helpers/date_formatters'
import NumFormatters from '@/helpers/num_formatters'

export default {
  name: 'FormItem',
  components: {
    MultiSelect,
  },
  props: {
    name: {
      required: true,
      type: String
    },
    value: {
      required: true,
    },
    label: {
      required: false,
      default: undefined,
      type: String
    },
    type: {
      required: false,
      default: 'text',
      type: String
    },
    placeholder: {
      required: false,
      default: null,
      type: String
    },
    readOnly: {
      required: false,
      default: false,
      type: Boolean
    },
    required: {
      required: false,
      default: false,
      type: Boolean
    },
    expanded: {
      required: false,
      default: true,
      type: Boolean
    },
    isCountrySelect: {
      required: false,
      default: false,
      type: Boolean
    },
    selectOptions: {
      required: false,
      default: () => [],
      type: Array
    },
    // updateVar: {
    //   required: false,
    //   default: null,
    //   type: String
    // },
    min: {
      required: false,
      default: null,
      type: Number
    },
    max: {
      required: false,
      default: null,
      type: Number
    },
    step: {
      required: false,
      default: 'any',
      type: [String, Number]
    },
    unit: {
      required: false,
      default: null,
      type: String
    },
    autocomplete: {
      required: false,
      default: null,
      type: String
    },
    isDate: {
      required: false,
      default: null,
      type: Boolean
    },
    customClass: {
      required: false,
      default: 'column',
      type: String
    },
    inputClass: {
      required: false,
      default: '',
      type: String
    },
    uppercase: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      countrySearchText: '',
      selectedOption: null,
      dateValue: null,
    }
  },
  computed: {
    labelAuto () {
      if (this.label) {
        return this.label
      } else {
        return this.capitalise(this.snakeToSpace(this.name))
      }
    },
    countries () {
      return this.$store.state.geo.countries
    },
    isNumber () {
      return this.type === 'number' || this.type === 'price'
    },
    inputClassAuto () {
      return this.inputClass + (this.type === 'price' ? ' price' : '') + (this.step === 'any' && this.isNumber ? ' no-arrows' : '')
    },
    validationErrors () {
      if (this.$store.getters.validationErrors && this.name in this.$store.getters.validationErrors) {
        return this.$store.getters.validationErrors[this.name]
      } else {
        return null
      }
    },
    formattedValue () {
      return this.type === 'price' ? this.formatPrice(this.value) : this.value
    }
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // console.log('watch -> initValue')
        this.initValue(newVal)
      }
    },
  },
  created () {
    if (this.isCountrySelect) {
      this.$store.dispatch(Actions.GET_COUNTRIES)
    }
    this.initValue(this.value)
  },
  methods: {
    ...StringHelpers,
    ...DateFormatters,
    ...NumFormatters,
    initValue (val) {
      if (this.isCountrySelect || this.selectOptions.length) {
        this.selectedOption = val
      } else if (this.isDate) {
        if (val) {
          this.dateValue = this.$dayjs(val).toDate()
        } else {
          this.dateValue = null
        }
      }
    },
    updateForm (val) {
      if (this.isNumber) {
        val = val ? parseFloat(val.replace(/[ ,]/g, '')) : 0
        // val = Number(val)
      } else if (this.isDate && val) {
        val = new Date(val.getTime() - (val.getTimezoneOffset() * 60000))
          .toISOString()
          .split('T')[0]
      } else if (this.uppercase) {
        val = val.toUpperCase()
      }
      this.$emit('change', this.name, val)
    },
    updateFormCountry (val) {
      this.$emit('change', this.name, val ? val.iso : null)
    },
  }
}
</script>
<style lang="scss">
.form-item {
  &.single-line {
    margin-bottom: 1.5rem;
  }
  .required {
    input, select, .multiselect__tags, .button {
      border-color: #999 !important;
    }
  }

  .price, .distance, .percent, .quantity {
    text-align: right;
    // font-family: serif;
    font-weight: bold;
    letter-spacing: 0.05em;
  }

  .control>input {
    min-width: 5em;
  }

  .field {
    margin-bottom: 1em;
  }
}

.form-item.column .field {
  margin-bottom: 0em;
}

</style>
