<template>
  <section class="section">
    <div class="container">
      <nav class="level is-mobile">
        <div class="level-left" />
        <div class="level-right">
          <div class="level-item">
            <CreateButton @click.native="isContactCreationModalShown = true">Add Contact</CreateButton>
          </div>
        </div>
      </nav>
      <Loading v-if="isFetching && contactProfiles.length == 0" />
      <div v-else-if="contactProfiles.length" id="ContactsList" class="section-box">
        <div v-for="profileData in contactProfiles" :key="profileData.id" class="columns contact-card is-vcentered ">
          <div class="column is-2 card-leftname">
              <div class="qv-card__left">
                <pictogram :name="profileData.full_name" @click.native="showContactDetails(profileData)">
                  <span>{{ profileData.initials }}</span>
                </pictogram>
              </div>
          </div>
          <div class="column is-4 card-details-left">
            <div class="details-left" @click="showContactDetails(profileData)">
              <span class="card-name">{{profileData.full_name}}</span>
            </div>
            <div v-if="profileData.company_name">
              <span class="card-company"><span class="caption">Company:</span> {{profileData.company_name}}</span>
            </div>
          </div>
          <div class="column is-4">
             <div v-if="profileData.address_country">
               <span class="caption">Country:</span> {{profileData.address_country.name}}
             </div>
             <div v-if="profileData.date_of_birth">
               <span class="caption">Date of Birth:</span> {{ profileData.date_of_birth | moment("MMMM Do YYYY") }}
             </div>
          </div>
          <div class="column is-2 card-btn">
            <router-link :to="{name: 'ContactDetail', params: {id: profileData.id}, props: true}">
              <button class="button is-primary is-outlined detail-btn">Detailed info</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <b-modal :active.sync="isContactCreationModalShown"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="min-width: 300px;">
        <ContactForm @contact-created="showContactDetails" @profile-created="showProfileDetails" />
      </div>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import Pictogram from '@/components/UI/Pictogram'
import moment from 'vue-moment'
import Actions from '@/store/actions'
import ContactForm from '@/components/forms/contact/ContactForm'

Vue.use(moment)
export default {
  name: 'ContactsList',
  components: {
    Pictogram,
    ContactForm,
  },
  data () {
    return {
      contactsUsersInfo: [],
      contacts: [],
      selectedContactUserId: null,
      isFetching: true,
      // contactProfiles: [],
      contactId: '',
      isContactCreationModalShown: false
    }
  },
  computed: {
    contactProfiles: function () {
      return [].concat.apply([], this.$store.state.contact.contacts.map(
        cs => cs.contacts.map(function (c) {
          c.from_profile_id = cs.from_profile_id
          return c
        })
      ))
    }
  },
  created () {
    self.isFetching = true
    this.$store.dispatch(Actions.GET_USER_CONTACTS)
      .then((contacts) => {
        // this.contactProfiles = [].concat.apply([], contacts.map(
        //   cs => cs.contacts.map(function (c) {
        //     c.from_profile_id = cs.from_profile_id
        //     return c
        //   })
        // ))
      })
      .finally(() => { this.isFetching = false })
  },
  methods: {
    showContactDetails (contact) {
      this.$router.push({ name: 'ContactDetail', params: { id: contact.id } })
    },
    showProfileDetails (profile) {
      this.$router.push({ name: 'ProfileDetail', params: { profileId: profile.id } })
    },
  }
}
</script>

<style lang="scss" scoped>

.columns.contact-card{
  border: 2px #dbdbdb solid;
  padding: 4px 2px;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .pictogram {
    cursor: pointer;
  }
  .card-btn{
    // margin-top: 12px;
    a:hover {
      text-decoration: none;
    }
  }
  .card-name{
    font-weight: 600;
    font-size: 1.1rem;
    // padding-bottom: 1.5rem;
    cursor: pointer;
  }
}

</style>
