<template>
  <div id="priceSection" data-test="price-form" :aria-busy="isUpdating || isSending || isFetching">
    <form v-if="cruise">
      <section class="section">
        <h1 class="title">Yacht Rates</h1>
        <div class="columns is-vcentered">
          <div class="column is-7">
            <b-field has-addons class="main-item">
              <p class="control">
                <span class="button is-static">① Base Cruise Price:</span>
              </p>
              <p class="control">
                <span class="button is-static">{{ cruise.currency }}</span>
              </p>
              <p class="control is-expanded has-text-right">
                <b-input
                  :value="formatPrice(cruise.base_price_cruising)"
                  data-test="base_price_cruising"
                  :disabled="!isDraft || !isEditable"
                  custom-class="price"
                  min="0"
                  placeholder="0"
                  @change.native="(e) => updateCruiseDataFromNum('base_price_cruising', e.target.value)"
                />
              </p>
            </b-field>
          </div>
          <p class="column comment">
           excl. VAT, excl. oil products
          </p>
        </div>
        <div class="columns is-vcentered">
          <div class="column is-7">
            <b-field has-addons class="main-item">
              <p class="control">
                <span class="button is-static">② Positioning Cost:</span>
              </p>
              <p class="control">
                <span class="button is-static">{{ cruise.currency }}</span>
              </p>
              <p class="control is-expanded">
                <b-input
                  :value="formatPrice(cruise.base_price_routing)"
                  data-test="base_price_routing"
                  :disabled="!isDraft || !isEditable"
                  custom-class="price"
                  placeholder="0"
                  @change.native="(e) => updateCruiseDataFromNum('base_price_routing', e.target.value)"
                />
              </p>
            </b-field>
          </div>
          <p class="column comment">
           excl. VAT, excl. oil products
          </p>
        </div>
      </section>
      <hr/>
      <section class="section">
        <h1 class="title">
          Fuel Prices
        </h1>
        <div v-if="isSettle" class="columns is-vcentered">
          <div class="column is-7 sub-item">
            <b-field has-addons>
              <p class="control">
                <span class="button is-static">a. Routing consumption:</span>
              </p>
              <p class="control is-expanded has-text-right">
                <b-input
                  :value="formatFuel(cruise.routing_consumption)"
                  data-test="routing_consumption"
                  :disabled="!isEditable"
                  custom-class="quantity"
                  min="0"
                  placeholder="0"
                  @change.native="(e) => updateCruiseDataFromNum('routing_consumption', e.target.value)"
                />
              </p>
              <p class="control">
                <span class="button is-static">L</span>
              </p>
            </b-field>
          </div>
          <div class="column comment">
           Original estimate: {{ formatNullNA(formatFuelUnit(lastGeneratedCruise.routing_cons_estimate))  }}
          </div>
        </div>
        <div v-else class="columns is-vcentered">
          <div class="column is-7 sub-item">
            <nav class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  a. Routing consumption estimate:
                </div>
              </div>
              <div class="level-right">
                <div class="level-item" data-test="routing_cons_estimate">
                  {{ formatNullNA(formatFuelUnit(cruise.routing_cons_estimate)) }}
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div v-if="isSettle" class="columns is-vcentered">
          <div class="column is-7 sub-item">
            <b-field has-addons>
              <p class="control">
                <span class="button is-static">b. Cruise consumption:</span>
              </p>
              <p class="control is-expanded has-text-right">
                <b-input
                  :value="formatFuel(cruise.journey_consumption)"
                  data-test="journey_consumption"
                  :disabled="!isEditable"
                  custom-class="quantity"
                  min="0"
                  placeholder="0"
                  @change.native="(e) => updateCruiseDataFromNum('journey_consumption', e.target.value)"
                />
              </p>
              <p class="control">
                <span class="button is-static">L</span>
              </p>
            </b-field>
          </div>
          <div class="column comment">
           Original estimate: {{ formatNullNA(formatFuelUnit(lastGeneratedCruise.journey_cons_estimate)) }}
          </div>
        </div>
        <div v-else class="columns is-vcentered">
          <div class="column is-7 sub-item">
            <nav class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  b. Cruise consumption estimate:
                </div>
              </div>
              <div class="level-right">
                <div class="level-item" data-test="journey_cons_estimate">
                  {{ formatNullNA(formatFuelUnit(cruise.journey_cons_estimate)) }}
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div class="columns is-vcentered">
          <div class="column is-7 sub-item">
            <nav class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <span v-if="isSettle">c. Total journey consumption:</span>
                  <span v-else>c. Total journey consumption estimate:</span>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item price" data-test="total_fuel_consumption">
                  {{ formatNullNA(formatFuelUnit(cruise.total_fuel_consumption)) }}
                </div>
              </div>
            </nav>
          </div>
          <div v-if="isSettle" class="column comment">
            Original estimate: {{ formatNullNA(formatFuelUnit(lastGeneratedCruise.total_fuel_consumption)) }}
          </div>
          <div class="column comment">
            <span class="arrow"> ☜ </span>a + b
          </div>
        </div>
        <div v-for="fuel_bunker in cruise.fuel_bunkers" :key="fuel_bunker.id" class="columns is-vcentered" data-test="fuel_bunker">
          <div class="column is-2">
            <div class="columns is-gapless is-vcentered">
              <div v-if="isEditable && cruise.fuel_bunkers.length > 1" class="column is-narrow">
                <b-button
                  icon-left="trash-alt"
                  class="mouseover-danger"
                  pack="fas"
                  type="is-text"
                  @click="deleteFuelBunker(fuel_bunker.id)"
                />
              </div>
              <div class="column" style="margin-right:0.5rem">
                <p class="control text">
                  With fuel price:
                </p>
              </div>
            </div>
          </div>
          <div class="column is-5">
            <div class="columns is-gapless">
              <FormItem
                name="price_per_litre"
                :value="fuel_bunker.price_per_litre"
                data-test="price_per_litre"
                :label="cruise.currency"
                type="price"
                :read-only="(!isDraft && !isSettle) || !isEditable"
                placeholder="0"
                unit="per L"
                @change="(varName, val) => updateFuelBunker(fuel_bunker.id, 'price_per_litre', val)"
              />
              <div class="column" style="margin-left:0.5rem">
                <b-field
                  has-addons
                  :type="{'is-danger': fuel_bunker.quantity_used > fuel_bunker.quantity}"
                  :message="fuel_bunker.quantity_used > fuel_bunker.quantity ? 'Will be using ' + formatFuel(fuel_bunker.quantity_used) : ''"
                >
                  <p class="control">
                    <span class="button is-static">for up to:</span>
                  </p>
                  <p class="control is-expanded">
                    <b-input
                      :value="formatFuel(fuel_bunker.quantity)"
                      data-test="quantity"
                      :disabled="(!isDraft && !isSettle) || !isEditable"
                      custom-class="quantity"
                      placeholder="0"
                      @change.native="(e) => updateFuelBunker(fuel_bunker.id, 'quantity', e.target.value)"
                    />
                  </p>
                  <p class="control">
                    <span class="button is-static">L</span>
                  </p>
                </b-field>
              </div>
            </div>
          </div>
          <p class="column comment  is-2">
           excl. taxes
          </p>
        </div>
        <div v-if="(isDraft || isSettle) && isEditable" class="columns is-vcentered main-item">
          <div class="column is-7">
            <CreateButton @click.native="addFuelBunker()">
              Add bunker
            </CreateButton>
          </div>
        </div>
        <div class="columns is-vcentered main-item">
          <div class="column is-5">
              ③ Oil Products Price:
          </div>
          <div class="column is-2 has-text-right">
            <div class="columns is-vcentered is-mobile is-gapless">
              <div class="column is-1">
                {{ cruise.currency }}
              </div>
              <div class="column" data-test="total_oil_price">
               {{ formatNullNA(formatPrice(cruise.total_oil_price))  }}
             </div>
           </div>
          </div>
          <div v-if="cruise.total_oil_price !== null" class="column comment is-narrow">
             excl. taxes
          </div>
          <div class="column comment has-text-centered">
             You saved EUR {{formatNullNA(formatPrice(savingOne))}} on TICPE
          </div>
        </div>
      </section>
      <hr/>
      <section class="section">
        <div class="columns is-vcentered main-item">
          <div class="column is-5">
            ④ Total Services:
          </div>
          <div class="column is-2 has-text-right">
            <div class="columns is-vcentered is-mobile is-gapless">
              <div class="column is-1">
                {{ cruise.currency }}
              </div>
              <div class="column" data-test="total_services_price">
               {{ formatNullNA(formatPrice(cruise.total_services_price)) }}
             </div>
           </div>
          </div>
          <div class="column comment">
            <b-tooltip :label="cruise.special_conditions.length + ' service' + (cruise.special_conditions.length != 1 ? 's' : '') + ' listed'">
              <router-link :to="{name: 'ServicesSection'}">
                <b-icon
                  icon="info-circle"
                  size="is-small"
                />
              </router-link>
            </b-tooltip>
          </div>
        </div>
        <div class="columns is-vcentered main-item">
          <div class="column is-5">
            ⑤ Cruise VAT:
          </div>
          <div class="column is-2 has-text-right">
            <div class="columns is-vcentered is-mobile is-gapless">
              <div class="column is-1">
                {{ cruise.currency }}
              </div>
              <div class="column" data-test="vat_amount">
               {{ formatNullNA(formatPrice(cruise.vat_amount))  }}
             </div>
           </div>
          </div>
          <div v-if="cruise.vat_country" class="column is-narrow">
            <b-tooltip
              :label="'VAT country: ' + cruise.vat_country.name"
              data-test="vat_country"
            >
              <router-link :to="{name: 'ClientsSection'}">
                <b-icon
                  icon="info-circle"
                  size="is-small"
                />
              </router-link>
            </b-tooltip>
          </div>
          <div class="column saving-two">
            You saved EUR {{formatNullNA(formatPrice(savingTwo - cruise.vat_amount))}} on VAT
          </div>
        </div>
        <div class="columns is-multiline is-vcentered main-item price-total">
          <div class="column is-5">
            ⑥ Cruise Agreement:
          </div>
          <div class="column is-2 has-text-right">
            <div class="columns is-vcentered is-mobile is-gapless">
              <div class="column is-1">
                {{ cruise.currency }}
              </div>
              <div class="column" data-test="total_agreement_price">
               {{ formatNullNA(formatPrice(total_agreement_price)) }}
             </div>
           </div>
          </div>
          <div class="column comment">
            <span class="arrow"> ☜ </span>① + ② + ③ + ④ + ⑤
          </div>
          <div class="column is-full has-text-right saving-three has-text-weight-medium">
            You saved €{{ formatNullNA(formatPrice(savingOne + savingTwo)) }} with Wolfgang
          </div>
        </div>
      </section>
      <hr/>
      <section class="section">
        <h1 class="title">
          Advance Provisioning Allowance &amp; Deposit
        </h1>
        <div v-if="isSettle" class="columns is-vcentered">
          <div class="column is-7">
            <b-field has-addons class="main-item">
              <p class="control">
                <span class="button is-static">⑦ Advance Provisioning Allowance:</span>
              </p>
              <p class="control">
                <span class="button is-static">{{ cruise.currency }}</span>
              </p>
              <p class="control is-expanded">
                <b-input
                  :value="formatPrice(cruise.actual_client_expenses)"
                  :disabled="!isEditable"
                  custom-class="price"
                  placeholder="n/a"
                  min="0"
                  expanded
                  @change.native="(e) => updateCruiseDataFromNum('actual_client_expenses', e.target.value)"
                />
              </p>
            </b-field>
          </div>
          <div class="column comment">
            Requested APA: {{ cruise.currency }} {{ formatPrice(requested_cea_amount) }}
          </div>
        </div>
        <div v-else class="columns is-vcentered">
          <div class="column is-5">
            <b-field has-addons class="main-item">
              <p class="control">
                <span class="button is-static">⑦ Requested APA:</span>
              </p>
              <p class="control is-expanded">
                <b-input
                  :value="formatPercent(cruise.requested_cea_ratio)"
                  :disabled="!isDraft || !isEditable"
                  custom-class="percent"
                  placeholder="0"
                  step="0.1"
                  min="0"
                  max="100"
                  type="number"
                  expanded
                  @change.native="(e) => updateCruiseDataFromPercent('requested_cea_ratio', e.target.value)"
                />
              </p>
              <p class="control">
                <b-button class="is-static">%</b-button>
              </p>
            </b-field>
          </div>
          <div v-if="total_agreement_price" class="column is-2">
            <b-field has-addons class="main-item">
              <p class="control">
                <span class="button is-static">{{ cruise.currency }}</span>
              </p>
              <p class="control is-expanded has-text-right">
                <b-input
                  :value="formatPrice(requested_cea_amount)"
                  :disabled="!isDraft || !isEditable"
                  custom-class="price"
                  placeholder="n/a"
                  min="0"
                  expanded
                  @change.native="(e) => updateCruiseDataWithRatioFrom('requested_cea_ratio', e.target.value, total_agreement_price)"
                />
              </p>
            </b-field>
          </div>
        </div>
        <div v-if="isSettle" class="columns is-vcentered">
          <div class="column is-7">
            <b-field has-addons class="main-item">
              <p class="control">
                <span class="button is-static">⑧ Amount deducted from Security Deposit:</span>
              </p>
              <p class="control">
                <span class="button is-static">{{ cruise.currency }}</span>
              </p>
              <p class="control is-expanded">
                <b-input
                  :value="formatPrice(cruise.actual_deposit_deduction)"
                  :disabled="!isEditable"
                  custom-class="price"
                  placeholder="n/a"
                  min="0"
                  expanded
                  @change.native="(e) => updateCruiseDataFromNum('actual_deposit_deduction', e.target.value)"
                />
              </p>
            </b-field>
          </div>
          <div class="column comment">
            Requested Security Deposit: {{ cruise.currency }} {{ formatPrice(requested_deposit_amount) }}
          </div>
        </div>
        <div v-else class="columns is-vcentered">
          <div class="column is-5">
            <b-field has-addons class="main-item">
              <p class="control">
                <span class="button is-static">⑧ Requested Security Deposit:</span>
              </p>
              <p class="control is-expanded">
                <b-input
                  :value="formatPercent(cruise.requested_deposit_ratio)"
                  :disabled="!isDraft || !isEditable"
                  custom-class="percent"
                  placeholder="0"
                  step="0.1"
                  min="0"
                  max="100"
                  type="number"
                  expanded
                  @change.native="(e) => updateCruiseDataFromPercent('requested_deposit_ratio', e.target.value)"
                />
              </p>
              <p class="control">
                <b-button class="is-static">%</b-button>
              </p>
            </b-field>
          </div>
          <div v-if="total_agreement_price" class="column is-2">
            <b-field has-addons class="main-item">
              <p class="control">
                <span class="button is-static">{{ cruise.currency }}</span>
              </p>
              <p class="control is-expanded has-text-right">
                <b-input
                  :value="formatPrice(requested_deposit_amount)"
                  :disabled="!isDraft || !isEditable"
                  custom-class="price"
                  placeholder="0"
                  min="0"
                  expanded
                  @change.native="(e) => updateCruiseDataWithRatioFrom('requested_deposit_ratio', e.target.value, total_agreement_price)"
                />
              </p>
            </b-field>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="columns is-vcentered main-item price-total">
          <div class="column is-5">
            Grand Total:
          </div>
          <div class="column is-2 has-text-right">
            <div class="columns is-vcentered is-mobile is-gapless">
              <div class="column is-1">
                {{ cruise.currency }}
              </div>
              <div class="column">
               {{ formatNullNA(formatPrice(cruise.grand_total_price))  }}
             </div>
           </div>
          </div>
          <div class="column comment">
            <span class="arrow"> ☜ </span>⑥ + ⑦ + ⑧
          </div>
        </div>
      </section>
      <hr class="separator" />
      <h1 class="title separator-title">Payment Plan</h1>
      <div v-for="(title, callType) in callTypes" :key="callType">
        <section class="section">
          <h1 class="title">{{ title }}</h1>
          <payment-call
            v-for="call in paymentCalls.filter(pc => pc.call_type == callType && pc.contract_code !== 'SETTLEMENT')"
            :key="call.id"
            :call="call"
          />
          <template v-if="isSettle">
            <payment-call
              v-for="call in paymentCalls.filter(pc => pc.call_type == callType && pc.contract_code === 'SETTLEMENT')"
              :key="call.id"
              :call="call"
              class="main-item price-total"
            />
          </template>
          <template v-else>
            <div class="columns">
              <div v-if="isEditable && cruise.can_approve" class="column">
                <CreateButton @click.native="addCallForFund(callType)">
                  Add call for fund
                </CreateButton>
              </div>
            </div>
          </template>
          <div class="columns is-vcentered calls-total">
            <div class="column is-2">
              Total called:
            </div>
            <div class="column is-5 has-text-right">
              {{ cruise.currency }} {{ formatNullNA(formatPrice(callsTotalAsked(callType)))  }}
            </div>
            <div class="column  is-narrow">
              Total paid:
            </div>
            <div class="column has-text-right is-narrow">
              {{ cruise.currency }} {{ formatNullNA(formatPrice(callsTotalPaid(callType)))  }}
            </div>
            <div v-if="callsTotalRemaining(callType) != 0" class="column is-expanded has-text-danger">
              (Remaining: {{ cruise.currency }} {{ formatNullNA(formatPrice(callsTotalRemaining(callType))) }})
            </div>
          </div>
          <div v-if="!isSettle" class="columns is-vcentered main-item price-total">
              <div class="column is-5">
                Remaining Balance {{ shortCallTypes[callType] }}:
              </div>
              <div class="column is-2 has-text-right">
                <div class="columns is-vcentered is-mobile is-gapless">
                  <div class="column is-1">
                    {{ cruise.currency }}
                  </div>
                  <div class="column">
                   {{ formatNullNA(formatPrice(callsRemainingBalance(callType))) }}
                 </div>
               </div>
              </div>
            </div>
        </section>
        <hr/>
      </div>
      <div class="buttons">
        <b-button v-if="cruise.status == 'SETTLING'"
          type="is-danger"
          :disabled="isSending"
          @click="cancelSettling()"
        >
          Cancel settlement process
        </b-button>
        <b-button v-if="cruise.status == 'CONTRACT' || cruise.status == 'ADDENDUM'"
          type="is-primary"
          :disabled="isSending"
          @click="startSettling()"
        >
          Start settling
        </b-button>
        <b-button v-if="cruise.status == 'SETTLING'"
          type="is-primary"
          :disabled="isSending"
          @click="settle()"
        >
          Close settlement
        </b-button>
        <b-button v-if="cruise.status == 'SETTLED'"
          type="is-warning"
          :disabled="isSending"
          @click="cancelSettle()"
        >
          Reopen settlement process
        </b-button>
      </div>
    </form>
    <Loading v-else />
  </div>
</template>
<script>

import DateFormatters from '@/helpers/date_formatters'
import NumFormatters from '@/helpers/num_formatters'
import Actions from '@/store/actions'
import PaymentCall from '@/components/forms/cruise/PaymentCall'

export default {
  name: 'PriceSection',
  components: {
    PaymentCall,
  },
  data () {
    return {
      isFetching: false,
      isSending: false,
      isUpdating: false,
      errors: {},
      callTypes: {
        CRUISE: 'Cruise Agreement',
        CEA: 'Advance Provisioning Allowance',
        DEPOSIT: 'Security Deposit',
      },
      defaultCallDesignations: {
        CRUISE: 'Down Payment ',
        CEA: 'APA ',
        DEPOSIT: 'Deposit ',
      },
      shortCallTypes: {
        CRUISE: 'Cruise Agreement ⑥',
        CEA: 'APA ⑦',
        DEPOSIT: 'Deposit ⑧',
      },
      lastGeneratedCruise: {}
    }
  },
  computed: {
    savingOne: function () {
      return this.cruise.total_fuel_consumption * 0.6075
    },
    savingTwo: function () {
      return (this.cruise.base_price_cruising +
        this.cruise.base_price_routing + this.cruise.total_oil_price +
        this.cruise.total_services_price +
        this.savingOne) * 0.2
    },
    cruise: function () {
      return this.$store.state.cruise.cruise
    },
    paymentCalls: function () {
      return this.$store.state.cruise.paymentCalls
    },
    total_agreement_price: function () {
      return this.cruise.total_agreement_price
    },
    requested_deposit_amount: function () {
      const c = this.isSettle ? this.lastGeneratedCruise : this.cruise
      if (c.total_agreement_price === undefined) {
        return null
      } else {
        return c.requested_deposit_ratio * c.total_agreement_price
      }
    },
    requested_cea_amount: function () {
      const c = this.isSettle ? this.lastGeneratedCruise : this.cruise
      if (c.total_agreement_price === undefined) {
        return null
      } else {
        return c.requested_cea_ratio * c.total_agreement_price
      }
    },
    isDraft () {
      return this.cruise.status === 'DRAFT'
    },
    isSettle () {
      return ['SETTLING', 'SETTLED', 'ARCHIVED'].includes(this.cruise.status)
    },
    isEditable () {
      return !['SETTLED', 'ARCHIVED'].includes(this.cruise.status) && this.cruise.write_access
    },
  },
  watch: {
    cruise (toVal, fromVal) {
      if (this.isSettle && toVal.last_generated_version > 0) {
        const toVersion = toVal.last_generated_version
        if (this.lastGeneratedCruise && this.lastGeneratedCruise.version === toVersion) {
          return
        }
        this.$store.dispatch(Actions.GET_CRUISE, { cruiseId: this.cruise.id, cruiseVersion: toVersion, saveInState: false })
          .then((data) => {
            this.lastGeneratedCruise = data
          })
      }
    }
  },
  created () {
    this.$store.dispatch(Actions.REFRESH_CRUISE, { validate: false })
      .then(() => {
        this.$store.dispatch(Actions.GET_CRUISE_PAYMENT_CALLS)
      })
  },
  methods: {
    ...DateFormatters,
    ...NumFormatters,
    updateCruiseDataFromNum (cruiseVar, val) {
      val = val ? parseFloat(val.replace(/[ ,]/g, '')) : 0
      this.updateCruiseData(cruiseVar, val)
    },
    updateCruiseDataFromPercent (cruiseVar, val) {
      val = val ? parseFloat(val.replace(/[ ,]/g, '')) : 0
      val = val / 100
      this.updateCruiseData(cruiseVar, val)
    },
    updateCruiseDataWithRatioFrom (cruiseVar, val, ratioFrom) {
      if (typeof ratioFrom === 'string') {
        ratioFrom = parseFloat(ratioFrom.replace(/[ ,]/g, ''))
      }
      val = val ? parseFloat(val.replace(/[ ,]/g, '')) : 0
      val = val / ratioFrom
      this.updateCruiseData(cruiseVar, val)
    },
    updateCruiseData (cruiseVar, val) {
      this.isUpdating = true
      this.$store.dispatch(Actions.UPDATE_CRUISE, { [cruiseVar]: val })
        .then(() => {
          this.$store.dispatch(Actions.GET_CRUISE_PAYMENT_CALLS)
        })
        .finally(() => {
          this.isUpdating = false
        })
    },
    updateFuelBunker (fuelBunkerId, fbVar, val) {
      if (typeof val === 'string') {
        val = val ? parseFloat(val.replace(/[ ,]/g, '')) : 0
      }
      return this.$store.dispatch(Actions.UPDATE_FUEL_BUNKER, {
        fuelBunkerId: fuelBunkerId,
        fuelBunkerData: { [fbVar]: val }
      })
        .then(() => {
          this.$store.dispatch(Actions.REFRESH_CRUISE)
        })
    },
    addFuelBunker () {
      this.$store.dispatch(Actions.ADD_FUEL_BUNKER, {})
    },
    deleteFuelBunker (fuelBunkerId) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this bunker?',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          this.$store.dispatch(Actions.DELETE_FUEL_BUNKER, fuelBunkerId)
        }
      })
    },
    addCallForFund (callType) {
      const curCount = this.paymentCalls.filter(
        pc => pc.call_type === callType && pc.contract_code !== 'BOOKING_BALANCE'
      ).length
      this.$store.dispatch(Actions.ADD_PAYMENT_CALL, {
        call_type: callType,
        designation: this.defaultCallDesignations[callType] + (curCount + 1)
      })
    },
    startSettling () {
      this.isSending = true
      this.$store.dispatch(Actions.START_SETTLING, this.cruise.id)
        .finally(() => {
          this.isSending = false
        })
    },
    cancelSettling () {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete the ongoing settlement process?<br/>All payment information related to the settlement will be deleted permanently.',
        confirmText: 'Delete',
        cancelText: 'Cancel',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          this.isSending = true
          this.$store.dispatch(Actions.CANCEL_SETTLING, this.cruise.id)
            .finally(() => {
              this.isSending = false
            })
        }
      })
    },
    settle () {
      this.isSending = true
      this.$store.dispatch(Actions.SETTLE_CONTRACT, this.cruise.id)
        .finally(() => {
          this.isSending = false
        })
    },
    cancelSettle () {
      this.isSending = true
      this.$store.dispatch(Actions.CANCEL_SETTLE, this.cruise.id)
        .finally(() => {
          this.isSending = false
        })
    },
    callsTotalAsked: function (callType) {
      return this.paymentCalls.filter(
        c => c.call_type === callType && c.amount !== null
      ).map(c => c.amount).reduce((a, b) => a + b, 0)
    },
    callsRemainingBalance: function (callType) {
      const totalsDue = {
        CRUISE: this.total_agreement_price,
        CEA: this.total_agreement_price !== null ? this.cruise.requested_cea_ratio * this.total_agreement_price : null,
        DEPOSIT: this.total_agreement_price !== null ? this.cruise.requested_deposit_ratio * this.total_agreement_price : null,
      }
      if (totalsDue[callType] === null) return null
      return (totalsDue[callType] - this.callsTotalAsked(callType))
    },
    callsTotalPaid: function (callType) {
      return this.paymentCalls
        .filter(c => c.call_type === callType)
        .map(c => c.payments.filter(p => !p.cancelled).map(p => p.amount))
        .flat()
        .reduce((a, b) => a + b, 0)
    },
    callsTotalRemaining: function (callType) {
      const asked = this.callsTotalAsked(callType)
      const paid = this.callsTotalPaid(callType)
      if (asked === null || paid === null) return null
      return (asked - paid)
    }
  },
}
</script>
<style lang="scss">

  .price, .distance, .percent, .quantity {
    text-align: right;
    // font-family: serif;
    font-weight: bold;
    letter-spacing: 0.05em;
  }

  .price-total {
    background-color: #d0dddf;
    font-size: 1.2rem;
    margin-top: 0.5rem !important;
  }

</style>
<style lang="scss" scoped>

.separator {
  border-top: 1.5rem solid #f4f6fa;
  margin: 1rem -2.5rem 1rem -2.5rem;
}

h1.separator-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.saving-two {
  margin-left:13%;
}

.saving-three {
  padding-right:9%;
}

#priceSection {
  .columns {
    // margin-bottom: 0 !important;
    @include tablet {
      margin-bottom: 0 !important;
    }
    @include desktop {
      margin-bottom: 0 !important;
    }
    .text {
      padding-left: 1em;
      @include mobile {
        padding-left: 0.5rem;
      }
    }
    .sub-item .level-left .level-item {
      padding-left: 1rem;
    }
    .column.sub-item {
      padding-left: 1.5rem;
    }
  }

  .main-item {
    font-weight: bold;
    .comment {
      font-weight: normal;
    }
  }

  .calls-total .column {
    padding-left: 1rem;
    padding-right: 1.5rem;
    font-weight: bold;
  }

  .comment {
    color: #777777;
    // font-style: italic;
      // line-height: 1.5em;

    @include mobile {
      text-align: right !important;
      .arrow {
        display: none;
      }
    }
  }
}

</style>
