<template>
  <div class="role-form" :data-test="role">
    <Loading v-if="isFetching && !roleProfiles" />
    <template v-else>
      <b-message v-if="cruiseReadOnly && roleProfiles.length == 0" type="is-dark">
        No {{ capitalise(roleFriendlyNamePlural) }} have been added to this agreement.
      </b-message>
      <div v-else class="level">
        <h1 class="title level-left">{{ capitalise(multiple ? roleFriendlyNamePlural : roleFriendlyNameSingular) }}</h1>
        <div v-if="!cruiseReadOnly" class="level-right add-role">
          <multi-select
            ref="userSelectionBox"
            v-model="selectedContact"
            :data-test="`select-${role}`"
            label="full_name"
            track-by="id"
            :placeholder="(multiple ? (roleProfiles && roleProfiles.length ? `Add another` : `Add`) : `Set`) + ' ' + capitalise (roleFriendlyNameSingular)"
            :options="contacts"
            group-values="contacts"
            group-label="category"
            :group-select="false"
            :loading="isSending || isFetching"
            :disabled="isSending || isFetching"
            :show-labels="false"
            @input="assignRole"
          >
            <template #beforeList>
              <div style="padding: .75rem;">
                <a class="is-italic" @click="enableContactCreation">Add a new contact</a>
              </div>
            </template>
          </multi-select>
        </div>
      </div>
      <div v-for="profile in roleProfiles" :key="profile.id" data-test="role-card">
        <role-profile-item :profile="profile" :role="role" :read-only="cruiseReadOnly" :parent-loading="isSending" />
      </div>
      <b-modal
        :active.sync="isContactCreationModalShown"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
      >
        <div class="modal-card" style="min-width: 300px;">
          <ContactForm
            :role="role"
            @contact-created="handleContactCreated"
            @profile-created="handleProfileCreated"
          />
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import Actions from '@/store/actions'
import MultiSelect from 'vue-multiselect'
import ContactForm from '@/components/forms/contact/ContactForm'
import RoleProfileItem from '@/components/forms/cruise/RoleProfileItem'
import StringHelpers from '@/helpers/string_helpers'

export default {
  name: 'RoleForm',
  components: {
    MultiSelect,
    ContactForm,
    RoleProfileItem,
  },
  props: {
    agreementId: {
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    role: {
      type: String,
      required: true
    },
    roleFriendlyNamePlural: {
      type: String,
      required: true
    },
    roleFriendlyNameSingular: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      isFetching: true,
      isSending: false,
      userAddData: {
        profile_id: null
      },
      selectedContact: null,
      isContactCreationModalShown: false,
    }
  },
  computed: {
    contacts () {
      const selectedContactIds = this.roleProfiles.map(p => p.id)
      const groupedContacts = []
      if (this.$store.getters.userProfiles && !this.$store.getters.userProfiles.some(
        p => selectedContactIds.includes(p.id)
      )) {
        groupedContacts.push({
          category: 'You',
          contacts: this.$store.getters.userProfiles
        })
      }

      if (this.$store.state.contact.contacts.length > 0) {
        groupedContacts.push({
          category: 'Contacts',
          contacts: this.$store.state.contact.contacts.reduce(
            (arr, contact) => {
              return arr.concat(...contact.contacts.filter(
                profile => !selectedContactIds.includes(profile.id)
              ))
            }, [])
        })
      }
      return groupedContacts
    },
    roleProfiles () {
      if (this.role in this.$store.state.cruise.cruise) {
        return this.$store.state.cruise.cruise[this.role]
      } else {
        return []
      }
    },
    cruiseReadOnly () { return this.$store.getters.cruiseReadOnly }
  },
  created () {
    this.isFetching = true
    this.$store.dispatch(Actions.GET_USER_CONTACTS)
    this.$store.dispatch(Actions.GET_USER_PROFILES)
    this.$store.dispatch(Actions.GET_PROFILES_FOR_ROLE, this.role)
      .finally(() => {
        this.isFetching = false
      })
  },
  methods: {
    ...StringHelpers,
    assignRole () {
      // if (this.selectedContact) {
      this.isSending = true
      // if (this.multiple) {
      this.$store.dispatch(Actions.ADD_PROFILE_TO_ROLE, {
        role: this.role,
        profileId: this.selectedContact.id
      })
        .then(() => {
          if (this.role === 'passengers') {
            this.$store.dispatch(Actions.ADD_PROFILE_DISEMB, {
              profileId: this.selectedContact.id,
              disembData: {
                emb_date: null,
                disemb_date: null,
                emb_waypoint_id: null,
                disemb_waypoint_id: null
              }
            })
          }
        })
        .finally(() => {
          this.selectedContact = null
          this.isSending = false
        })
      //   } else {
      //     // If only one user can have this role
      //     // Fetch the current ones and delete them all
      //     this.$store.dispatch(Actions.GET_PROFILES_FOR_ROLE, this.role)
      //       .then((profiles) => {
      //         Promise.all(profiles.map(p => this.$store.dispatch(Actions.DELETE_PROFILE_FROM_ROLE, {
      //           role: this.role,
      //           profileId: p.id
      //         })))
      //           .then(() => {
      //             // All existing profiles for this role have been removed
      //             // We can now safely add the newly chosen user
      //             this.$store.dispatch(Actions.ADD_PROFILE_TO_ROLE, {
      //               role: this.role,
      //               profileId: this.selectedContact.id
      //             })
      //               .finally(() => {
      //                 this.isSending = false
      //                 this.selectedContact = null
      //               })
      //           })
      //       })
      //   }
      // }
    },
    enableContactCreation () {
      this.isContactCreationModalShown = true
      this.$refs.userSelectionBox.$refs.search.blur()
    },
    handleContactCreated (createdContact) {
      this.isContactCreationModalShown = false
      // Only look through second group ('contacts'), not first group ('you'):
      const contactsMenu = this.contacts.find(cs => cs.category === 'Contacts')

      this.selectedContact = contactsMenu.contacts.find(contact => contact.id === createdContact.id)
      if (this.selectedContact) {
        this.assignRole()
      }
    },
    handleProfileCreated (createdProfile) {
      this.isContactCreationModalShown = false
      Promise.all([
        this.$store.dispatch(Actions.GET_PROFILES_FOR_ROLE, this.role),
        this.$store.dispatch(Actions.GET_USER_PROFILES)
      ])
        .then(() => {
          const youMenu = this.contacts.find(cs => cs.category === 'You')
          if (youMenu !== undefined) {
            this.selectedContact = youMenu.contacts.find(contact => contact.id === createdProfile.id)
            this.assignRole()
          } else {
            this.selectedContact = undefined
          }
        })
    }
  },
}
</script>
<style lang="scss" scoped>
  .role-form {
    margin-bottom: 1rem;

    .level {
      margin-bottom: 0.8rem;
    }
  }
</style>
