<template>
  <fragment>
<div class="price-plan" id="Price1">
    <h2 class="fnt-lib-r">It’s all-inclusive, paid by the cruise and serves the whole team!</h2>
    <div class="role-table columns">
      <div class="column">
        <div class="role-box">
          <div class="box-frame">
            <div class="header">
              Owners
            </div>
            <div class="content">
            <p>Register your yacht</p>
            <p>We connect with your team</p>
            <p>Tell us about your next cruise</p>
            <p>We optimize</p>
            <p>We deliver your contract</p>
            <p>Enjoy more cruising for less!</p>
          </div>
        </div>
        <a class="role-action contact-us-learn">
        <span class="main hide-phone">It’s included!</span>
        <div class="extra"><div class="bigger">15% of the savings paid by the client</div>
        <div class="hide-phone">All-inclusive</div></div>
      </a>
      </div>

      </div>
      <div class="column">
        <div class="role-box">
          <div class="box-frame">
            <div class="header">
          Central Agents
        </div>
        <div class="content">
          <p>Register your fleet</p>
          <p>We train your team</p>
          <p>Forward us the cruise requests</p>
          <p>We process the contracts</p>
          <p>We supervise the cruise</p>
          <p>We deliver all fiscal reports</p>
        </div>
      </div>
      <a class="role-action contact-us-learn">
        <span class="main hide-phone">It’s included!</span>
        <div class="extra"><div class="bigger">15% of the savings paid by the client</div>
        <div class="hide-phone">All-inclusive</div></div>
      </a>
    </div>
  </div>
  <div class="column">
        <div class="role-box">
          <div class="box-frame">
            <div class="header">
          Brokers
        </div>
        <div class="content">
          <p>We liaise with the CA</p>
          <p>We optimize the contract</p>
          <p>We handle all addenda</p>
          <p>We are your trusted third party</p>
          <p>We invoice through the cruise</p>
        </div>
      </div>
        <a class="role-action contact-us-learn">
          <span class="main hide-phone">It’s included!</span>
        <div class="extra"><div class="bigger">15% of the savings paid by the client</div>
        <div class="hide-phone">All-inclusive</div></div>
        </a>
      </div>
    </div>
      <div class="column">
        <div class="role-box">
          <div class="box-frame">
            <div class="header">
          Captain
        </div>
        <div class="content">
          <p>We train you and your crew</p>
          <p>We help you deal with fuel taxes</p>
          <p>We are your 24/7 hotline</p>
          <p>We deliver updates in minutes</p>
          <p>We assist you with controls</p>
          <p>We make sure you’re compliant</p>
        </div>
      </div>
      <a class="role-action contact-us-learn">
        <span class="main hide-phone">It’s included!</span>
        <div class="extra"><div class="bigger">15% of the savings paid by the client</div>
        <div class="hide-phone">All-inclusive</div></div>
      </a>
    </div>
  </div>
</div>

</div>
  <div class="bonus" id="About1">
    <div class="row bonus-row">
      <div class="col-lg-3"><img  loading="lazy" src="@/assets/img/image/ICO1.png" width="100%"></div>
      <div class="col-lg-4">
        <h4>iAo: the future of yachting</h4>
        <p>Wolfgang is leading the first R&D project to understand and mitigate the impacts of yachting and boating: a multi-awarded effort toward better yachting. Join us to build the future of yachting!</p>
      </div>
    </div>

  </div>

  <div class="testimonial">
    <div class="col-lg-6">
      <h4>We sailed the seven seas for two decades before we came up with the plan to improve yacht charters, inspired by the efficiency of the cruise industry. We have built a plug-and-play process so you can focus on the joys of cruising:<br/>
        smarter and greener yachting!</h4>
      <h5>Matthias du Verle <span>&</span>Anthony Flores</h5>
      <p>Co-founders</p>
    </div>

  </div>
  <div class="we-are-wolfgang">
    <h4>We are Wolfgang</h4>
    <h5>Bluetech and Legaltech for Yachts and Ports</h5>
    <div class="we-are-wolfgang-p-wrap">
      <div class="col-md-12 col-lg-6 " data-aos="fade-right">
        <p>Wolfgang offers services and software to the yachting industry since 2018. Wolfgang Yachts is our solution for owners and professionals. We help design and optimize every aspects of the yacht cruise with the aim to create a better luxury travel experience.</p>
      </div>
      <div class="col-md-12  col-lg-6" data-aos="fade-left">
        <p>Since 2023, Wolfgang has extended its expertise towards marinas and shore professionals to support with the overall environmental management of its cruise. Optimizing resources and controlling the footprint of our activity is how we sail different!</p>
      </div>
    </div>
    <div class="text-center contact_us_text">
      <a class="savoir-plus contact-us-learn">Learn more</a>
    </div>

  </div>
  <a href="#" class="bot"><img src="@/assets/img/image/BOT.svg"></a>
  </fragment>
</template>
<script>
import jQuery from 'jquery'
const $ = jQuery
export default {
  mounted () {
    $('.contact-us-learn').click(function () {
      $('#contact-us-modal').addClass('active')
      $('body').append('<div class="modal-overlay"></div>')
    })

    $('.close-modal').click(function () {
      $('#contact-us-modal').removeClass('active')
      $('.modal-overlay').remove()
    })
  },
}
</script>
<style scoped src="@/assets/css/style.css">
</style>
<style scoped style="scss">

.role-table {
  margin-bottom: 4em !important;
}

.role-box {

  height: 100%;
  border-radius: 5px;
  overflow: hidden;

  .box-frame {
    border: .5px dashed #c2e1e6;
    border-bottom: 0;
    color: white;
    padding: 1em;
    font-family: "libre_baskerville";
    height: calc(100% - 4em);
    border-radius: 5px 5px 0 0;
  }

  .header {
    font-size: 1.8em;
    text-align: center;
    padding-bottom: 1em;
    border-bottom: .5px dashed #c2e1e6;
  }

  /* only for non-mobile: */
@media screen and (min-width: 769px) {
  &:hover {
    .box-frame {
      height: 0;
      padding: 0;
      border: none;
      /* add vertical transition upward: */
      transition: height 0.5s;

      .header {
        /* display: none; */
        padding-top: .75em;
        border-bottom: none;
        color: hsl(0, 0%, 21%);
      }

      .content {
        display: none;
      }
    }

    .role-action {
      background-color: #a3d6e0;
      height: 100%;
      transition: height 0.5s;
      padding-top: 50%;

      .main {
        display: none;
      }
      .extra {
        display: block;
      }
    }
  }

  .bigger {
      font-size: 1.2em;
      margin: 1em 1em;
  }
}


  .content {
    padding: 2em 0 1em 0;
    font-size: 1.4em;
    text-align: center;
  }

}

.role-action {
    display: block;
    font-size: 1.5em;
    background-color: #c2e1e6;
    font-family: "libre_baskervilleitalic";
    padding: 1em;
    margin-bottom: 1em;
    text-align: center;
    height: 3em;
    border-radius: 0 0 5px 5px;

    &:hover {
      background-color: #a3d6e0;
    }

    .extra {
      display: none;
    }
}


@media screen and (max-width: 770px) {
  .role-action {

    height: 4em;

    .hide-phone {
      display: none;
    }
    .extra {
      display: block;
      line-height: 1.5;
    }
  }
}


</style>