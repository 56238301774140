import axios from 'axios'
// import localforage from 'localforage'
import store from '@/store'
import Actions from '@/store/actions'

import { eventBus } from '@/main.js'

if (process.env.VUE_APP_WOLFGANG_REST_SERVER == null) {
  console.error('VUE_APP_WOLFGANG_REST_SERVER env var is not set!')
} else {
  console.info('Using REST backend URL: ' + process.env.VUE_APP_WOLFGANG_REST_SERVER)
}

export const serverURL = process.env.VUE_APP_WOLFGANG_REST_SERVER
export const apiURL = serverURL + '/api'
const axiosInstance = axios.create({
  baseURL: apiURL
})

var slowQueries
const querySlowing = process.env.VUE_APP_WOLFGANG_DEBUG_QUERY_DELAY
if (querySlowing) {
  console.warn(`Artificially delaying queries by ${querySlowing} ms`)
  slowQueries = data => {
    console.log(`Slowing query by ${querySlowing} ms: ${data.config.method.toUpperCase()} ${data.config.url}`)
    return new Promise(function (resolve) {
      setTimeout(() => {
        resolve(data)
      }, querySlowing)
    })
  }
} else {
  slowQueries = undefined
}

axiosInstance.interceptors.response.use(
  slowQueries,
  error => {
    if (!error.response) {
      console.log('Network Error: Cannot connect to backend.')
      store.dispatch(Actions.ERROR, error)
    } else if (error.response.status === 402 && !error.config.is_retry) {
      return new Promise((resolve, reject) => eventBus.$emit('handle-payment', error.response, resolve, reject))
    } else if (error.response.status < 300 || error.response.status >= 400) {
      if (error.response && error.response.data && error.response.data instanceof ArrayBuffer) {
        const text = String.fromCharCode.apply(null, Array.from(new Uint8Array(error.response.data)))
        if (text) {
          error.response.data = JSON.parse(text)
        }
      }
      store.dispatch(Actions.ERROR, error)
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
