<template>
<div class="signup-and-signin-modal" id="signup-modal">
  <a href="#" class="close-modal"><img src="@/assets/img/image/BT_close.svg"></a>
  <form ref="form" autocomplete="on" @submit.prevent="register">
  <h2>Sign up</h2>

  <b-field class="c-field"
        label="First name"
      >
        <b-input
          v-model="registrationData.first_name"
          autocomplete="given-name"
          placeholder="John"
          required
        />
      </b-field>
      <b-field class="c-field"
        label="Last name"
      >
        <b-input
          v-model="registrationData.last_name"
          autocomplete="family-name"
          placeholder="Doe"
          required
        />
      </b-field>

      <b-field class="c-field"
        label="Email"
      >
        <b-input
          v-model="registrationData.email"
          type="email"
          autocomplete="email"
          placeholder="john.doe@example.xyz"
          required
        />
      </b-field>
      <b-field class="c-field"
        label="Password"
      >
        <b-input
          v-model="registrationData.password"
          type="password"
          autocomplete="new-password"
          placeholder="must be at least 8 characters"
          minlength="8"
          required
          password-reveal
        />
      </b-field>
<label class="custom-checkbox">
  <input  type="checkbox" required>
  <span class="checkmark"></span>
  I agree with Wolfgang’s Terms of Service including the GDPR provisions.
  <br><b-field class="pb-2 c-field">
        <a href="https://wolfgang.pro/tos" class="terms-a"><u>Terms of Service</u></a>
      </b-field>
</label>
<button :is-loading="isSending"
          :is-disabled="isSending" class="create-account-btn">Sign up</button>
<a class="accnt-a" id ="signup-trigger-account"><u>Already have an account ?</u></a>
</form>
</div>
</template>

<script>
/* eslint-disable */
import jQuery from 'jquery'
import Actions from '@/store/actions'

const $ = jQuery

export default {
  name: 'RegistrationForm',
  data () {
    return {
      registrationData: {
        first_name: '',
        last_name: '',
        email: '',
        password: ''
      },
      isSending: false,
    }
  },
  mounted () {
    $('#signup-trigger').click(function () {
      $('#signup-modal').addClass('active')
      $('body').append('<div class="modal-overlay"></div>')
    })
    $('.close-modal').click(function () {
      $('#signup-modal').removeClass('active')
      $('.modal-overlay').remove()
    })

    $('#signup-trigger-account').click(function () {
      $('#signup-modal').removeClass('active')
      $('#signin-modal').addClass('active')
    })
  },
  methods: {
    register () {
      this.$store.dispatch(Actions.REGISTER, this.registrationData)
        .then(() => this.$store.dispatch(Actions.LOGIN, {
          email: this.registrationData.email,
          password: this.registrationData.password
        }))
         .then(() => {
          $('#signup-modal').removeClass('active')
          $('.modal-overlay').remove()
          this.$router.go({ name: 'AgreementList' })
          // this.$emit('login-success')
        })
        .finally(() => { this.isSending = true })
    }
  }
}
</script>

<style src="@/assets/css/style.css">

</style>