<template>
  <div>
    <b-message
      v-if="showSuggestMerging && isSelf && otherProfiles.length > 0"
      type="is-info"
      aria-close-label="Close message"
    >
      <p>
        This profile was created by your contact.<br/>
        Do you want to use <template v-if="otherProfiles.length > 1">one of your current profiles</template><template v-else>your existing profile</template> instead, or do you prefer to keep to keep appearing in this contact's cruise agreements under a separate profile?
        <br/><br/>
      </p>
      <div class="level">
        <div class="level-item">
          <b-dropdown
            v-if="otherProfiles.length > 1"
            v-model="selectedOtherProfiles"
            aria-role="menu"
            @change="(val) => confirmMergeProfile(val)"
          >
            <button
              slot="trigger"
              class="button is-success"
              :is-loading="isSending"
            >
                <span>Merge with…</span>
                <b-icon icon="caret-down"></b-icon>
            </button>
            <b-dropdown-item
              v-for="p in otherProfiles"
              :key="p.id"
              aria-role="menuitem"
              :value="p"
            >
              {{ p.full_name }}
            </b-dropdown-item>
          </b-dropdown>
          <b-button v-else type="is-success" @click="confirmMergeProfile(otherProfiles[0])">Merge with main profile</b-button>
        </div>
        <div class="level-item"><b-button type="is-info" @click="showSuggestMerging=false">Keep profiles separate</b-button></div>
      </div>
    </b-message>
    <template v-if="!isFetching && profileData">
      <div v-if="!isSelf && !isEmbedded" class="columns">
          <div class="column is-1 card-leftname">
              <div class="qv-card__left">
                <pictogram :name="profileData.full_name">
                  <span>{{ profileData.initials }}</span>
                </pictogram>
              </div>
          </div>
          <div class="column is-8 contact-name">&nbsp;&nbsp;&nbsp; <span>{{profileData.first_name}}&nbsp;&nbsp;{{profileData.last_name}}</span></div>
          <div class="column is-2"></div>
      </div>
      <form ref="form">
        <div v-if="isSelf && !isEmbedded" class="columns">
          <FormItem
            name="profile_name"
            :value="profileData.profile_name"
            :read-only="isFormInactive"
            required
            @change="updateProfileData"
          />
          <div v-if="!profileData.is_main" class="column is-narrow">
            <button type="button" class="button is-primary" @click="updateProfileData('is_main', true)">Set as default profile</button>
          </div>
        </div>
        <fieldset>
          <legend>Identity</legend>
          <div class="columns">
            <FormItem
              name="first_name"
              label="First name"
              autocomplete="given-name"
              placeholder="John"
              :value="profileData.first_name"
              :read-only="isFormInactive"
              required
              @change="updateProfileData"
            />
            <FormItem
              name="last_name"
              label="Last name"
              autocomplete="family-name"
              placeholder="Doe"
              :value="profileData.last_name"
              :read-only="isFormInactive"
              required
              @change="updateProfileData"
            />
          </div>
          <fieldset v-if="!role || role && role != 'passengers'">
            <div class="columns">
              <span class="column is-narrow" style="font-weight:500; padding-right:0;">
                Individual
              </span>
              <b-switch class="column is-narrow"
                :disabled="isFormInactive"
                :value="profileData.is_company"
                @input="(val) => updateProfileData('is_company', val)"
              >
                Company
              </b-switch>
              <span class=" column arrow"> ☜ Please define if you will be acting as an individual or on behalf of a company.</span>
            </div>
          </fieldset>
          <div v-if="!profileData.is_company" class="columns">
            <FormItem
              name="date_of_birth"
              :value="profileData.date_of_birth"
              :read-only="isFormInactive"
              is-date
              @change="updateProfileData"
            />
            <FormItem
              name="place_of_birth"
              :value="profileData.place_of_birth"
              :read-only="isFormInactive"
              @change="updateProfileData"
            />
          </div>
        </fieldset>
        <fieldset v-if="!profileData.is_company">
          <legend>Contact</legend>
          <div class="columns">
            <FormItem
              name="address"
              placeholder="Street and number"
              :value="profileData.address"
              :read-only="isFormInactive"
              @change="updateProfileData"
            />
          </div>
          <div class="columns">
            <FormItem
              name="city"
              :value="profileData.city"
              :read-only="isFormInactive"
              @change="updateProfileData"
            />
            <FormItem
              name="postcode"
              :value="profileData.postcode"
              :read-only="isFormInactive"
              @change="updateProfileData"
            />
          </div>
          <div class="columns">
            <FormItem
              name="address_country_iso"
              label="Country"
              :value="profileData.address_country"
              :read-only="isFormInactive"
              is-country-select
              @change="updateProfileData"
            />
            <FormItem
              name="main_phone"
              :value="profileData.main_phone"
              :read-only="isFormInactive"
              type="tel"
              placeholder="Optional"
              @change="updateProfileData"
            />
          </div>
        </fieldset>
        <fieldset v-if="!profileData.is_company">
          <legend>Documents</legend>
          <div class="columns is-multiline">
            <FormItem
              name="passport_num"
              label="Passport number"
              :value="profileData.passport_num"
              :read-only="isFormInactive"
              @change="updateProfileData"
            />
            <FormItem
              name="passport_country_iso"
              label="Passport country"
              :value="profileData.passport_country"
              :read-only="isFormInactive"
              is-country-select
              @change="updateProfileData"
            />
            <FormItem
              name="passport_expiration"
              label="Expiration date"
              :value="profileData.passport_expiration"
              :read-only="isFormInactive"
              is-date
              @change="updateProfileData"
            />
          </div>
        </fieldset>
        <fieldset v-if="!role && profileData.is_company || (role && role != 'passengers') && profileData.is_company">
          <legend>Company</legend>
          <div class="columns is-multiline">
            <FormItem
             name="company_name"
             label="Name"
             :value="profileData.company_name"
             :read-only="isFormInactive"
             placeholder="Company name"
             @change="updateProfileData"
            />
            <FormItem
             name="capacity"
             :value="profileData.capacity"
             :read-only="isFormInactive"
             placeholder="Role within company"
             @change="updateProfileData"
            />
          </div>
          <div class="columns">
            <FormItem
             name="company_reg_num"
             label="Registration number"
             :value="profileData.company_reg_num"
             :read-only="isFormInactive"
             @change="updateProfileData"
            />
            <FormItem
             name="company_vat_num"
             label="VAT number"
             :value="profileData.company_vat_num"
             :read-only="isFormInactive"
             @change="updateProfileData"
            />
          </div>
          <div class="columns">
            <FormItem
             name="company_reg_address"
             label="Registration address"
             :value="profileData.company_reg_address"
             :read-only="isFormInactive"
             @change="updateProfileData"
            />
          </div>
          <div class="columns">
            <FormItem
              name="company_reg_city"
              label="City"
              :value="profileData.company_reg_city"
              :read-only="isFormInactive"
              @change="updateProfileData"
            />
            <FormItem
              name="company_reg_postcode"
              label="Postcode"
              :value="profileData.company_reg_postcode"
              :read-only="isFormInactive"
              @change="updateProfileData"
            />
            <FormItem
              name="company_reg_country_iso"
              label="Country"
              :value="profileData.company_reg_country"
              :read-only="isFormInactive"
              is-country-select
              @change="updateProfileData"
            />
          </div>
          <div class="columns">
            <FormItem
             name="company_insurance_pol"
             label="Insurance policy"
             :value="profileData.company_insurance_pol"
             :read-only="isFormInactive"
             @change="updateProfileData"
            />
            <FormItem
             name="company_insurance_name"
             label="Insurance company"
             :value="profileData.company_insurance_name"
             :read-only="isFormInactive"
             @change="updateProfileData"
            />
          </div>
          <div class="columns">
            <FormItem
             name="financial_guarantee"
             label="Financial guarantee"
             :value="profileData.financial_guarantee"
             :read-only="isFormInactive"
             placeholder="Amount secured"
             @change="updateProfileData"
            />
            <FormItem
             name="guarantor_name"
             label="Guarantor"
             :value="profileData.guarantor_name"
             :read-only="isFormInactive"
             placeholder="Guarantor's name"
             @change="updateProfileData"
            />
          </div>
          <div class="columns">
            <FormItem
             name="company_affil"
             label="Affiliation"
             :value="profileData.company_affil"
             :read-only="isFormInactive"
             placeholder="Federation/Association"
             @change="updateProfileData"
            />
            <FormItem
             name="company_affil_num"
             label="Number"
             :value="profileData.company_affil_num"
             :read-only="isFormInactive"
             placeholder="Affiliation number"
             @change="updateProfileData"
            />
          </div>
          <div class="columns">
            <FormItem
             name="travel_agent_id"
             label="Travel Agent ID"
             :value="profileData.travel_agent_id"
             :read-only="isFormInactive"
             @change="updateProfileData"
            />
          </div>
        </fieldset>
        <fieldset v-if="!role && profileData.is_company || (role && ['carriers', 'central_agents', 'brokers', 'stakeholders'].includes(role)) && profileData.is_company">
          <legend>Bank details</legend>
          <div class="columns">
            <FormItem
             name="bank_name"
             label="Name"
             :value="profileData.bank_name"
             :read-only="isFormInactive"
             @change="updateProfileData"
            />
            <FormItem
             name="account_num"
             label="Account number"
             :value="profileData.account_num"
             :read-only="isFormInactive"
             @change="updateProfileData"
            />
          </div>
          <div class="columns">
            <FormItem
             name="bank_address"
             label="Address"
             :value="profileData.bank_address"
             :read-only="isFormInactive"
             @change="updateProfileData"
            />
            <FormItem
              name="bank_country_iso"
              label="Country"
              :value="profileData.bank_country"
              :read-only="isFormInactive"
              is-country-select
              @change="updateProfileData"
            />
          </div>
          <div class="columns">
            <FormItem
             name="iban"
             label="IBAN"
             :value="profileData.iban"
             :read-only="isFormInactive"
             @change="updateProfileData"
            />
            <FormItem
             name="swiftbic"
             label="SWIFT/BIC"
             :value="profileData.swiftbic"
             :read-only="isFormInactive"
             @change="updateProfileData"
            />
          </div>
        </fieldset>
        <div v-if="role" class="has-text-centered">
          <a class="button is-primary is-outlined" @click="$emit('close')">Hide profile info</a>
        </div>
      </form>
      <div v-if="!isEmbedded" class="contactbtn columns is-centered">
        <button v-if="!isSelf"
          :is-loading="isSending"
          class="button is-danger is-medium"
          @click="confirmDeleteContact"
        >
          Remove Contact
        </button>
        <b-dropdown
          v-if="isSelf && otherProfiles.length > 0"
          v-model="selectedOtherProfiles"
          aria-role="menu"
          @change="(val) => confirmMergeProfile(val)"
        >
          <button
            slot="trigger"
            class="button is-warning is-medium"
            :is-loading="isSending"
          >
              <span>Delete and merge with…</span>
              <b-icon icon="caret-down"></b-icon>
          </button>
          <b-dropdown-item
            v-for="p in otherProfiles"
            :key="p.id"
            aria-role="menuitem"
            :value="p"
          >
            {{ p.full_name ? p.full_name : 'Profile: ' + p.profile_name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
    <div v-else>
      <div class="columns">
        <div class="column has-text-centered filler">
          <b-icon
            icon="circle-notch"
            type="is-grey"
            size="is-large"
            custom-class="fa-spin"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Pictogram from '@/components/UI/Pictogram'
import Actions from '@/store/actions'
import DateFormatters from '@/helpers/date_formatters'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileForm',
  components: {
    Pictogram
  },
  props: {
    profileId: {
      type: Number,
      default: null,
    },
    profileVersion: {
      type: Number,
      default: undefined
    },
    role: {
      type: String
    },
    isSelf: {
      type: Boolean,
      default: true
    },
    suggestMerging: {
      type: Boolean,
      default: false
    },
    isEmbedded: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      isModalActive: false,
      contactProfiles: [],
      // profileData: {},
      isSending: false,
      isFetching: false,
      selectedOtherProfiles: null,
      showSuggestMerging: this.suggestMerging,
    }
  },
  computed: {
    ...mapGetters([
      'userProfiles',
      'userId',
    ]),
    isFormInactive () {
      return this.profileData && (this.profileData.locked || !this.profileData.write_access)
    },
    otherProfiles () {
      return this.userProfiles.filter((p) => p.id !== this.profileId)
    },
    profileData () {
      if (this.isSelf) {
        return this.userProfiles.find((p) => p.id === this.profileId)
      } else {
        return this.$store.getters.profileByIdVersion(this.profileId, this.profileVersion)
        // const p = this.$store.getters.allProfiles[0]
        // console.log(p, this.$store.getters.allProfiles)
        // return p
      }
    }
  },
  created () {
    if (!this.isSelf) {
      this.isFetching = true
      this.$store.dispatch(Actions.GET_PROFILE, { profileId: this.profileId, profileVersion: this.profileVersion })
        .finally(() => {
          this.isFetching = false
        })
    }
  },
  methods: {
    ...DateFormatters,
    updateProfileData (profileVar, val) {
      this.isSending = true
      this.$store.dispatch(Actions.UPDATE_PROFILE, {
        profileId: this.profileId,
        profileData: { [profileVar]: val }
      })
        .then((data) => {
          this.$emit('profile-changed', data)
        })
        .finally(() => {
          this.isSending = false
        })
    },
    confirmMergeProfile (withProfile) {
      const name = withProfile.full_name ? withProfile.full_name : 'Profile: ' + withProfile.profile_name
      this.$buefy.dialog.confirm({
        title: 'Merge profile',
        message: `Merge this profile with <i>${name}</i> and delete it?`,
        confirmText: 'Merge',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          this.isFetching = true
          this.$store.dispatch(Actions.MERGE_USER_PROFILES, { profileId: this.profileId, withProfileId: withProfile.id })
            .then(() => {
              this.$router.push({ name: 'ProfileDetail', params: { profileId: withProfile.id } })
            })
            .finally(() => {
              this.isFetching = false
              this.selectedOtherProfiles = null
              this.showSuggestMerging = false
            })
        },
        onCancel: () => { this.selectedOtherProfiles = null } // needed to prevent selection getting stuck
      })
    },
    confirmDeleteContact () {
      this.$buefy.dialog.confirm({
        title: 'Deleting contact',
        message: 'Are you sure you want to <b>remove</b> ' + this.profileData.full_name + ' from your contacts.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isFetching = true
          this.$store.dispatch(Actions.GET_USER_CONTACTS)
            .then((contacts) => {
              var fromProfileId = -1

              for (const cs of contacts) {
                if (cs.contacts.filter(c => c.id === this.profileId).length === 1) {
                  fromProfileId = cs.from_profile_id
                  break
                }
              }
              const userprofileData = {
                userId: this.userId,
                profileId: fromProfileId,
                contactProfileId: this.profileId
              }
              this.$store.dispatch(Actions.DELETE_CONTACT, userprofileData)
                .then(() => {
                  this.$router.push({ name: 'ContactsList' })
                })
                .finally(() => { this.isFetching = false })
            })
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
  .contact-name {
    font-size: 24px;
    margin-top: 17px;
  }
  .table-spacing {
    margin-bottom: 20px;
  }
  .form-control {
  padding: 0rem 0.75rem;
  }
  .contactbtn {
    padding: 1.5rem 0.5rem;
    button {
      margin: 0 1rem;
    }
  }
</style>
