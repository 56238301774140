export function nestedVal (obj, args) {
  // var args = Array.prototype.slice.call(arguments, 1)
  if (typeof args === 'string') {
    args = args.split('.')
  }
  for (var i = 0; i < args.length; i++) {
    if (!obj || !(args[i] in obj)) {
      return undefined
    }
    obj = obj[args[i]]
  }
  return obj
}

export function nestedTrue (obj, args) {
  return nestedVal(obj, args) === true
}
