<template>
  <button
    type="submit"
    :class="[
      'button',
      'is-primary',
      { 'is-loading': isLoading },
      { 'has-background-grey-light': isDisabled },
      { 'has-text-light': isDisabled }
    ]" :disabled="isDisabled">
      <slot />
    </button>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
  }
}
</script>
