<template>
  <section class="section">
    <div class="container">
      <div v-if="!isFetching" class="tab-box section-box">
        <div>
          <nav class="tab-box__tabs">
            <router-link
              v-for="profile in userProfiles"
              :key="profile.id"
              :to="{name: 'ProfileDetail', params: {'profileId': profile.id }}"
              class="tab-box__tab"
              active-class="tab-box__tab--active"
            >{{ profile.profile_name }} </router-link>
            <a
              key="create"
              class="tab-box__tab"
              title="Add Profile"
              :disabled="isFetching"
              @click="createNewProfile"
            >
              <b-icon icon="plus" />
            </a>
          </nav>
        </div>
        <div class="tab-box__tab-content">
          <profile-form :profile-id="profileId" :is-self="true" :suggest-merging="suggestMerging"></profile-form>
        </div>
      </div>
      <Loading v-else />
    </div>
  </section>
</template>

<script>

import ProfileForm from '@/components/forms/profile/ProfileForm'
import Actions from '@/store/actions'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileList',
  components: {
    ProfileForm
  },
  props: {
    profileId: {
      type: Number
    },
    suggestMerging: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFetching: false
    }
  },
  computed: {
    ...mapGetters([
      'userProfiles',
      'mainProfile',
    ])
  },
  watch: {
    $route (to, from) {
      if (to.name === 'ProfileList') {
        this.$router.replace({
          name: 'ProfileDetail',
          params: { profileId: this.mainProfile.id, suggestMerging: this.suggestMerging }
        })
      }
    }
  },
  created () {
    this.isFetching = true
    this.$store.dispatch(Actions.GET_USER_PROFILES)
      .finally(() => {
        this.isFetching = false
      })

    if (!this.profileId) {
      if (this.suggestMerging) {
        this.$store.dispatch(Actions.GET_USER_PROFILES)
          .then(() => {
            this.$router.replace({
              name: 'ProfileDetail',
              params: { profileId: this.userProfiles[this.userProfiles.length - 1].id, suggestMerging: true }
            })
          })
      } else {
        this.$router.replace({
          name: 'ProfileDetail',
          params: { profileId: this.mainProfile.id }
        })
      }
    }
  },
  methods: {
    createNewProfile () {
      this.isFetching = true
      this.$store.dispatch(Actions.CREATE_USER_PROFILE, {})
        .then((profileData) => {
          this.$router.push({ name: 'ProfileDetail', params: { profileId: profileData.id } })
          this.isFetching = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .tab-box {
    padding-top: 0;
    &__tabs {
      display: flex;
      flex-flow: nowrap;
      background-color: white;
      margin-bottom: 1.5rem;
    }
    &__tab {
      display: flex;
      height: 4rem;
      flex-flow: column nowrap;
      justify-content: center;
      padding: 0.5rem .75rem;
      color: lightgrey;
      font-weight: 600;
      border-bottom: 3px solid transparent;
      transition: all .2s ease;

      &--active {
        border-bottom: 3px solid $primary;
        color: black !important;
      }
    }
    &__tab:hover {
      color: $primary;
    }
    &__tab-content {
      background-color: white;
    }
  }

  .add-profile {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
</style>
