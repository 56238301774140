import axios from '@/api/config'

export const getServices = cruiseId => {
  return axios.get(`/cruise/${cruiseId}/service/`)
}

export const postService = (cruiseId, serviceData) => {
  return axios.post(`/cruise/${cruiseId}/service/`, serviceData)
}

export const putService = (cruiseId, serviceId, serviceData) => {
  return axios.put(`/cruise/${cruiseId}/service/${serviceId}`, serviceData)
}

export const deleteService = (cruiseId, serviceId) => {
  return axios.delete(`/cruise/${cruiseId}/service/${serviceId}`)
}
