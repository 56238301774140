import axios from '@/api/config'
import Actions from '@/store/actions'

const MU_SET_CONTACTS = 'μ: Set contacts'
const MU_ADD_CONTACT = 'μ: Add contact'
const MU_DELETE_CONTACT = 'μ: Delete contact'

const contactModule = {
  state: {
    contacts: [],
    contact: null
  },
  mutations: {
    [MU_SET_CONTACTS] (state, contacts) {
      state.contacts = contacts
    },
    [MU_ADD_CONTACT] (state, contact) {
      state.contacts = [...state.contacts, { contacts: [{ ...contact }] }]
    },
    [MU_DELETE_CONTACT] (state, contactId) {
      state.contacts = state.contacts.filter(
        (contact) => Number(contact.id) !== Number(contactId)
      )
    }
  },
  actions: {
    [Actions.GET_USER_CONTACTS] ({ commit, getters }) {
      return axios.get(`/user/${getters.userId}/contact/`).then(({ data }) => {
        commit(MU_SET_CONTACTS, data)
        return data
      })
    },
    [Actions.CREATE_USER_CONTACT] ({ commit, getters, dispatch }, contactData) {
      return axios
        .post(`/user/${getters.userId}/contact/`, contactData)
        .then(({ data }) => {
          commit(MU_SET_CONTACTS, data)
          return data.flatMap((g) => g.contacts).find((c) => c.is_new)
        })
    },
    [Actions.DELETE_CONTACT] (
      { commit, state, watch, getters },
      { profileId, contactProfileId }
    ) {
      commit(MU_DELETE_CONTACT, contactProfileId)
      return axios
        .delete(
          `/user/${getters.userId}/profile/${profileId}/contact/${contactProfileId}`
        )
        .then(({ data }) => {
          commit(MU_SET_CONTACTS, data)
        })
    }
  },
  getters: {
    getContactById: (state) => (id) => {
      return state.contacts.find((contact) => contact.id === Number(id))
    }
  }
}

export default contactModule
