<template>
  <section class="section">
    <div class="container">
      <nav class="level is-mobile">
        <div class="level-left"/>
        <div class="level-right">
          <div class="level-item">
            <BackButton @click.native="onClickBack" />
          </div>
        </div>
      </nav>
      <div class="section-box">
        <YachtCreateForm @yacht-created="handleYachtCreated"></YachtCreateForm>
      </div>
    </div>
  </section>
</template>

<script>
import YachtCreateForm from '@/components/forms/yacht/YachtCreateForm'

export default {
  name: 'YachtCreate',
  components: {
    YachtCreateForm
  },
  methods: {
    onClickBack () {
      this.$router.push({ name: 'YachtList' })
    },
    handleYachtCreated (yacht) {
      this.$router.push({ name: 'YachtDetail', params: { id: yacht.id } })
    }
  }
}
</script>
<style>
</style>
