<template>
  <header class="top-section-header">
    <nav class="navbar navbar-expand-lg " style="background-color:transparent">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"><img src="@/assets/img/image/logo.svg" /></a>

        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul class="navbar-nav m-auto mb-2 mb-lg-0 fnt-p col-lg-6">
            <li class="nav-item">
             <a class="nav-link" v-scroll-to="'#banner1'">Wolfgang pro</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" v-scroll-to="'#Clients1'">Clients</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" v-scroll-to="'#Price1'">Prices</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" v-scroll-to="'#About1'">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" v-scroll-to="'#News1'">News</a>
            </li>

          </ul>
          <div class="d-flex align-items-center" >
            <a class="signin fnt-lib-i" id="signin-trigger">Login</a>
            <button class="slide signup fnt-lib-i" id="signup-trigger">Sign up</button>

          </div>
        </div>

        <!-- mobile view  -->

        <div class="button_container" id="toggle">
          <span class="top"></span>
          <span class="middle"></span>
          <span class="bottom"></span>
        </div>

        <div class="overlay" id="overlay">
          <nav class="overlay-menu">
            <ul>
              <li class="nav-item">
                <a class="nav-link"  id="scroll-banner" aria-current="page" href="#">Wolfgang pro</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="scroll-client" href="#" >Clients</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="scroll-price" href="#" >Prices</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="scroll-about" href="#">About</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" id="scroll-news" href="#">News</a>
              </li>
              <li>
                <div class="d-flex align-items-center flex-column" >

                  <button class="slide signup fnt-lib-i mob-signup " id="mobile-signup" >Sign up</button>
                  <a href="#" class="signin fnt-lib-i mob-signin" id="mobile-login">Login</a>
                </div>
              </li>

            </ul>
          </nav>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import jQuery from 'jquery'
const $ = jQuery
export default {
  mounted () {
    $(window).scroll(function () {
      if ($(document).scrollTop() > 70) {
        $('header').addClass('darknav')
      }
      if ($(document).scrollTop() < 70) {
        $('header').removeClass('darknav')
      }
    })

    $(window).scroll(function () {
      if ($(document).scrollTop() > 5000) {
        $('.last-sec').addClass('active')
      }
    })
    $('#toggle').click(function () {
      $(this).toggleClass('active')
      $('#overlay').toggleClass('open')
    })
    $('#scroll-banner').click(function () {
      $(this).removeClass('active')
      $('#overlay').removeClass('open')
      $('.button_container').removeClass('active')
      $('html, body').animate({
        scrollTop: $('#banner1').offset().top
      })
    })
    $('#scroll-client').click(function () {
      $(this).removeClass('active')
      $('#overlay').removeClass('open')
      $('.button_container').removeClass('active')
      $('html, body').animate({
        scrollTop: $('#Clients1').offset().top
      })
    })
    $('#scroll-price').click(function () {
      $(this).removeClass('active')
      $('#overlay').removeClass('open')
      $('.button_container').removeClass('active')
      $('html, body').animate({
        scrollTop: $('#Price1').offset().top
      })
    })
    $('#scroll-about').click(function () {
      $(this).removeClass('active')
      $('#overlay').removeClass('open')
      $('.button_container').removeClass('active')
      $('html, body').animate({
        scrollTop: $('#About1').offset().top
      })
    })
    $('#scroll-news').click(function () {
      $(this).removeClass('active')
      $('#overlay').removeClass('open')
      $('.button_container').removeClass('active')
      $('html, body').animate({
        scrollTop: $('#News1').offset().top
      })
    })
    $('#mobile-signup').click(function () {
      $(this).removeClass('active')
      $('#overlay').removeClass('open')
      $('.button_container').removeClass('active')
      $('#signup-modal').addClass('active')
      // $('body').append('<div class="modal-overlay"></div>')
    })
    $('.close-modal').click(function () {
      $('#signup-modal').removeClass('active')
      $('.modal-overlay').remove()
    })

    $('#mobile-login').click(function () {
      $(this).removeClass('active')
      $('#overlay').removeClass('open')
      $('.button_container').removeClass('active')
      $('#signin-modal').addClass('active')
      // $('body').append('<div class="modal-overlay"></div>')
    })
    $('.close-modal').click(function () {
      $('#signin-modal').removeClass('active')
      $('.modal-overlay').remove()
    })
  }
}
</script>

<style scoped src="@/assets/css/style.css">

</style>