<template>
  <form data-test="yacht-create-form" @submit.prevent="send" >
    <header class="modal-card-head">
      <p class="modal-card-title">New Yacht</p>
    </header>
    <section class="modal-card-body">
      <b-field
        expanded
        label="Name of the yacht"
      >
        <b-input
          v-model="yachtData.name"
          data-test="name"
          type="text"
          required
        />
      </b-field>

      <b-field
        expanded
        label="Port of registry"
      >
        <b-input
          v-model="yachtData.port_of_registry"
          data-test="port_of_registry"
          type="text"
          required
        />
      </b-field>

      <b-field
        expanded
        label="Type"
        >
        <b-select
          v-model="yachtData.type"
          data-test="type"
          expanded
          placeholder="Select a yacht type"
          required
        >
          <option value="MOTOR">Motor Yacht</option>
          <option value="SAIL">Sail Yacht</option>
        </b-select>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <section class="section">
        <button class="button is-danger" type="button" @click="$parent.close()">
          Cancel
        </button>
        <SubmitButton :is-loading="isSending" :is-disabled="isSending">
          Create
        </SubmitButton>
      </section>
    </footer>
  </form>
</template>
<script>
import Actions from '@/store/actions'

export default {
  name: 'YachtCreateForm',
  data () {
    return {
      yachtData: {
        name: '',
        type: null
      },
      yachtId: null,
      isSending: false,
      createdYacht: null
    }
  },
  methods: {
    send () {
      this.isSending = true
      this.$store.dispatch(Actions.CREATE_YACHT, this.yachtData)
        .then(yacht => {
          this.$emit('yacht-created', yacht)
          this.createdYacht = yacht
        })
        .finally(() => {
          this.isSending = false
          this.type = null
        })
    },
  }
}
</script>
