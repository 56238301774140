<template>
  <div class="signup-and-signin-modal" id="contact-us-modal">
    <a class="close-modal"><img src="@/assets/img/image/BT_close.svg"></a>
    <form ref="form" @submit.prevent="sendEmail">
      <h2>Contact Us</h2>
      <b-field class="c-field"
        label="Full Name"
      >
        <b-input
          v-model="name"
          autocomplete="name"
          placeholder="Your answer"
          name="user_name"
          required
        />
      </b-field>
      <b-field class="c-field"
        label="Company "
      >
      <b-input
          name="user_company"
          v-model="company"
          autocomplete="Company"
          placeholder="Your answer"
          required
        />
      </b-field>
      <b-field class="c-field"
        label="Email"
      >
        <b-input
          name= "user_email"
          v-model="email"
          type="email"
          autocomplete="email"
          placeholder="Your answer"
          required
        />
      </b-field>
      <b-field class="c-field"
        label="Would you like to : "
      ></b-field>
      <div >
        <div>
  <input name= "checked" type="checkbox" value="Get in touch with our team / receive a call back" v-model="checked">  Get in touch with our team / receive a call back</div>
  <div class="others_checkbox"><input  name= "checked" type="checkbox" value="Receive regular news" v-model="checked">  Receive regular news</div>
  <div><input type="checkbox" value="Others" v-model="checked">  Others :<input class="others_input" name= "checked" type="text" v-model = "others"></div>
</div>

      <b-field class="c-field"
        label="Leave us your message or your inquiry :"
      >
        <b-input
          name="user_message"
          v-model="message"
          autocomplete="message"
          placeholder="Your answer"
        />
      </b-field>
      <div style="text-align: center;" v-if="show">Thank You!<br/>
We will get in touch shortly</div>
      <br/>

<button class="create-account-btn" :disabled="!checked" @submit.prevent="sendEmail" >Submit</button>

  </form>
  </div>
</template>
<script>

import emailjs from 'emailjs-com'

export default {
  data () {
    return {

      name: '',
      company: '',
      email: '',
      message: '',
      show: false,
      checked: [],
      others: '',
      ServiceID: process.env.VUE_APP_EMAILJS_SERVICEID,
      TemplateID: process.env.VUE_APP_EMAILJS_TEMPLATEID,
      Publickey: process.env.VUE_APP_EMAILJS_PUBLICKEY

    }
  },
  methods: {
    sendEmail (e) {
      if (this.checked !== '') {
        try {
          emailjs.sendForm(this.ServiceID, this.TemplateID, e.target,
            this.Publickey, {
              Contact_name: this.user_name,
              email: this.user_email,
              company: this.user_company,
              message: this.user_message,
              checkbox: this.checked,
            })
        } catch (error) {
          console.log({ error })
        }
        this.show = true
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.company = ''
      this.message = ''
      this.checked = ''
      this.others = ''
    },
  }

}

</script>

<style scoped src="@/assets/css/style.css">

</style>