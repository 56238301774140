<template>
  <form @submit.prevent="send">
    <header class="modal-card-head">
      <p class="modal-card-title">Add Payment</p>
    </header>
    <section class="modal-card-body">
      <RequestError :error="$store.state.error.currentError" :simple="true" />
      <p v-if="paymentData.amount < 0" class="is-size-7">Negative amount indicates a credit</p>
      <b-field>
        <p class="control">
          <span class="button is-static">{{ currency }}</span>
        </p>
        <p class="control is-expanded">
          <b-input
            v-model="paymentData.amount"
            expanded
            type="number"
            step="0.01"
            :max="callBalance() > 0 ? callBalance() : 0"
            :min="callBalance() < 0 ? callBalance() : 0"
            placeholder="Amount"
            required
            custom-class="price"
          />
        </p>
      </b-field>
      <b-field>
        <p class="control is-expanded">
          <b-datepicker
            icon="calendar"
            pack="fas"
            :value="string2date(paymentData.date_paid)"
            :first-day-of-week="1"
            :date-formatter="localeDateLong"
            placeholder="Payment date…"
            required
            editable
            @input="val => paymentData.date_paid = val"
          />
        </p>
      </b-field>
      <b-field>
        <b-select
          v-model="paymentData.client_id"
          expanded
          placeholder="On behalf of…"
        >
          <option
            v-for="o in clients"
            :key="o.id"
            :value="o.id">
            {{ o.full_name }}
          </option>
        </b-select>
      </b-field>
      <b-field>
        <p v-if="paymentData.upload" class="section tags">
          <b-tag
            size="is-medium"
          ><b-icon
            icon="file"
            size="is-small"
          /> {{ paymentData.upload.name }}
            <button class="delete is-small"
                type="button"
                @click="paymentData.upload = null"
            >
            </button>
          </b-tag>
        </p>
        <b-upload v-else v-model="paymentData.upload" drag-drop>
          <p class="section">
            <b-icon
                 icon="upload"
                 size="is-small"
            /> Drop file here or click to upload
           </p>
        </b-upload>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <section class="section">
        <button class="button is-danger" type="button" @click="$parent.close()">
          Cancel
        </button>
        <button class="button is-primary" :is-loading="isSending" :is-disabled="isSending" native-type="submit">
          Add
        </button>
      </section>
    </footer>
  </form>
</template>
<script>

import DateFormatters from '@/helpers/date_formatters'
import NumFormatters from '@/helpers/num_formatters'
import Actions from '@/store/actions'

export default {
  name: 'PaymentForm',
  components: {
  },
  props: {
    call: {
      required: true
    },
  },
  data () {
    return {
      paymentData: {
        amount: this.callBalance(),
        date_paid: new Date(),
      },
      isSending: false,
    }
  },
  computed: {
    clients () {
      return this.$store.state.cruise.cruise.clients
    },
    currency () {
      return this.$store.state.cruise.cruise.currency
    },
  },
  methods: {
    ...DateFormatters,
    ...NumFormatters,
    send () {
      this.isSending = true
      this.$store.dispatch(Actions.ADD_PAYMENT, { paymentCallId: this.call.id, paymentData: this.paymentData })
        .then(paymentCall => {
          this.$parent.close()
          this.$emit('payment-created', paymentCall)
        })
        .finally(() => {
          this.isSending = false
        })
    },
    callBalance () {
      return Math.round(((this.call.amount - this.call.paid_amount) + Number.EPSILON) * 100) / 100
    },
  }
}
</script>
