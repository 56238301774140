import axios from '@/api/config'

// export const isLoggedInWithOauth = () => {
//   console.log('isLoggedInWithOauth')
//   return axios.get(`/sign/${cruiseId}/${cruiseVersion}`)
//     .catch((error) => {
//       console.log('isLoggedInWithOauth error', error)
//     })
// }

export const getContractDraft = (cruiseId, cruiseVersion) => {
  return axios.get(`/contract/${cruiseId}/${cruiseVersion}/draft`)
}

export const getContractPdf = (cruiseId, cruiseVersion) => {
  return axios.get(`/contract/${cruiseId}/${cruiseVersion}/pdf`, { responseType: 'arraybuffer' })
}

const transformRequest = (jsonData = {}) =>
  Object.entries(jsonData)
    .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
    .join('&')

export const signContractWithOauth = ({ cruiseId, cruiseVersion, userId, oauthCode, oauthState }) => {
  return axios.post(`/sign/${cruiseId}/${cruiseVersion}/by_user/${userId}`, transformRequest({
    code: oauthCode,
    state: oauthState
  }), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export const checkBillingCanSign = ({ cruiseId, cruiseVersion }) => {
  return axios.get(`/sign/${cruiseId}/${cruiseVersion}/can_sign`)
}

export const getContractSignatures = ({ cruiseId, cruiseVersion }) => {
  return axios.get(`/sign/${cruiseId}/${cruiseVersion}`)
}

export const getSignedContractPdf = ({ cruiseId, cruiseVersion, docId }) => {
  return axios.get(`/sign/${cruiseId}/${cruiseVersion}/${docId}.pdf`, { responseType: 'arraybuffer' })
}
