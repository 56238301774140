<template>
<div >
  <div class="services" >

    <div sticky-container >
      <div class="sticky" v-sticky sticky-offset="offset">
        <div id="list-example" class="list-group phone-wrap">
  <a class="list-group-item list-group-item-action  " href="#list-item-1"><img src="@/assets/img/image/SCREEN1.png"></a>
  <a class="list-group-item list-group-item-action " href="#list-item-2"><img src="@/assets/img/image/SCREEN2.png"></a>
  <a class="list-group-item list-group-item-action " href="#list-item-3"><img src="@/assets/img/image/SCREEN3.png"></a>
  <a class="list-group-item list-group-item-action last-sec" href="#list-item-4"><img src="@/assets/img/image/SCREEN5.png"
      style="padding-left: 25px;"></a>
</div>
      </div>

      <!-- locally installed, namespaced sticky -->
      <div >

    </div>
    <div ></div>
<div>

<div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-offset="100" class="scrollspy-example" tabindex="0"
  style="margin-top: -555px;">
  <div class="slides" id="list-item-1">
    <div class="mobile-view-phone">
      <img loading="lazy" src="@/assets/img/image/PHONE1.png"/>
    </div>
    <div class="row m-0">
      <div class="col-md-12 col-lg-4 sv-txt-padding-left sv-txt">

        <h4 class="fnt-lib-r">Register and boost your business</h4>
        <ul class="fnt-p" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000"
          data-aos-delay="1000">
          <li>Register now for free to benefit from fast-track services when season comes</li>
          <li>Cruise more! 9-day cruises for the cost of a 7-day charter</li>
          <li>Attract retail clients with competitive pricing without affecting your net margin</li>
        </ul>
      </div>
      <div class="col-md-12 col-lg-6 illu-wrap">
        <img loading="lazy" src="@/assets/img/image/ILLU1.svg" class="svg-img" />
      </div>
    </div>

  </div>
  <div class="slides boat1_slides" id="list-item-2">
    <div class="row m-0">

      <div class="col-md-12 col-lg-6 p-0" data-aos="zoom-in" data-aos-easing="linear" data-aos-delay="500"
        data-aos-duration="1000">
        <div class="boat1_video_slide">
          <video  playsinline player.controls="false" class="boat1_video" style="pointer-events: none;" muted loop preload autoplay ><source src="../assets/img/image/boat1.mp4" type="video/mp4"></video>
        </div>
        <div class="mobile-view-phone">
          <img loading="lazy" src="@/assets/img/image/PHONE2.png"/>
        </div>
      </div>

      <div class="col-md-12 col-lg-4 sv-txt sv-txt-padding-left sv-boat-text" style=" margin-left:auto ;">

        <h4 class="fnt-lib-r">Planning & contracting: simple, fast and safe</h4>
        <ul class="fnt-p" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000"
          data-aos-delay="500">
          <li>Simple: we handle the drafting, updating and all calculations live for you</li>
          <li>Fast: answer your clients inquiries and change of plans in record time</li>
          <li>Safe: all calculations and optimizations are backed by our proprietary platform</li>
        </ul>

      </div>
    </div>
  </div>
  <div class="slides" id="list-item-3">
    <div class="row m-0">
      <div class="col-md-12 col-lg-4 sv-txt-padding-left sv-txt">

        <h4 class="fnt-lib-r">The expert in cruise and transport contract</h4>
        <ul class="fnt-p" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000"
          data-aos-delay="1000">
          <li>The most efficient legal and fiscal framework available</li>
          <li>Our contract templates are vetted by the French fiscal authorities</li>
          <li>We help safeguard you against liabilities and risk of re-qualification</li>
        </ul>

      </div>
      <div class="col-md-12 col-lg-6 illu-wrap">
        <div class="mobile-view-phone mt-2">
          <img loading="lazy" src="@/assets/img/image/PHONE3.png"/>
        </div>
        <img loading="lazy" src="@/assets/img/image/ILLU2.svg" class="svg-img" />
      </div>
    </div>
  </div>
  <div class="slides" id="list-item-4">
    <div class="row m-0">

      <div class="col-md-12 col-lg-6 p-0" data-aos="zoom-in" data-aos-easing="linear" data-aos-delay="500"
        data-aos-duration="1000">
        <div class="boat1" :style="{backgroundImage: 'url('+require('../assets/img/image/slidelast.png')+')'}
      "></div>
      <div class="mobile-view-phone ">
        <img loading="lazy" src="@/assets/img/image/PHONE4.png"/>
      </div>
      </div>

      <div class="col-md-12 col-lg-4 sv-txt-padding-right sv-txt sv-boat-text" style=" margin-left:auto ;">

        <h4 class="fnt-lib-r">All-inclusive service for your peace of mind</h4>
        <p data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000"
          data-aos-delay="500">
        <ul class="fnt-p">
          <li>We collaborate with your team and minimize your workload</li>
          <li>All documents included: contracts, addenda and reports,  digitally signed and traced</li>
          <li>24/7 compliance supervision and hotline during the cruise</li>
        </ul>
        <span style="color: #a8b1bb; font-size: 1.2em">You can focus on your clients. We handle the contracts!</span>
      </p>
      </div>
    </div>
  </div>
</div>
</div></div>  </div></div>
</template>

<script>
export default {
  data () {
    return {
      offset: {
        bottom: 700,
        top: 350
      }
    }
  },
  components: {

  },
  mounted () {
    const startAnimation = (entries, observer) => {
      entries.forEach(entry => {
        entry.target.classList.toggle('svg-img', entry.isIntersecting)
      })
    }

    const observer = new IntersectionObserver(startAnimation)
    const options = { root: null, rootMargin: '0px', threshold: 1 }

    const elements = document.querySelectorAll('.svg-img')
    elements.forEach(el => {
      observer.observe(el, options)
    })
  }
}
</script>

<style scoped src="@/assets/css/style.css">

  .sticky{

    >*{

      display: flex;
      justify-content: center;

     }
  }

  </style>