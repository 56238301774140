
export default {
  formatNumLong (value) {
    if (value === null || value === undefined || value.toString === undefined) return null
    var parts = value.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  },
  formatPercent (value) {
    if (value === null || value === undefined || value.toFixed === undefined) return null
    return (value * 100).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  formatNum (value) {
    if (value === null || value === undefined || value.toFixed === undefined) return null
    return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  formatNumRound (value) {
    if (value === null || value === undefined || value.toFixed === undefined) return null
    return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  formatFuelUnit (value) {
    if (value === null || value === undefined || value.toFixed === undefined) return null
    return value.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' L'
  },
  formatFuel (value) {
    if (value === null || value === undefined || value.toFixed === undefined) return null
    return value.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  formatPrice (value) {
    if (value === null || value === undefined || value.toFixed === undefined) return null
    return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  formatDist (value) {
    if (value === null || value === undefined || value.toFixed === undefined) return null
    return value.toFixed(2)
  },
  formatNullNA (value) {
    if (value === null || value === undefined) return 'n/a'
    return value
  }
}
