<template>
  <div class="quickview" data-test="quickview" :aria-busy="isFetchingRoleStatuses || isFetchingRoleStatuses">
    <section class="columns is-mobile">
      <div class="column" data-test="clients">
        <div class="columns is-vcentered is-centered has-text-centered-mobile">
          <div class="column is-narrow">
            <pictogram><b-icon icon="user-alt"></b-icon></pictogram>
          </div>
          <div class="column is-narrow">
            <h4 class="has-text-weight-semibold has-text-grey-light is-uppercase">Client<template v-if="cruise.clients.length > 1">s</template></h4>
            <p v-if="cruise.clients.length === 1">{{ cruise.clients[0].full_name }}</p>
            <template v-else-if="cruise.clients.length === 2">
              <p>{{ cruise.clients[0].full_name }}</p>
              <p>{{ cruise.clients[1].full_name }}</p>
            </template>
            <template v-else-if="cruise.clients.length > 2">
              <p>{{ cruise.clients[0].full_name }}</p>
              <p>&amp; <router-link class="has-text-weight-semibold" :to="{name: 'ClientsSection'}">{{ cruise.clients.length - 1 }} others</router-link></p>
            </template>
            <p v-else-if="cruise.write_access" class="has-text-grey-light"><router-link :to="{name: 'ClientsSection'}">Set client</router-link></p>
            <p v-else class="has-text-grey-light">No client assigned yet.</p>
          </div>
        </div>
      </div>
      <div class="column" data-test="agreement-status">
        <div class="columns is-vcentered is-centered has-text-centered-mobile">
          <div class="column is-narrow">
            <pictogram><b-icon icon="pencil-alt"></b-icon></pictogram>
          </div>
          <div class="column is-narrow">
            <h4 class="has-text-weight-semibold has-text-grey-light is-uppercase">Agreement status</h4>
            <div v-if="cruise.generated_versions_len > 0" class="generated-versions">
              <router-link
              :to="{name: 'ContractSection', params: { cruiseVersion: cruise.first_generated_version }}"
              ><span class="tag is-primary">Contract</span></router-link>
              <template v-if="cruise.generated_versions_len > 1">
                <router-link :to="{
                          name: 'ContractSection',
                          params: { cruiseVersion: cruise.last_generated_version }
                        }">
                  <span class="tag is-primary">{{cruise.generated_versions_len-1}} Addend{{
                    cruise.generated_versions_len == 2 ? 'um' : 'a'
                  }}</span>
                </router-link>
              </template>
            </div>
            <template v-if="cruise.status=='DRAFT'||cruise.status=='LOCKED'">
              <router-link :to="{name: 'ContractSection'}">
                <span :class="[
                  'tag',
                  { 'is-light': cruise.status=='DRAFT' },
                  { 'is-success': cruise.status=='LOCKED' }
                ]">{{ (cruise.status=='LOCKED' ? 'Approved ' : ' ') + (cruise.generated_versions_len > 0 ? 'Addendum' : '') + (cruise.status=='DRAFT' ? ' Draft' : ' ') }}</span>
              </router-link>
              <button v-if="cruise.write_access" type="button" :class="[
                'button',
                'is-success',
                'is-small',
                {'is-loading': isGeneratingContract}
              ]"
              :disabled="isGeneratingContract"
              @click="handleGenerateContract">
                Generate
              </button>
            </template>
            <button v-else-if="cruise.write_access && (cruise.status=='CONTRACT' || cruise.status=='ADDENDUM')"
              type="button"
              :class="['button','is-small', 'is-success', {'is-loading': isUnlocking} ]"
              :disabled="isUnlocking"
              @click="handleUnlock"
             >
             New addendum
           </button>
          </div>
        </div>
      </div>
      <div class="column" data-test="contract">
          <div class="columns is-vcentered is-centered has-text-centered-mobile">
            <div class="column is-narrow">
              <pictogram>
                <b-icon :icon="currencyIcon" />
              </pictogram>
            </div>
            <div class="column is-narrow">
              <h4 class="has-text-weight-semibold is-uppercase has-text-grey-light">{{ cruise.contract_type }}</h4>
              <template v-if="cruise.total_agreement_price">
              Total (incl. fuel): <strong data-test="total-agreement-price">{{ currencySymbol }}{{ formatPrice(cruise.total_agreement_price) }}</strong><br/>
              </template>
              Including <strong  data-test="effective-vat">{{ cruise.effective_vat_rate != null ? (cruise.effective_vat_rate * 100).toPrecision(3) + '%' : 'n/a' }}</strong> tax
            </div>
          </div>
        </div>
    </section>
    <section class="columns">
      <div class="column" data-test="schedule">
        <h3 class="title is-5">Schedule</h3>
        <template v-if="cruise.delivery_date && cruise.redelivery_date">
          <p class="schedule-text">
            {{ formatDateGMT(cruise.delivery_date, false).format('MMM DD, YYYY HH:mm UTC') }} - {{ formatDateGMT(cruise.redelivery_date, false).format('MMM DD, YYYY HH:mm UTC') }}
          </p>
          <v-calendar
            v-if="cruise"
            class="calendar"
            is-expanded
            :attributes="datepickerAttributes"
            :theme-styles="datepickerStyles"
            :from-page="initialDatePage"
          />
        </template>
        <template v-else>
          <p v-if="waypoints && waypoints.length" class="has-text-grey-light">Set passenger <router-link :to="{name: 'ClientsSection'}">loading times</router-link> to create your schedule.</p>
          <p v-else-if="cruise.write_access" class="has-text-grey-light">Start <router-link :to="{name: 'MappingSection'}">adding waypoints</router-link> to create your schedule.</p>
        </template>
      </div>
      <div class="column" data-test="map">
        <h3 class="title is-5">Cruise</h3>
        <div v-if="waypoints && waypoints.length" class="map-container">
          <waypoints-map v-if="!isLoadingWaypoints" :style="{'max-height': '100%', 'min-height': 'unset'}" :zoom-drag-enabled="false" :is-map-active="false" />
        </div>
        <p v-else-if="cruise.write_access" class="has-text-grey-light">Start <router-link :to="{name: 'MappingSection'}">mapping</router-link> your cruise.</p>
        <p v-else class="has-text-grey-light">This cruise does not have any embarkation or disembarkation scheduled yet.</p>
      </div>
      <div class="column" data-test="yacht">
        <h3 class="title is-5">Yacht&nbsp;&nbsp;
          <span v-if="yacht" class="has-text-weight-normal has-text-grey-light">|&nbsp;&nbsp;{{ yacht.type === 'SAIL' ? 'SY' : 'MY' }} {{ yacht.name }}</span>
        </h3>
        <div v-if="yacht" class="yacht-picture">
          <img v-if="yacht.pictures && yacht.pictures.length" :src="`${serverURL}${yacht.pictures[0].public_url}`"/>
          <img v-else class="is-hidden-mobile" :src="require('@/assets/img/yacht/empty.png')" />
        </div>
        <p v-else-if="cruise.write_access" class="has-text-weight-normal has-text-grey-light">Select <router-link :to="{name: 'YachtSection'}">a yacht</router-link> for your cruise.</p>
        <p v-else class="has-text-weight-normal has-text-grey-light">No yacht has been selected for this cruise.</p>
      </div>
    </section>
    <section v-if="(userRoleStatuses && userRoleStatuses.length) || (othersRoleStatuses && othersRoleStatuses.length)" data-test="role-statuses">
      <h3 class="title is-5">Parties to the Agreement</h3>
      <div v-if="isFetchingRoleStatuses" aria-busy="true">
        <b-skeleton width="20%" animated></b-skeleton>
        <b-skeleton width="40%" animated></b-skeleton>
        <b-skeleton width="30%" animated></b-skeleton>
      </div>
      <div v-else class="columns is-mobile is-multiline">
        <template v-if="userRoleStatuses.length">
          <div v-for="(roleStatus, index) in userRoleStatuses" :key="`user-${index}`" class="column is-6-mobile is-4-tablet is-3-desktop">
            <role-status-card is-user :role-status="roleStatus" :cruise-status="cruise.status" @approval-change="fetchRoleStatuses" />
          </div>
        </template>
        <template v-if="othersRoleStatuses.length">
          <div v-for="(roleStatus, index) in othersRoleStatuses" :key="`other-${index}`" class="column is-6-mobile is-4-tablet is-3-desktop">
            <role-status-card :role-status="roleStatus" :cruise-status="cruise.status" />
          </div>
        </template>
      </div>
    </section>
    <section v-if="cruise.write_access" class="is-clearfix">
      <button data-test="delete-cruise" type="button" class="button is-danger is-pulled-right" @click="handleCruiseDelete">Delete cruise</button>
    </section>
  </div>
</template>

<script>
//
import Pictogram from '@/components/UI/Pictogram'
import RoleStatusCard from './components/RoleStatusCard'
import WaypointsMap from '@/views/AgreementDetail/sections/waypoints/WaypointsMap'
import { datepickerStyles } from '@/helpers/datepickers'
import { serverURL } from '@/api/config'
import { mapState } from 'vuex'
import NumFormatters from '@/helpers/num_formatters'

import Actions from '@/store/actions'

export default {
  name: 'QuickViewSection',
  components: {
    Pictogram,
    RoleStatusCard,
    WaypointsMap,
  },
  data () {
    return {
      isFetchingRoleStatuses: false,
      isGeneratingContract: false,
      isUnlocking: false,
      datepickerStyles: datepickerStyles,
      serverURL: serverURL,
      // stripePk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    }
  },
  computed: {
    ...mapState({
      cruise: state => state.cruise.cruise,
      yacht: state => state.cruise.cruise.yacht,
      waypoints: state => state.waypoint.waypoints,
      isLoadingWaypoints: state => state.waypoint.loading,
      userProfileIds: state => state.user.user.profiles.map(p => p.id),
      roleStatuses: state => state.cruise.roleStatuses,
    }),
    userRoleStatuses: function () {
      return this.roleStatuses.filter(roleStatus => this.userProfileIds.includes(roleStatus.profile.id))
    },
    othersRoleStatuses: function () {
      return this.roleStatuses.filter(roleStatus => !this.userProfileIds.includes(roleStatus.profile.id))
    },
    currencySymbol: function () {
      const symbols = {
        EUR: '€',
        USD: '$',
        JPY: '¥',
        CNY: 'CN¥',
        GBP: '£',
      }
      if (this.cruise.currency in symbols) {
        return symbols[this.cruise.currency]
      } else {
        return this.cruise.currency
      }
    },
    currencyIcon: function () {
      const icons = {
        EUR: 'euro-sign',
        USD: 'dollar-sign',
        JPY: 'yen-sign',
        CNY: 'icon-cny',
        GBP: 'icon-pound',
      }
      if (this.cruise.currency in icons) {
        return icons[this.cruise.currency]
      } else {
        return 'dollar-sign'
      }
    },
    // Datepicker data
    initialDatePage () {
      const deliveryDate = this.formatDateGMT(this.cruise.delivery_date, false)
      return {
        month: deliveryDate.month() + 1,
        year: deliveryDate.year()
      }
    },
    datepickerAttributes () {
      const positioningTowardStart = this.formatDateGMT(this.cruise.positioning_toward_start)
      const deliveryDate = this.formatDateGMT(this.cruise.delivery_date)
      const positioningReturnEnd = this.formatDateGMT(this.cruise.positioning_return_end)
      const redeliveryDate = this.formatDateGMT(this.cruise.redelivery_date)

      var attributes = [
        {
          highlight: {
            backgroundColor: '#43a9e3BB',
            height: '20px'
          },
          contentStyle: {
            color: 'white'
          },
          dates: [{
            start: deliveryDate,
            end: redeliveryDate
          }]
        },
        {
          dot: {
            backgroundColor: 'blue'
          },
          dates: this.waypoints.filter(waypoint => waypoint.is_call && this.formatDateGMT(waypoint.arr_date) > deliveryDate && this.formatDateGMT(waypoint.dep_date) < redeliveryDate).map(waypoint => this.formatDateGMT(waypoint.arr_date))
        },
        {
          dot: {
            backgroundColor: 'green'
          },
          dates: [deliveryDate]
        },
        {
          dot: {
            backgroundColor: 'red'
          },
          dates: [redeliveryDate]
        },
        {
          dot: {
            backgroundColor: 'black'
          },
          dates: this.waypoints.filter(waypoint => waypoint.is_call && waypoint.is_routing).map(waypoint => this.formatDateGMT(waypoint.arr_date))
        }
      ]

      if (positioningTowardStart < deliveryDate) {
        attributes.push({
          highlight: {
            backgroundColor: '#ccccccAA',
            height: '20px'
          },
          contentStyle: {
            color: 'black'
          },
          dates: [{
            start: positioningTowardStart,
            end: deliveryDate
          }]
        })
      }

      if (positioningReturnEnd > redeliveryDate) {
        attributes.push({
          highlight: {
            backgroundColor: '#ccccccAA',
            height: '20px'
          },
          contentStyle: {
            color: 'black'
          },
          dates: [{
            start: redeliveryDate,
            end: positioningReturnEnd
          }]
        })
      }

      return attributes
    }
    // End datepicker data
  },
  watch: {
    waypoints (newVal, oldVal) {
      this.$store.dispatch(Actions.SET_SELECTED_WAYPOINT, false)
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    ...NumFormatters,
    fetchData () {
      this.fetchRoleStatuses()
      this.$store.dispatch(Actions.SET_SELECTED_WAYPOINT, false)
    },
    fetchRoleStatuses () {
      this.isFetchingRoleStatuses = true
      this.$store.dispatch(Actions.GET_CRUISE_ROLE_STATUSES)
        .finally(() => { this.isFetchingRoleStatuses = false })
    },
    handleCruiseDelete () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Cruise',
        message: 'Are you sure you want to <b>delete</b> this cruise?<br/>This action cannot be undone.',
        confirmText: 'Delete Cruise',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch(Actions.DELETE_CRUISE, this.cruise.id)
            .then(() => {
              this.$router.replace({ name: 'AgreementList' })
            })
        }
      })
    },
    handleUnlock () {
      this.isUnlocking = true
      this.$store.dispatch(Actions.SET_CRUISE_LOCKED, false)
        .finally(() => { this.isUnlocking = false })
    },
    handleGenerateContract () {
      this.isGeneratingContract = true
      this.$store.dispatch(Actions.VALIDATE_CRUISE, this.cruise.id)
        .then(() => {
          const payAndGenerate = () => {
            this.$store.dispatch(Actions.GENERATE_CONTRACT, this.cruise.id)
              .catch(() => {}) // cancelled payment
              .finally(() => { this.isGeneratingContract = false })
          }
          if (this.cruise.total_agreement_price === null) {
            this.$buefy.dialog.alert({
              title: '',
              message: 'Required information are still missing to calculate the total agreement price. Please provide them before generating a contract.',
              type: 'is-danger',
              hasIcon: false,
            })
            this.isGeneratingContract = false
          } else if (Object.keys(this.$store.state.cruise.validationData).length) {
            this.$buefy.dialog.confirm({
              title: '',
              message: 'There are still missing values that are required to generate the contract. Generate anyway and leave blank?',
              confirmText: 'Generate contract',
              type: 'is-warning',
              hasIcon: false,
              onConfirm: payAndGenerate,
              onCancel: () => { this.isGeneratingContract = false }
            })
          } else {
            payAndGenerate()
          }
        })
    },
    formatDateGMT (date, getJSDate = true) {
      if (!date) return null
      date = this.$dayjs(date)
      date = date.subtract(date.utcOffset(), 'minutes')
      return getJSDate ? date.toDate() : date
    }
  },
}
</script>

<style lang="scss" scoped>

.quickview {
  section {
    margin-bottom: 1rem;
  }

  .schedule-text {
    display: none;
  }

  .yacht-picture img {
    max-height: 100%;
  }

  .map-container {
    height: 270px;
    border: 1px solid #ddd;
  }

  @include mobile {
    .calendar {
      display: none;
    }
    .schedule-text {
      display: block;
      margin-bottom: 1rem;
    }

    .map-container {
      margin-bottom: 1rem;
    }
  }

  .column {
    @include mobile {
      .pictogram {
        display: none;
      }
    }
  }

  .generated-versions {
    margin-bottom: 0.4rem;
    text-align: left;
  }
  .tag {
    margin-right: 2px;
  }
}

</style>
