<template>
  <div>
    <div v-if="serverError" class="message is-danger">
      <div class="message-body">
        <p>{{ serverError }}</p>
      </div>
    </div>
    <div v-if="isSent" class="message is-success">
      <div class="message-body">
        <p>A password recovery email has been sent to the email adress provided. If the email doesn't show up soon, check your spam folder for any mail from <strong>wolfgang.pro</strong>.</p>
      </div>
    </div>
    <form v-if="!isSent" autocomplete="on" @submit.prevent="recoverPassword">
      <legend>Reset your password</legend>
      <b-field label="Email">
        <b-input
          v-model="recoverPasswordData.emailAddress"
          type="email"
          autocomplete="email"
          placeholder="john.doe@example.xyz"
          required
        />
      </b-field>
      <b-field label="First name">
        <b-input
          v-model="recoverPasswordData.firstName"
          autocomplete="given-name"
          placeholder="John"
          required
        />
      </b-field>
      <b-field>
        <SubmitButton :is-loading="isLoading" :is-disabled="isLoading">
          Reset password
        </SubmitButton>
      </b-field>
      <p>We will email you instructions on how to reset your password.</p>
    </form>
  </div>
</template>

<script>
//
import Actions from '@/store/actions'

export default {
  name: 'PasswordRecovery',
  data () {
    return {
      recoverPasswordData: {
        emailAddress: '',
        firstName: ''
      },
      isLoading: false,
      isSent: false,
      serverError: false
    }
  },
  methods: {
    recoverPassword: function () {
      this.$store.dispatch(Actions.RESET_ERROR)
      this.isLoading = true
      this.$store.dispatch(Actions.SEND_PASSWORD_RESET, {
        email: this.recoverPasswordData.emailAddress,
        first_name: this.recoverPasswordData.firstName
      })
        .then(() => {
          this.isSent = true
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped lang="css">
.subtitle {
  margin-top: 1.5rem !important;
}
</style>
